import { Typography } from '@mui/material'
import React, { memo } from 'react'

interface Props {
  setExpanded: (bool: boolean) => void
  expanded: boolean
  length: number
}

const ExpandStatus = (props: Props): React.ReactElement => {
  const { length, expanded, setExpanded } = props
  return (
    <Typography
      variant='button'
      fontWeight={500}
      paddingRight='8px'
      color='primary'
      onClick={() => setExpanded(!expanded)}
      sx={{ cursor: 'pointer' }}
    >
      {`+ ${length} ${expanded ? 'less' : 'more'}`}
    </Typography>
  )
}

export default memo(ExpandStatus)
