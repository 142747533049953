import { Typography } from '@mui/material'
import React, { memo } from 'react'
import { Color } from 'components'
interface Props {
  affiliation: any
  key: number
  last: boolean
}

const AffiliationBuilder = (props: Props): React.ReactElement => {
  const { affiliation, last } = props

  return (
    <div style={{ whiteSpace: 'nowrap' }}>
      <Typography
        variant='body2Medium'
        color={Color.black}
        gutterBottom
        component='span'
      >
        {affiliation.name}{' '}
      </Typography>
      <Typography
        variant='body2'
        color={Color.grey700}
        lineHeight='20px'
        component='span'
      >
        ({affiliation.linkedinGroups.length} group
        {affiliation.linkedinGroups.length > 1 ? 's' : ''}) {!last ? '• ' : ''}
        &nbsp;
      </Typography>
    </div>
  )
}

export default memo(AffiliationBuilder)
