import { JobBatch } from './jobPipeline'

export interface Navigation {
  label: string
  link: string
  isTitle?: boolean
  id?: string
  hide?: boolean
}
export interface Question {
  choices?: string[]
  title: string
  subTitle: string
  type: QuestionType
  index: string
}
export interface Chip {
  label: string
  value: string
}
export interface Identifier {
  title: string
  chips: Chip[]
}
export enum QuestionType {
  SingleChoice,
  Identifiers,
  About,
  CurrentJob,
  Congrats,
  Location
}
export type PaginationState = {
  currentPage: number
  rowsPerPage?: number
  cursor?: string
  status?: string
}

export type Filter = {
  name?: string
  status?: any
  sortBy: string
  sortOrder: string
}
export enum JobStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  DELETED = 'DELETED'
}
enum JobLocationType {
  ONSITE,
  REMOTE,
  REMOTE_OPTIONAL,
  TRAVELLING
}

export type LocationInput = {
  city?: string
  cityId?: number
  country?: string
  countryId?: number
  state?: string
  stateId?: number
}

export type CreateJobInput = {
  description?: string
  displayTitle: string
  employerId: string
  internalTitle?: string
  jobId: string
  locationType?: JobLocationType
  locations?: LocationInput[]
  openCreateJobDialog: boolean
}
export type DispatchContent = {
  index?: string
  value?: string | number | boolean | string[]
  type?: string
}
export type EmployerStaffAccount = {
  node: EmployerStaffAccountNode
}
export type EmployerStaffAccountNode = {
  id: string
  user: any
}

export type Job = {
  id: string
  nanoid: string
  description: string
  displayTitle: string
  internalTitle: string
  numCandidates: number
  numCandidatesInLastWeek: number
  insertedAt: string
  updatedAt: string
  status: JobStatus
  talentMaskingFlag: boolean
  recruiters: EmployerStaffAccountNode[]
  followers: EmployerStaffAccountNode[]
  candidates: any[]
  employer: any
  locationType: JobLocationType
  locations: LocationInput[]
  currentBatch: JobBatch
  exampleProfiles?: string[]
  requiredSkills?: string[]
  preferredSkills?: string[]
  companiesToInclude?: string[]
  companiesToExclude?: string[]
  commentsAndFeedback?: string
}

export type TransferCandidateInput = {
  jobId: string
  jobName: string
  shouldArchive: boolean
}

export type User = {
  username: string
  email: string
  avatarURL?: string
  handleLogout: () => void
}

export type Document = {
  title: string
  author?: string
  name: string
  updatedAt?: string | Date
}

export type SocialMedia = {
  link: string
  label: 'LINKEDIN' | 'TWITTER' | 'FACEBOOK'
}

export type NavigationTab = {
  label: string
  link: string
}

export type Action = {
  text?: string
  handleAction?: (input?: any) => void
  disabled?: boolean
}
