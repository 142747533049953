import React, { memo, useReducer, useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { Color, Dialog } from 'components'
import { Grid, Typography, Checkbox, FormControlLabel } from '@mui/material'
import JobSelector from './JobSelector'
import { TransferCandidateInput } from 'model'
import { JobCandidateContext } from 'context/JobCandidateContext'
interface Props {
  transferrableJobs: any
  isTransferrableJobsLoading: boolean
  open: boolean
  setOpen: (open: boolean) => void
  selectedRow: any
  setJobSearchTerm: (searchTerm: string | undefined) => void
}

const useStyles = makeStyles(() => ({
  checkbox: {
    color: Color.grey700
  }
}))
const InitialTransferCandidateInput: TransferCandidateInput = {
  jobName: '',
  jobId: '',
  shouldArchive: false
}

const TransferCandidate = ({
  transferrableJobs,
  isTransferrableJobsLoading,
  open,
  setOpen,
  selectedRow,
  setJobSearchTerm
}: Props): React.ReactElement => {
  const classes = useStyles()
  const { onTransferCandidate } = useContext(JobCandidateContext)
  const [transferInput, dispatchTransferInput] = useReducer(
    (input: TransferCandidateInput, action: any) => {
      switch (action.type) {
        case 'setShouldArchive':
          return {
            ...input,
            shouldArchive: action.payload
          }
        default:
          return {
            ...input,
            jobName: action.jobName,
            jobId: action.jobId
          }
      }
    },
    InitialTransferCandidateInput
  )

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={'Transfer lead to another job'}
      confirm={{
        text: 'Transfer lead',
        disabled: !transferInput.jobName,
        handleAction: () => {
          onTransferCandidate(
            {
              variables: {
                input: {
                  candidateId: selectedRow?.id,
                  newJobId: transferInput.jobId,
                  shouldArchiveForThisJob: transferInput.shouldArchive
                }
              }
            },
            `${transferInput.jobId}/${selectedRow!.profile.id}`
          )
        }
      }}
      content={'Choose which job you’d like to transfer this candidate to'}
      onClose={() => setOpen(false)}
    >
      <Typography variant='formLabel' gutterBottom>
        Job name
      </Typography>

      <JobSelector
        transferrableJobs={transferrableJobs}
        isTransferrableJobsLoading={isTransferrableJobsLoading}
        setJobSearchTerm={setJobSearchTerm}
        dispatchTransferInput={dispatchTransferInput}
      />

      <Grid marginTop={3}>
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkbox}
              name='archive'
              checked={transferInput.shouldArchive}
              onClick={() =>
                dispatchTransferInput({
                  type: 'setShouldArchive',
                  payload: !transferInput.shouldArchive
                })
              }
            />
          }
          label={
            <Typography color={Color.grey700} lineHeight={'18px'}>
              Archive this lead on current job
            </Typography>
          }
        />
      </Grid>
    </Dialog>
  )
}

export default memo(TransferCandidate)
