import { gql } from '@apollo/client'
import { PageInfo, EmployerStaffPermissions } from './fragments'

export const GET_STAFF_MEMBERS = gql`
  query EmployerStaffAccounts(
    $first: Int
    $after: String
    $ehiAccessLevels: [EmployerStaffAccountEhiAccessLevel]
    $jobsAccessLevels: [EmployerStaffAccountJobsAccessLevel]
    $roles: [EmployerStaffAccountRole]
    $isUsingSeat: Boolean
    $searchTerm: String
    $statuses: [EmployerStaffAccountStatus]
  ) {
    employerStaffAccounts(
      first: $first
      after: $after
      searchTerm: $searchTerm
      ehiAccessLevels: $ehiAccessLevels
      jobsAccessLevels: $jobsAccessLevels
      roles: $roles
      isUsingSeat: $isUsingSeat
      statuses: $statuses
    ) {
      pageInfo {
        ...pageInfo
      }
      count
      edges {
        node {
          id
          user {
            id
            name
          }
          email
          status
          ...employerStaffPermissions
        }
      }
    }
  }
  ${PageInfo}
  ${EmployerStaffPermissions}
`

//commands
export const INVITE_EMPLOYER_STAFF_ACCOUNTS = gql`
  mutation InviteEmployerStaffAccounts($emails: [String]) {
    inviteEmployerStaffAccounts(emails: $emails) {
      failedEmails
      successfulEmails
    }
  }
`

export const RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE = gql`
  mutation ResendEmployerStaffAccountInvite(
    $input: ResendEmployerStaffAccountInviteInput!
  ) {
    resendEmployerStaffAccountInvite(input: $input)
  }
`

export const CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS = gql`
  mutation ChangeEmployerStaffAccountPermissions(
    $input: ChangeEmployerStaffAccountPermissionsInput!
  ) {
    changeEmployerStaffAccountPermissions(input: $input)
  }
`

// subsciption
export const EMPLOYER_STAFF_ACCOUNT_INVITED = gql`
  subscription EmployerStaffAccountInvited {
    employerStaffAccountInvited {
      id
    }
  }
`

export const EMPLOYER_STAFF_ACCOUNT_PERMISSIONS_CHANGED = gql`
  subscription EmployerStaffAccountPermissionsChanged(
    $employerStaffAccountId: ID!
  ) {
    employerStaffAccountPermissionsChanged(
      employerStaffAccountId: $employerStaffAccountId
    ) {
      employerSeatUsageChange
      employerStaffAccount {
        id
        employer {
          id
          numUsedSeats
          numOpenSeats
        }
        ...employerStaffPermissions
      }
    }
  }
  ${EmployerStaffPermissions}
`
