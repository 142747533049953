import { CircularProgress } from '@mui/material'
import React, { memo } from 'react'

import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  loading: {
    margin: 'auto',
    display: 'block'
  },
  loadingContainer: {
    width: '100%',
    height: 120,
    paddingTop: 60
  }
}))

const TableLoading = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.loadingContainer}>
      <CircularProgress className={classes.loading} color='primary' />
    </div>
  )
}

export default memo(TableLoading)
