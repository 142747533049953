import React from 'react'
import { Tooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { CreateJobInput, Filter, PaginationState } from 'model'

export const TABLE = {
  TABLE_HEADS: [
    {
      id: 'company_name',
      label: 'Job name',
      alignRight: false,
      width: '20%'
    },
    {
      id: 'account_status',
      label: 'Leads',
      alignRight: true,
      width: '25%'
    },
    {
      id: 'organization_id',
      label: 'This week',
      alignRight: true,
      width: '25%'
    },
    {
      id: 'created',
      label: 'Date Created',
      alignRight: true,
      width: '16%'
    },
    {
      id: 'internal_title',
      label: (
        <Tooltip
          PopperProps={{ sx: { marginLeft: '-10px !important' } }}
          title={
            <div>
              If you enter your ATS Job ID,
              <br /> leads moved to your ‘To contact’ <br /> list will
              automatically send to <br /> your ATS platform.
            </div>
          }
          placement='right-end'
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '35%'
            }}
          >
            ATS Job ID
            <InfoIcon
              style={{
                paddingLeft: '5px',
                width: 18,
                height: 18,
                color: '#395260'
              }}
            />
          </div>
        </Tooltip>
      ),
      alignRight: false
    },
    {
      id: 'action',
      label: '',
      alignRight: false
    }
  ]
}

export const Status = {
  OPEN: { state: 'OPEN', text: 'Show closed jobs' },
  CLOSED: { state: 'CLOSED', text: 'Show open jobs' }
}
export const InitiaPaginationState: PaginationState = {
  currentPage: 0,
  rowsPerPage: 25,
  cursor: undefined
}
export const InitialNewJobInfo: CreateJobInput = {
  displayTitle: '',
  internalTitle: '',
  employerId: '',
  jobId: '',
  openCreateJobDialog: false
}

export const InitialJobFilter: Filter = {
  sortBy: 'date',
  sortOrder: 'DESC',
  name: '',
  status: Status.OPEN
}
