import { PipelineStage, BatchStatus, PipelineTag } from 'model/jobPipeline'

export const IMG_LOGO_PARAMS = {
  w: 420,
  h: 420,
  ar: '1:1',
  fit: 'crop',
  mask: 'ellipse'
}

export const PipelineTabs = [
  {
    value: PipelineStage.TO_REVIEW,
    tag: 'To review'
  },
  {
    value: PipelineStage.TO_CONTACT,
    tag: 'To contact'
  },
  {
    value: PipelineStage.CONTACTED,
    tag: 'Contacted'
  },
  {
    value: PipelineStage.ARCHIVED,
    tag: 'Archived'
  }
]

export const PipelineFilter = [
  {
    value: PipelineStage.TO_REVIEW,
    tag: PipelineTag.TO_REVIEW
  },
  {
    value: PipelineStage.TO_CONTACT,
    tag: PipelineTag.TO_CONTACT
  },
  {
    value: PipelineStage.CONTACTED,
    tag: PipelineTag.CONTACTED
  },
  {
    value: PipelineStage.ARCHIVED,
    tag: PipelineTag.ARCHIVED
  }
]

export const BatchContent = [
  {
    value: BatchStatus.REQUESTED,
    tag: 'Requested'
  },
  {
    value: BatchStatus.IN_PROGRESS,
    tag: 'In progress'
  },
  {
    value: BatchStatus.COMPLETED,
    tag: 'Completed'
  },
  {
    value: BatchStatus.CANCELLED,
    tag: 'Cancelled'
  }
]

export const TABLE_HEADS = [
  {
    id: 'candidate',
    label: 'Candidate',
    alignRight: false,
    width: '38%'
  },
  {
    id: 'view',
    label: 'View',
    alignRight: false,
    width: '16%'
  },
  {
    id: 'batch',
    label: 'Batch',
    alignRight: false,
    width: '30%'
  },
  {
    id: 'added',
    label: 'Added',
    alignRight: true,
    width: '7%'
  },
  {
    id: 'action',
    label: '',
    alignRight: true,
    width: '9%'
  }
]

export const MOVE_CANDIDATE_OPTIONS = [
  {
    value: PipelineStage.TO_CONTACT,
    tag: PipelineTag.TO_CONTACT
  },
  {
    value: PipelineStage.CONTACTED,
    tag: PipelineTag.CONTACTED
  },
  {
    value: PipelineStage.ARCHIVED,
    tag: 'Archive'
  }
]
