import React, { memo, useState, useContext } from 'react'
import { Dialog } from 'components'
import { Typography } from '@mui/material'
import MoveSelector from './MoveSelector'
import { PipelineStage } from 'model/jobPipeline'
import { JobCandidateContext } from 'context/JobCandidateContext'
interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  selectedRow: any
}

const MoveCandidate = ({
  open,
  setOpen,
  selectedRow
}: Props): React.ReactElement => {
  const [moveTo, setMoveTo] = useState<PipelineStage | string>('')
  const { onMoveCandidate } = useContext(JobCandidateContext)
  const currentStage = selectedRow?.stage
  return (
    <>
      <Dialog
        open={open}
        setOpen={setOpen}
        title={'Move lead'}
        confirm={{
          text: 'Move lead',
          disabled: !moveTo,
          handleAction: () => {
            onMoveCandidate(
              {
                variables: {
                  input: {
                    candidateId: selectedRow!.id,
                    stage: moveTo
                  }
                }
              },
              selectedRow.id
            )
          }
        }}
        content={'Choose which stage you’d like to move this lead to'}
        onClose={() => setOpen(false)}
      >
        <Typography variant='formLabel' gutterBottom>
          Stage
        </Typography>
        <MoveSelector
          fullwidth
          currentStage={currentStage}
          selected={moveTo}
          setSelected={setMoveTo}
        />
      </Dialog>
    </>
  )
}

export default memo(MoveCandidate)
