import React from 'react'
import Modal from '@mui/material/Modal'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'
import Button, { ButtonProps } from '@mui/material/Button'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
interface KCModalProps {
  children?: React.ReactNode
}

const ModalHeader = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
}))

const ModalContentContainer = styled(Box)<BoxProps>(() => ({
  backgroundColor: 'white',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  border: 'none'
}))

const PaperWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 'inherit',
  padding: '0 50px'
}))

const CloseButton = styled(IconButton)<IconButtonProps>(() => ({
  color: 'black',
  '& .hidden': {
    color: 'white'
  }
}))

const PlayButton = styled(Button)<ButtonProps>(() => ({
  textTransform: 'none',
  color: 'white',
  backgroundColor: 'black',
  borderRadius: '8px',
  height: 85,
  width: 85,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: '#444444',
    boxShadow: 'none'
  },
  '& .MuiButton-startIcon': {
    marginRight: 0
  }
}))

const KCModal = ({ children }: KCModalProps) => {
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <Box>
      <Box className='modal-btn-wrapper'>
        <PlayButton
          className='video-button'
          variant='contained'
          startIcon={<PlayArrowIcon style={{ width: 30, height: 30 }} />}
          onClick={handleOpen}
        ></PlayButton>
      </Box>

      <Modal
        open={open}
        BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0.8)' } }}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <ModalContentContainer>
          <ModalHeader>
            <CloseButton aria-label='close modal' onClick={handleClose}>
              <CloseIcon fontSize='large' />
            </CloseButton>
          </ModalHeader>
          <PaperWrapper>{children}</PaperWrapper>
        </ModalContentContainer>
      </Modal>
    </Box>
  )
}
export default KCModal
