import * as React from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

interface ContentTypeChipProps {
  label: string
  backgroundColor: string
  cardGroup?: 'two' | 'three' | undefined
}

interface StyledChipProps extends ChipProps {
  backgroundColor: string
  cardGroup?: 'two' | 'three' | undefined
}

const StyledChip = styled(Chip, {
  shouldForwardProp: prop => prop !== 'backgroundColor' && prop !== 'cardGroup'
})<StyledChipProps>(({ backgroundColor, cardGroup }) => ({
  color: '#000000',
  backgroundColor: backgroundColor,
  fontSize: '12px',
  lineHeight: '18px',
  fontFamily: '"D-DIN", Roboto, sans-serif',
  fontWeight: 700,
  textTransform: 'uppercase',
  padding: cardGroup === 'three' ? '0px 5px' : '0px 13px',
  height: cardGroup === 'three' ? '35px' : '37px',
  borderRadius: '25px',
  letterSpacing: '2px'
}))

export const ContentTypeChip = ({
  label,
  backgroundColor,
  cardGroup
}: ContentTypeChipProps) => {
  return (
    <StyledChip
      cardGroup={cardGroup}
      backgroundColor={backgroundColor}
      label={label}
    />
  )
}
