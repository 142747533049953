import { makeStyles, withStyles } from '@mui/styles'
import { Color } from 'components'
import { Dialog as MuiDialog } from '@mui/material'

export const useStyles = makeStyles(() => ({
  batchDialog: {
    '& .MuiDialogContent-root': {
      margin: 'auto',
      paddingTop: 56,
      position: 'relative'
    }
  },
  infoBanner: {
    marginBottom: 24,
    '& .bannerText': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  redBanner: {
    marginBottom: 24
  },
  jobType: {
    width: 600,
    marginTop: 24,
    position: 'relative',
    '& .closeButton': {
      backgroundColor: Color.black5,
      width: 32,
      height: 32
    }
  },
  batchSize: {
    width: 600,
    paddingTop: 24,
    '& .closeButton': {
      backgroundColor: Color.black5,
      width: 32,
      height: 32,
      position: 'absolute',
      right: 0,
      top: 7
    },
    '& .textMargin': {
      marginLeft: 4,
      marginRight: 4
    }
  },
  completed: {
    width: 600,
    '& .closeButton': {
      backgroundColor: Color.black5,
      width: 32,
      height: 32
    },
    '& .selectionText': {
      fontSize: '18px',
      fontWeight: 400,
      color: Color.black,
      letterSpacing: '2%',
      display: 'block',
      marginBottom: 32
    },
    '& .editText': {
      color: Color.main,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      marginLeft: 8,
      cursor: 'pointer'
    }
  },
  selection: {
    marginBottom: 16,
    padding: 24,
    width: '100%',
    border: '1px solid #D7DCDF',
    borderRadius: 10,
    display: 'flex',
    '& .MuiListItemText-root': {
      margin: 0
    }
  },
  selected: {
    border: '1px solid #050B0E',
    backgroundColor: '#FAFAFA'
  },
  nextButton: {
    padding: '12px 40px',
    height: 44
  },
  backButton: {
    padding: '12px 40px',
    height: 44
  }
}))

export const Dialog = withStyles({
  root: {
    top: 56,
    zIndex: 999
  },
  paper: {
    boxShadow: 'none'
  }
})(MuiDialog)
