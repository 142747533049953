export const toCapitalize = (string: string) => {
  if (!string) return ''
  string = string.replaceAll('_', ' ')
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase()
}
const UppercaseWords = ['EI']
export const toCapitalizeWithSpace = (word: string) => {
  return word
    .replace('EHI', 'EI')
    .split('_')
    .map(letter =>
      UppercaseWords.includes(letter)
        ? letter
        : letter.charAt(0).toUpperCase() + letter.slice(1).toLocaleLowerCase()
    )
    .join(' ')
}
export const generateLink = (string: string) => {
  return window.location.origin + string
}
