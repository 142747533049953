import { MenuItem, Menu, Typography, Button } from '@mui/material'
import React, { memo, useState } from 'react'
import { JobStatus, Job } from 'model'
import { CLOSE_JOB, DELETE_JOB, REOPEN_JOB } from 'gql/command'
import { DialogWithoutAction } from 'components'
import { Color } from 'components'
import { useMutation } from 'gql/hooks'
import { makeStyles } from '@mui/styles'
interface Props {
  selectedRow?: Job
  status: any
  anchorEl: any
  setAnchorEl: (_e: any) => void
  updateQuery: (prev: any) => void
}
const useStyles = makeStyles({
  menuContainer: { width: 296, paddingTop: 8, paddingBottom: 8 },
  menuItem: {
    height: 54,
    borderRadius: 5,
    width: 280,
    color: Color.black
  },
  menuList: { paddingTop: 0, paddingBottom: 0 },
  dialogButtons: {
    height: 44,
    width: 222,
    '&:not(:first-of-type)': { marginLeft: 32 }
  }
})
const Dialogs = (props: Props): React.ReactElement => {
  const { selectedRow, status, anchorEl, setAnchorEl, updateQuery } = props

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [closeJobMutation] = useMutation(CLOSE_JOB)
  const [deleteJobMutation] = useMutation(DELETE_JOB)
  const [reopenJobMutation] = useMutation(REOPEN_JOB)
  const classes = useStyles()
  const removeItemAndCloseDialog = () => {
    removeJobFromList(selectedRow!.id)
    setOpenConfirmDialog(false)
    setAnchorEl(undefined)
  }

  const removeJobFromList = (selectedJobId: string) => {
    updateQuery((prev: any) => {
      return Object.assign({}, prev, {
        myJobs: {
          ...prev.myJobs,
          edges: [
            ...prev.myJobs.edges.filter(
              (edge: any) => edge.node.id !== selectedJobId
            )
          ],
          count: prev.myJobs.count - 1
        }
      })
    })
  }

  return (
    <>
      <DialogWithoutAction
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title={'Delete this job?'}
        banner={{
          multiline: true,
          type: 'warning',
          content:
            'This means you will lose access this job, and all leads assigned to this job and their stages. Once you’ve deleted the job, there’s no going back.'
        }}
        Action={() => (
          <>
            <Button
              onClick={async () => {
                deleteJobMutation({
                  variables: {
                    deleteJobInput: {
                      jobId: selectedRow?.id
                    }
                  }
                })
                removeItemAndCloseDialog()
              }}
              className={classes.dialogButtons}
              variant='outlined'
              color='secondary'
            >
              Yes, delete this job
            </Button>
            <Button
              onClick={() => {
                setOpenConfirmDialog(false)
              }}
              variant='contained'
              color='primary'
              className={classes.dialogButtons}
            >
              Cancel and go back
            </Button>
          </>
        )}
      />
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        classes={{ paper: classes.menuContainer, list: classes.menuList }}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setAnchorEl(undefined)
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setOpenConfirmDialog(true)
            setAnchorEl(undefined)
          }}
        >
          <Typography variant='body1'>Delete</Typography>
        </MenuItem>{' '}
        {status.state === JobStatus.CLOSED ? (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              reopenJobMutation({
                variables: {
                  reopenJobInput: { jobId: selectedRow?.id }
                }
              })
              removeItemAndCloseDialog()
            }}
          >
            <Typography variant='body1'>Open</Typography>
          </MenuItem>
        ) : (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              closeJobMutation({
                variables: {
                  closeJobInput: { jobId: selectedRow?.id }
                }
              })
              removeItemAndCloseDialog()
            }}
          >
            <Typography variant='body1'>Close</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

export default memo(Dialogs)
