import { DialogContent } from '@mui/material'
import React, { useState, useContext } from 'react'
import { useStyles, Dialog } from './style'
import JobType from './JobType'
import BatchSize from './BatchSize'
import JobRequirement from './JobRequirement'
import Completed from './Completed'
import { FullScreenDialogContext } from 'context/FullScreenDialog'
import { RequestBatchContextProvider } from 'context/RequestBatch'

const RequestBatch = (): React.ReactElement => {
  const classes = useStyles()
  const [step, setStep] = useState(0)
  const { openBatchRequest, setOpenBatchRequest } = useContext(
    FullScreenDialogContext
  )

  return (
    <RequestBatchContextProvider>
      <Dialog
        fullScreen
        hideBackdrop
        open={openBatchRequest!}
        onClose={() => setOpenBatchRequest!(false)}
        className={classes.batchDialog}
      >
        <DialogContent sx={{ overflow: 'unset' }}>
          {step === 0 && <JobType step={step} setStep={setStep} />}
          {step === 1 && <BatchSize step={step} setStep={setStep} />}
          {step === 2 && <JobRequirement step={step} setStep={setStep} />}
          {step === 3 && <Completed step={step} setStep={setStep} />}
        </DialogContent>
      </Dialog>
    </RequestBatchContextProvider>
  )
}

export default RequestBatch
