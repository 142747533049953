import { gql } from '@apollo/client'
import { AccountWithName } from './fragments'

const Job = gql`
  fragment job on Job {
    id
    nanoid
    displayTitle
    internalTitle
    numCandidates
    numCandidatesInLastWeek
    insertedAt
    updatedAt
    status
  }
`

const JobBatch = gql`
  fragment jobBatch on JobBatch {
    batchNumber
    status
    deliveredLeads
    dueAt
    remainingLeads
    size
  }
`

const JobDetail = gql`
  fragment jobDetail on Job {
    ...job
    candidates {
      id
    }
    description
    exampleProfiles
    requiredSkills
    preferredSkills
    companiesToInclude
    companiesToExclude
    commentsAndFeedback
    talentMaskingFlag
    currentBatch {
      ...jobBatch
    }
    locations {
      city
    }
    recruiters {
      ...accountWithName
    }
    followers {
      ...accountWithName
    }
    employer {
      monthlyCreditsAllowance
      numRemainingCreditsForMonth
    }
  }
  ${Job}
  ${JobBatch}
  ${AccountWithName}
`

// Queries.

export const GET_MY_JOBS = gql`
  query LoadMyJobs(
    $first: Int
    $after: String
    $orderBy: JobSortColumns
    $orderDirection: OrderDirections
    $status: JobStatus
    $searchTerm: String
  ) {
    myJobs(
      first: $first
      after: $after
      orderBy: $orderBy
      orderDirection: $orderDirection
      status: $status
      searchTerm: $searchTerm
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        node {
          ...job
        }
      }
    }
  }
  ${Job}
`

export const GET_TRANSFERRABLE_JOBS = gql`
  query LoadTransferrableJobs(
    $first: Int
    $after: String
    $candidateId: ID
    $searchTerm: String
  ) {
    transferrableJobsForCandidate(
      first: $first
      after: $after
      candidateId: $candidateId
      searchTerm: $searchTerm
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      count
      edges {
        node {
          id
          displayTitle
          internalTitle
        }
      }
    }
  }
`

export const GET_JOB = gql`
  query LoadJob($nanoid: String!) {
    job(nanoid: $nanoid) {
      ...jobDetail
    }
  }
  ${JobDetail}
`
export const GET_BATCH_DELIVERY_PREFERENCES = gql`
  query GetbatchDeliveryPreferences {
    batchDeliveryPreferences {
      id
      name
      numberOfLeads
      recurringPeriod
      availableOnTypes
      businessDaysUntilDue
    }
  }
`

// Commands.
export const CREATE_JOB = gql`
  mutation CreateJobMutation($createJobInput: CreateJobInput!) {
    createJob(input: $createJobInput)
  }
`

export const CLOSE_JOB = gql`
  mutation CloseJobMutation($closeJobInput: CloseJobInput!) {
    closeJob(input: $closeJobInput)
  }
`

export const DELETE_JOB = gql`
  mutation DeleteJobMutation($deleteJobInput: DeleteJobInput!) {
    deleteJob(input: $deleteJobInput)
  }
`

export const REOPEN_JOB = gql`
  mutation ReopenJobMutation($reopenJobInput: ReopenJobInput!) {
    reopenJob(input: $reopenJobInput)
  }
`

export const ADD_RECRUITER_TO_JOB = gql`
  mutation AddRecruiterToJob($input: AddRecruiterToJobInput!) {
    addRecruiterToJob(input: $input)
  }
`

export const ADD_FOLLOWER_TO_JOB = gql`
  mutation AddFollowerToJob($input: AddFollowerToJobInput!) {
    addFollowerToJob(input: $input)
  }
`

export const REMOVE_RECRUITER_FROM_JOB = gql`
  mutation RemoveRecruiterFromJob($input: RemoveRecruiterFromJobInput!) {
    removeRecruiterFromJob(input: $input)
  }
`

export const REMOVE_FOLLOWER_FROM_JOB = gql`
  mutation RemoveFollowerFromJob($input: RemoveFollowerFromJobInput!) {
    removeFollowerFromJob(input: $input)
  }
`

export const CHANGE_JOB_REQUIREMENTS = gql`
  mutation ChangeJobRequirements($input: ChangeJobRequirementsInput!) {
    changeJobRequirements(input: $input)
  }
`

export const REQUEST_JOB_BATCH = gql`
  mutation RequestJobBatch($input: RequestJobBatchInput!) {
    requestJobBatch(input: $input)
  }
`

// Subscription

export const CREATE_JOB_SUBSCRIPTION = gql`
  subscription CreateJobSubscription($id: ID!) {
    jobCreated(id: $id) {
      ...job
    }
  }
  ${Job}
`

export const CLOSE_JOB_SUBSCRIPTION = gql`
  subscription CloseJobSubscription($id: ID!) {
    jobClosed(id: $id) {
      ...jobDetail
    }
  }
  ${JobDetail}
`

export const REOPEN_JOB_SUBSCRIPTION = gql`
  subscription ReopenJobSubscription($id: ID!) {
    jobReopened(id: $id) {
      ...jobDetail
    }
  }
  ${JobDetail}
`

export const JOB_RECRUITERS_CHANGED_SUBSCRIPTION = gql`
  subscription JobRecruitersChanged($id: ID!) {
    jobRecruitersChanged(jobId: $id) {
      ...accountWithName
    }
  }
  ${AccountWithName}
`

export const JOB_FOLLOWERS_CHANGED_SUBSCRIPTION = gql`
  subscription JobFollowersChanged($id: ID!) {
    jobFollowersChanged(jobId: $id) {
      ...accountWithName
    }
  }
  ${AccountWithName}
`
export const JOB_REQUIREMENTS_CHANGED_SUBSCRIPTION = gql`
  subscription JobRequirementsChanged($id: ID!) {
    jobRequirementsChanged(jobId: $id) {
      ...jobDetail
    }
  }
  ${JobDetail}
`
export const JOB_BATCH_REQUESTED_SUBSCRIPTION = gql`
  subscription JobBatchRequested($id: ID!) {
    jobBatchRequested(jobId: $id) {
      ...jobBatch
    }
  }
  ${JobBatch}
`
