import React, { memo, useEffect } from 'react'
import { useStyles } from './style'
import { FormField } from 'components'
import { IS_VALID_URL } from 'gql/query'
import { useLazyQuery } from 'gql/hooks'
import useDebounceInput from 'hooks/debounce'

interface Props {
  defaultValue?: string
  placeholder: string
  setIsValidUrl: (value: boolean) => void
  setValue?: (value: string) => void
  dispatchValue?: (index: number, value: string) => void
  index?: number
  endAdornment?: React.ReactNode
}
const RequiredInput = ({
  defaultValue,
  placeholder,
  setIsValidUrl,
  setValue,
  dispatchValue,
  index,
  endAdornment
}: Props): React.ReactElement => {
  const classes = useStyles()
  const { debouncedSearchTerm, setSearchTerm, searchTerm } =
    useDebounceInput(defaultValue)
  const [isValidUrl, { data: validationResult }] = useLazyQuery(IS_VALID_URL, {
    variables: {
      url: debouncedSearchTerm
    }
  })
  useEffect(() => {
    if (debouncedSearchTerm) {
      isValidUrl()
    }
  }, [debouncedSearchTerm, isValidUrl])
  useEffect(() => {
    if (validationResult) {
      setIsValidUrl && setIsValidUrl(validationResult.isValidUrl)
    }
    // eslint-disable-next-line
  }, [validationResult])

  return (
    <FormField
      fullWidth
      error={
        searchTerm !== '' && validationResult && !validationResult.isValidUrl
      }
      helperText={'Please enter a valid URL'}
      onChange={(e: any) => {
        setSearchTerm(e.target.value)
        setValue && setValue(e.target.value)
        dispatchValue && dispatchValue(index!, e.target.value)
      }}
      className={classes.textFieldWithDelete}
      value={searchTerm}
      placeholder={placeholder}
      InputProps={{
        endAdornment: endAdornment
      }}
    />
  )
}

export default memo(RequiredInput)
