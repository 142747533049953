import React from 'react'
import { useStyles } from '../style'
import { Color, Paper, EducationIcon } from 'components'
import { CardContent, Typography, Grid } from '@mui/material'
import { EducationRecord } from 'model/profile'

interface Props {
  educationRecords: EducationRecord[]
}

const Education = ({ educationRecords }: Props): React.ReactElement => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <CardContent>
        <Typography
          variant='h4'
          color={Color.black87}
          paddingBottom={3}
          fontWeight='500'
        >
          Education
        </Typography>
        {educationRecords &&
          educationRecords.map(
            (educationRecord: EducationRecord, index: number) => (
              <Grid
                container
                key={`education_${index}`}
                spacing={2}
                className={classes.education}
              >
                <Grid item>
                  <EducationIcon />
                </Grid>
                <Grid item>
                  <Typography variant='body1' color={Color.black}>
                    {educationRecord.school}
                  </Typography>
                  <Typography
                    variant='body2'
                    color={Color.grey700}
                    lineHeight='20px'
                  >
                    {educationRecord.diploma?.replace(',', ' •')}
                  </Typography>
                </Grid>
              </Grid>
            )
          )}
      </CardContent>
    </Paper>
  )
}

export default Education
