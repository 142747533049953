import React from 'react'
import Box from '@mui/material/Box'
import Button, { ButtonProps } from '@mui/material/Button'
import { ReactNode } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { styled } from '@mui/material/styles'

interface CardLinkProps {
  children?: ReactNode
}

const StyledButton = styled(Button)<ButtonProps>(() => ({
  fontSize: '1rem',
  fontWeight: 700,
  color: '#000000',
  padding: 0,
  borderRadius: 0,
  '& .text-wrapper': {
    borderBottom: '1px solid white',
    lineHeight: '18px',
    textTransform: 'none'
  },
  '&:hover': {
    background: 'transparent',
    '& .text-wrapper': {
      borderBottom: '1px solid black'
    }
  }
}))

const CardButtonLink = (props: CardLinkProps) => {
  return (
    <StyledButton endIcon={<ArrowForwardIcon />}>
      <Box className='text-wrapper'>{props.children}</Box>
    </StyledButton>
  )
}

export default CardButtonLink
