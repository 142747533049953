import React, { memo, useState, useEffect } from 'react'
import clsx from 'clsx'
import MoveCandidate from './Move'
import TransferCandidate from './Transfer'
import { Typography } from '@mui/material'
import { PipelineStage } from 'model/jobPipeline'
import { Menu, MenuItem, useStyles } from '../style'
import { GET_TRANSFERRABLE_JOBS } from 'gql/query'
import { useLazyQuery } from 'gql/hooks'

interface Props {
  selectedRow: any
  anchorEl: any
  setAnchorEl: (_e: any) => void
}

const CandidateActions = ({
  selectedRow,
  anchorEl,
  setAnchorEl
}: Props): React.ReactElement => {
  const classes = useStyles()
  const [openMove, setOpenMove] = useState(false)
  const [openTransfer, setOpenTransfer] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const [transferrableJobs, setTransferrableJobs] = useState([])
  const currentStage = selectedRow?.stage

  const [getTransferrableJobs, { data, loading }] = useLazyQuery(
    GET_TRANSFERRABLE_JOBS,
    {
      variables: {
        first: 5,
        candidateId: selectedRow.id,
        searchTerm
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  // rerun the query whenever anchorEl is set/changed or searchTerm changes
  useEffect(
    () => anchorEl && getTransferrableJobs(),
    [getTransferrableJobs, anchorEl, searchTerm]
  )

  // while the request is still in flight `data` is momentarily set to
  // `undefined`, this causes some flickering in the elements using this data
  // for rendering, we avoid this effect by only updating the array used for
  // rendering when data is actually loaded
  useEffect(() => {
    if (data?.transferrableJobsForCandidate?.edges) {
      setTransferrableJobs(
        data.transferrableJobsForCandidate.edges.map((n: any) => n.node)
      )
    }
  }, [data, setTransferrableJobs])

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => {
          setAnchorEl(undefined)
        }}
      >
        <MenuItem
          className={clsx(classes.shortMenuItem, {
            [classes.disabled]: currentStage === PipelineStage.TO_REVIEW
          })}
          onClick={() => {
            setAnchorEl(undefined)
            setOpenMove(true)
          }}
        >
          <Typography variant='body1'>Move lead</Typography>
        </MenuItem>
        <MenuItem
          disabled={data?.transferrableJobsForCandidate?.edges.length === 0}
          className={classes.shortMenuItem}
          onClick={() => {
            setAnchorEl(undefined)
            setOpenTransfer(true)
          }}
        >
          <Typography variant='body1'>Transfer lead</Typography>
        </MenuItem>
      </Menu>
      <MoveCandidate
        open={openMove}
        setOpen={setOpenMove}
        selectedRow={selectedRow}
      />

      <TransferCandidate
        open={openTransfer}
        setOpen={setOpenTransfer}
        selectedRow={selectedRow}
        setJobSearchTerm={setSearchTerm}
        transferrableJobs={transferrableJobs}
        isTransferrableJobsLoading={loading}
      />
    </>
  )
}

export default memo(CandidateActions)
