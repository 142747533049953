import React, { memo } from 'react'
import { Button } from '@mui/material'
import { useStyles } from './style'
import { DialogWithoutAction, ErrorMessage } from 'components'

const Dialog = ({
  closeJobOpen,
  setCloseJobOpen,
  closeJob
}: any): React.ReactElement => {
  const classes = useStyles()

  return (
    <DialogWithoutAction
      open={closeJobOpen}
      setOpen={setCloseJobOpen}
      title={'Close this job?'}
      Action={() => (
        <>
          <Button
            onClick={() => {
              setCloseJobOpen(false)
            }}
            variant='outlined'
            color='secondary'
            className={classes.dialogButtons}
          >
            Cancel and go back
          </Button>
          <Button
            onClick={async () => {
              closeJob()
              setCloseJobOpen(false)
            }}
            className={classes.dialogButtons}
            variant='contained'
            color='primary'
          >
            Yes, close job
          </Button>
        </>
      )}
    >
      <div style={{ paddingBottom: 0 }}>
        <ErrorMessage>
          This means you won’t see stats for this job on your dashboard and
          we’ll stop work on any batches that are in progress. You can still
          access leads associated with this job and job details.
        </ErrorMessage>
      </div>
    </DialogWithoutAction>
  )
}

export default memo(Dialog)
