import { useState, useEffect, useRef, useContext } from 'react'
import { useSubscription as useApolloSubscription } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { DocumentNode } from 'graphql'
import { SubscriptionHookOptions } from '@apollo/client/react/types/types'
import { OperationVariables } from '@apollo/client/core'
import { Subscription } from 'model/apollo'
import { MicroServiceContext } from 'context/MicroService'
const useSubscription: any = (
  document: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: SubscriptionHookOptions<any, OperationVariables> & Subscription
) => {
  const [returned, setReturned] = useState(false)
  const { displayNotification } = useContext(MicroServiceContext)
  const newOptions: any = { ...options }
  const onSubscriptionData = (res: any) => {
    if (newOptions.onSubscriptionData) {
      setReturned(true)
      newOptions.onSubscriptionData(res)
    }
  }

  const result = useApolloSubscription(document, {
    ...options,
    onSubscriptionData: onSubscriptionData
  } as SubscriptionHookOptions<any, OperationVariables>)
  const countRef = useRef(returned)
  countRef.current = returned
  const timeouts = useRef<any>()
  useEffect(
    () => () => {
      clearTimeout(timeouts.current)
    },
    [timeouts]
  )
  useEffect(() => {
    const extraOption = options as Subscription
    if (extraOption && extraOption.isSubscriptionEphemeral !== false) {
      timeouts.current = setTimeout(() => {
        if (countRef.current === false) {
          displayNotification('Sorry, we seem to be having network issues')
        }
      }, extraOption.subscriptionTimeout || 5000)
    }
    return () => {
      setReturned(true)
    }
    // eslint-disable-next-line
  }, [])

  return { ...result }
}

export default useSubscription
