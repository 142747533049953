import { PaginationState } from 'model'
export const INITIAL_STAFF_MEMBER_FILTER = {
  jobAccess: [],
  ehiAccess: [],
  role: [],
  name: '',
  isUsingSeat: undefined,
  statuses: []
}
export enum FilterIndex {
  jobAccess = 'jobAccess',
  ehiAccess = 'ehiAccess',
  role = 'role',
  isUsingSeat = 'isUsingSeat',
  statuses = 'statuses'
}
export const INITIA_PAGINATION_STATE: PaginationState = {
  currentPage: 0,
  rowsPerPage: 10,
  cursor: undefined
}

export const TABLE = {
  TABLE_HEADS: [
    {
      id: 'name',
      label: 'User',
      width: '25%'
    },
    {
      id: 'role',
      label: 'Role',
      width: '15%'
    },
    {
      id: 'job_access',
      label: 'Job access',
      width: '20%'
    },
    {
      id: 'EI_access',
      label: 'EI access',
      width: '20%'
    },
    {
      id: 'using_seat',
      label: 'Using seat?',
      width: '10%'
    },
    {
      id: 'action',
      label: '',
      width: '10%'
    }
  ]
}
