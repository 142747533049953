import { Color } from 'components'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  textFieldWithDelete: {
    '& .MuiOutlinedInput-root': { paddingRight: 7 },
    '& .MuiIconButton-root': {
      backgroundColor: '#E8EBED',
      width: 30,
      height: 30,
      '&:disabled': { display: 'none' }
    },
    '& .MuiSvgIcon-root': {
      color: Color.grey700,
      width: 22,
      height: 22
    }
  }
}))
