import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import useStyles from './style'
import Blue from './blue.png'
import Red from './red.png'
import { Color } from 'components'
import VideoModal from 'components/CMS/VideoModal'
import CircularProgress from './CircularProgress'
import { GET_DASHBOARD_NUMS } from 'gql/query'
import { useQuery } from 'gql/hooks'
import { DashboardContext } from 'context/DashboardContext'
import { format } from 'date-fns'
const INITIAL_DATA = {
  completedBatchesSinceStartOfMonthCount: 0,
  numCandidatesAddedInLastWeek: 0,
  numCandidatesAddedSinceStartOfMonth: 0,
  totalBatchSizeRequestedSinceStartOfMonthCount: 0,
  myEmployer: {
    monthlyCreditsAllowance: 0
  }
}

const Dashboard = (): React.ReactElement => {
  const classes = useStyles()

  const { data = INITIAL_DATA, error, loading } = useQuery(GET_DASHBOARD_NUMS)
  const {
    completedBatchesSinceStartOfMonthCount,
    numCandidatesAddedInLastWeek,
    numCandidatesAddedSinceStartOfMonth,
    totalBatchSizeRequestedSinceStartOfMonthCount,
    myEmployer
  } = data
  const { homePage } = useContext(DashboardContext)
  const { featured, fallbackImage } = homePage
  if (loading) return <div className={classes.placeHolder} />
  return (
    <Grid container className={classes.videoContainer}>
      {error ? (
        <VideoModal
          videoUrl={featured.externalVideo?.url || featured.video.video.mp4Url}
          image={fallbackImage.responsiveImage}
        ></VideoModal>
      ) : (
        <div className={classes.statisticsBlockContainer}>
          <img
            src={Blue}
            className={classes.blueShape}
            alt='MathisonBlueShape'
          ></img>
          <img
            src={Red}
            className={classes.redShape}
            alt='MathisonRedShape'
          ></img>
          <Grid container>
            <Grid item xs={4} container>
              <div className={classes.centralize}>
                <Typography
                  variant='formLabel'
                  component='div'
                  letterSpacing='2px'
                >
                  NEW LEADS THIS WEEK
                </Typography>
                <div className={classes.newLeads}>
                  <Typography
                    variant='h7'
                    fontSize='136px'
                    lineHeight='240px'
                    fontWeight={400}
                    letterSpacing='-13px'
                    color={Color.main}
                  >
                    +{`${numCandidatesAddedInLastWeek}`}
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} container>
              <div className={classes.centralize}>
                <Typography
                  variant='formLabel'
                  component='div'
                  letterSpacing='2px'
                >
                  TOTAL LEADS
                </Typography>
                <div className={classes.totalLeads}>
                  <CircularProgress
                    color='#FFD600'
                    value={
                      (numCandidatesAddedSinceStartOfMonth /
                        totalBatchSizeRequestedSinceStartOfMonthCount) *
                      100
                    }
                  />
                  <Typography
                    variant='h3'
                    fontSize='48px'
                    lineHeight='100px'
                    letterSpacing='-0.5px'
                    position='relative'
                    top='60px'
                  >
                    {`${numCandidatesAddedSinceStartOfMonth}/${totalBatchSizeRequestedSinceStartOfMonthCount}`}
                    <Typography
                      variant='h3'
                      fontSize='16px'
                      lineHeight='20px'
                      letterSpacing='2px'
                      component='p'
                    >
                      {`${format(new Date(), 'MMMM').toLocaleUpperCase()}`}
                    </Typography>
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} container>
              <div className={classes.centralize}>
                <Typography
                  variant='formLabel'
                  component='div'
                  letterSpacing='2px'
                >
                  COMPLETED BATCHES
                </Typography>
                <div className={classes.totalLeads}>
                  <CircularProgress
                    value={
                      (completedBatchesSinceStartOfMonthCount /
                        myEmployer.monthlyCreditsAllowance) *
                      100
                    }
                    color='#B80871'
                  />
                  <Typography
                    variant='h3'
                    fontSize='48px'
                    lineHeight='100px'
                    letterSpacing='-0.5px'
                    position='relative'
                    top='60px'
                  >
                    {`${completedBatchesSinceStartOfMonthCount}/${myEmployer.monthlyCreditsAllowance}`}
                    <Typography
                      variant='h3'
                      fontSize='16px'
                      lineHeight='20px'
                      letterSpacing='2px'
                      component='p'
                    >
                      {`${format(new Date(), 'MMMM').toLocaleUpperCase()}`}
                    </Typography>
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </Grid>
  )
}

export default Dashboard
