import React from 'react'

import { PopupSelectList, Menu } from 'components'

interface Props {
  filterState: any
  dispatch: (data: any) => void
  items: string[]
  editingType: string
  setRoleAnchorEl: (el: any) => void
  setIsUsingSeatAnchorEl: (el: any) => void
  roleAnchorEl: any
  isUsingSeatAnchorEl: any
}

const Dialogs = (props: Props) => {
  const {
    filterState,
    dispatch,
    items,
    editingType,
    setRoleAnchorEl,
    setIsUsingSeatAnchorEl,
    roleAnchorEl,
    isUsingSeatAnchorEl
  } = props

  const updateSelectedItems = {
    add: (content: any) => {
      const selectedItems = [...filterState[editingType]]
      selectedItems.push(content)
      dispatch({ index: editingType, value: selectedItems })
    },
    remove: (content: any) => {
      const selectedItems = filterState[editingType].filter(
        (item: any) => item !== content
      )
      dispatch({ index: editingType, value: selectedItems })
    },

    clear: () => {
      dispatch({ index: editingType, value: [] })
    }
  }

  return (
    <>
      <Menu
        anchorEl={isUsingSeatAnchorEl}
        setAnchorEl={setIsUsingSeatAnchorEl}
        selectedItem={filterState.userType}
        items={[
          {
            label: 'All',
            onClick: () => {
              dispatch!({ index: 'isUsingSeat', value: undefined })
            }
          },
          {
            label: 'Yes',
            onClick: () => {
              dispatch!({ index: 'isUsingSeat', value: true })
            }
          },
          {
            label: 'No',
            onClick: () => {
              dispatch!({ index: 'isUsingSeat', value: false })
            }
          }
        ]}
      />
      <PopupSelectList
        anchorEl={roleAnchorEl}
        setAnchorEl={setRoleAnchorEl}
        items={items}
        updateSelectedItems={updateSelectedItems}
        selectedItems={filterState[editingType]}
      />
    </>
  )
}

export default Dialogs
