import { makeStyles, withStyles } from '@mui/styles'
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  TableCell as MuiTableCell
} from '@mui/material'
import { Color } from 'components'

export const useStyles = makeStyles({
  container: {
    display: 'flex'
  },
  clickable: { cursor: 'pointer' },
  button: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: Color.black,
    '& .MuiButton-endIcon': {
      margin: 0
    },
    '&:disabled': {
      color: Color.black40
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  basicInfoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  basicInfo: {
    marginLeft: 24,
    width: '80%',
    '& .jobTitle': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      lineClamp: 1,
      webkitLineClamp: 1,
      maxHeight: '18px'
    }
  },
  expendButton: {
    color: Color.grey700,
    padding: 6,
    fontSize: 24
  },
  viewButton: {
    marginRight: 20,
    cursor: 'pointer'
  },
  maskedAvatar: {
    backgroundColor: '#D7DCDF',
    width: 40,
    height: 40,
    borderRadius: 40
  },
  boldText: {
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer',
    color: Color.black80
  }
})

export const Tabs = withStyles({
  indicator: {
    backgroundColor: `${Color.black} !important`
  }
})(MuiTabs)

export const Tab = withStyles({
  root: {
    minWidth: '168px !important'
  }
})(MuiTab)

export const TableCell = withStyles({
  root: {
    paddingTop: 16,
    paddingBottom: 16
  }
})(MuiTableCell)
