import { gql } from '@apollo/client'
export const LOGIN_APP_MUTATION = gql`
  mutation login($idToken: String!) {
    login(idToken: $idToken)
  }
`
export const APP_LOGIN_PROCESSED_SUBSCRIPTION = gql`
  subscription LoginProcessed($jwtId: String!) {
    loginProcessed(jwtId: $jwtId) {
      ... on User {
        id
        name
        primaryEmail
        isFirstLogin
      }
      ... on LoginError {
        type
        message
      }
    }
  }
`
