import { makeStyles } from '@mui/styles'
import { Color } from 'components'
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: '10px 14px',
    backgroundColor: Color.info10,
    borderRadius: 5
  },
  fullwith: { width: '100%' },
  content: {
    lineHeight: '16px',
    marginLeft: 14,
    marginBottom: 0,
    height: 16
  }
}))
export default useStyles
