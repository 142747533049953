import React from 'react'
import ReactPlayer from 'react-player'
import { styled } from '@mui/material/styles'

interface KCVideoProps {
  videoUrl: string
  image?: boolean | string
  playing?: boolean
  height?: string
  marginTop?: string
}

type PlayerWrapperProps = {
  height?: string
  marginTop?: string
}

const PlayerWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'height' && prop !== 'marginTop'
})<PlayerWrapperProps>(({ height, marginTop }) => ({
  position: 'relative',
  width: '100%',
  height: height || '600px',
  marginTop: marginTop || '0',
  '& .react-player': {
    '& .react-player__shadow': {
      backgroundColor: 'black !important',
      borderRadius: '8px !important',
      width: '85px !important',
      height: '85px !important'
    },
    video: {
      backgroundColor: 'black'
    }
  }
}))

const VideoPlayer = ({
  videoUrl,
  playing,
  height,
  marginTop
}: KCVideoProps) => {
  return (
    <PlayerWrapper height={height} marginTop={marginTop}>
      <ReactPlayer
        className='react-player'
        url={videoUrl}
        width='100%'
        height='100%'
        controls={true}
        light={false}
        playing={playing}
        config={{
          file: {
            attributes: {
              onContextMenu: (e: Event) => e.preventDefault()
            }
          }
        }}
      />
    </PlayerWrapper>
  )
}
export default VideoPlayer
