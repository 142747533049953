import React from 'react'
import { useStyles } from './style'
import {
  Button,
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText
} from '@mui/material'
import { useMutation, useSubscription } from 'gql/hooks'
import { Color } from 'components'
import { INTEREST_LEVEL } from './constants'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { StructuredFeedback, CandidateReviewInput } from 'model/jobPipeline'
import { DispatchContent } from 'model'
import { ACCEPT_CANDIDATE, REJECT_CANDIDATE } from 'gql/command'
import {
  CANDIDATE_ACCEPTED_SUBSCRIPTION,
  CANDIDATE_REJECTED_SUBSCRIPTION
} from 'gql/subscription'

interface Props {
  candidateId: string
  totalCount: number
  currentPage: number
  reviewInput: CandidateReviewInput
  setReviewInput: (input: DispatchContent) => void
  prevPage: () => void
  nextPage: () => void
  handlePostFeedback: () => void
  disabled: boolean
}
const ReviewPane = ({
  candidateId,
  totalCount,
  currentPage,
  reviewInput,
  setReviewInput,
  prevPage,
  nextPage,
  disabled,
  handlePostFeedback
}: Props): React.ReactElement => {
  const classes = useStyles()
  const [acceptCandidateMutation] = useMutation(ACCEPT_CANDIDATE)
  const [rejectCandidateMutation] = useMutation(REJECT_CANDIDATE)

  const subscriptionDoc =
    reviewInput.structuredFeedback === StructuredFeedback.NOT_INTERESTED
      ? CANDIDATE_REJECTED_SUBSCRIPTION
      : CANDIDATE_ACCEPTED_SUBSCRIPTION
  useSubscription(subscriptionDoc, {
    variables: { id: candidateId },
    isSubscriptionEphemeral: false,
    onSubscriptionData: () => handlePostFeedback()
  })

  const handleClickNext = () => {
    if (!reviewInput.structuredFeedback) {
      nextPage()
    } else if (
      reviewInput.structuredFeedback === StructuredFeedback.NOT_INTERESTED
    ) {
      rejectCandidateMutation({
        variables: {
          input: {
            candidateId: candidateId,
            structuredFeedback: StructuredFeedback.NOT_INTERESTED,
            generalFeedback: reviewInput.generalFeedback
          }
        }
      })
    } else {
      acceptCandidateMutation({
        variables: {
          input: {
            candidateId: candidateId,
            structuredFeedback: reviewInput.structuredFeedback,
            generalFeedback: reviewInput.generalFeedback
          }
        }
      })
    }
  }

  return (
    <Grid className={classes.reviewPane}>
      <Grid className={classes.actionContainer}>
        <Button
          className='prevButton'
          disabled={currentPage === 0}
          onClick={() => prevPage()}
        >
          Previous
        </Button>
        <Button
          className='nextButton'
          variant='contained'
          onClick={() => handleClickNext()}
        >
          Next
        </Button>
      </Grid>
      <Grid className={classes.reviewPaneContent}>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          className={classes.title}
        >
          <Grid display='flex' alignItems='center'>
            <Typography variant='h1' color={Color.black}>
              Feedback
            </Typography>
            <InfoOutlinedIcon className='infoIcon' />
          </Grid>
          <Typography variant='body2Medium' color={Color.black75}>
            {totalCount > 0 && `${currentPage + 1} of ${totalCount}`}
          </Typography>
        </Grid>
        <Typography variant='formLabel' color={Color.black}>
          Leave a comment (optional)
        </Typography>
        <Typography variant='body2' color={Color.black75}>
          This helps our recruiters provide you more relevant leads.
        </Typography>
        <TextField
          fullWidth
          disabled={disabled}
          multiline
          rows={3}
          value={reviewInput.generalFeedback}
          onChange={e =>
            setReviewInput({
              index: 'generalFeedback',
              value: e.target.value
            })
          }
          variant='outlined'
          className='inputBox'
          placeholder='Eg. Overqualified, needs more managerial experience'
        />
        <Typography variant='formLabel' color={Color.black}>
          Overall interest
        </Typography>
        <List>
          {INTEREST_LEVEL.map((item: any, index: number) => (
            <ListItem
              button
              disabled={disabled}
              key={`interest_option-${index}`}
              className='selection'
              classes={{ selected: 'selected' }}
              selected={reviewInput.structuredFeedback === item.value}
              onClick={() =>
                setReviewInput({
                  index: 'structuredFeedback',
                  value: item.value
                })
              }
            >
              <ListItemText
                primary={
                  <Grid container alignItems='center'>
                    <item.icon className='icon' />
                    <Typography variant='body1' color={Color.black}>
                      {item.title}
                    </Typography>
                    <div
                      className='tag'
                      style={{
                        backgroundColor:
                          item.title === 'Not interested'
                            ? '#E8EBED'
                            : '#E6F8F8'
                      }}
                    >
                      <Typography variant='body2Medium' color={Color.black}>
                        {item.tag}
                      </Typography>
                    </div>
                  </Grid>
                }
                secondary={
                  <Typography variant='body2' color={Color.black75}>
                    {item.description}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  )
}

export default ReviewPane
