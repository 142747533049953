import { JobTypeValue } from 'model/jobPipeline'
export const JOB_TYPE_OPTIONS = [
  {
    value: JobTypeValue.STANDARD,
    tag: 'Standard',
    description:
      'Roles that are mid to intermediate level, eg. Manager, Senior Manager, Individual Contributor with 1+ years of experience.'
  },
  {
    value: JobTypeValue.SENIOR,
    tag: 'Senior and specialized',
    description:
      'Roles that are senior to advanced, eg. C Suite positions, VP Director, Group Manager, Highly Specialized Individual Contributor.'
  }
]

export const BATCH_SIZE_OPTIONS = [
  {
    value: '10 leads in 4 business days',
    totalLeads: '10',
    deadline: 'in 4 business days'
  },
  {
    value: '20 leads in 7 business days',
    totalLeads: '20',
    deadline: 'in 7 business days'
  },
  {
    value: '20 leads every month',
    totalLeads: '20',
    deadline: 'every month'
  }
]

export const JOB_REQUIREMENT_STEPS = [
  'Job title',
  'Job description',
  'Locations',
  'Example profile',
  'Skills',
  'Company preferences',
  'Comments and feedback'
]

export const learnMoreUrl =
  'https://mathison.notion.site/mathison/What-types-of-jobs-does-Mathison-support-d777397973d143fab90bb82aea6f7c73'

export const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ align: '' }, { align: 'right' }, { align: 'justify' }],

  ['clean'] // remove formatting button
]
