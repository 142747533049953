import React from 'react'
import { useStyles } from '../style'
import { Color, Paper } from 'components'
import { CardContent, Typography, Grid } from '@mui/material'
import { LanguageExperience } from 'model/profile'

interface Props {
  languagesBlock: LanguageExperience[]
}

const Languages = ({ languagesBlock }: Props): React.ReactElement => {
  const classes = useStyles()
  return (
    <Paper>
      <CardContent>
        <Typography
          variant='h5'
          color={Color.black87}
          paddingBottom={3}
          fontWeight='500'
        >
          Languages
        </Typography>
        <Grid container>
          {languagesBlock &&
            languagesBlock.map(
              (languageExperience: LanguageExperience, index: number) => (
                <div key={`language-${index}`} className={classes.language}>
                  <Typography variant='body1' color={Color.black} fontSize={14}>
                    {languageExperience.language}
                  </Typography>
                  {index !== languagesBlock.length - 1 && (
                    <Typography
                      variant='body1'
                      color={Color.black}
                      fontSize={14}
                      paddingLeft={'2px'}
                      paddingRight={'2px'}
                    >
                      •
                    </Typography>
                  )}
                </div>
              )
            )}
        </Grid>
      </CardContent>
    </Paper>
  )
}

export default Languages
