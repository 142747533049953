export enum AccessMethod {
  'INVITATION_ONLY' = 'INVITATION_ONLY',
  'DOMAIN_LOCK' = 'DOMAIN_LOCK'
}
export enum ServiceProvider {
  'Select' = 'Select',
  'Google Workspace' = 'GOOGLE',
  'Office 365' = 'MICROSOFT'
}
export const InitialDomainState = {
  companyDomain: '',
  serviceProvider: ServiceProvider.Select
}
export interface DomainState {
  companyDomain: string
  serviceProvider: string
}
