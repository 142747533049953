import React from 'react'
import { Color } from 'components'
import { makeStyles, styled } from '@mui/styles'
import { Chip as MuiChip, ChipProps } from '@mui/material'
import { CancelRounded as CancelRoundedIcon } from '@mui/icons-material'

export const useStyles = makeStyles(() => ({
  radioButton: {
    height: 20
  },
  formLabel: {
    marginTop: 16
  },
  selectMenuItem: {
    width: 552
  },
  deleteIcon: {
    color: Color.grey700
  }
}))

export const Chip = styled((props: ChipProps) => (
  <MuiChip deleteIcon={<CancelRoundedIcon />} {...props} />
))(() => ({
  zIndex: 100,
  position: 'relative',
  height: 30,
  width: 44,
  marginRight: 8,
  color: Color.grey700,
  backgroundColor: '#E8EBED',
  '& .MuiChip-label': {
    marginRight: 4,
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.01em'
  },
  '& .MuiChip-deleteIcon': { color: Color.grey700 }
}))
