import React, { useState, useContext } from 'react'
import { BatchStatus } from 'model/jobPipeline'
import { EmployerStaffAccountJobsAccessLevel } from 'model/organization'
import Header from './Header'
import Board from './Board'
import CandidateTable from './CandidateTable'
import { Grid, Typography } from '@mui/material'
import FullScreenDialog from 'context/FullScreenDialog'
import { MyJobContext } from 'context/JobContext'
import JobSettings from './Settings'
import RequestBatch from './RequestBatch'
import NoBatchLeft from './NoBatchLeft'
import { InfoBanner } from 'components'
import { MicroServiceContext } from 'context/MicroService'

const SelectedJob = (): React.ReactElement => {
  const [openJobSettingDialog, setOpenJobSettingDialog] = useState(false)
  const [openBatchRequestDialog, setOpenBatchRequestDialog] = useState(false)

  const { currentAccount } = useContext(MicroServiceContext)
  const isJobsLead =
    currentAccount?.jobsAccess === EmployerStaffAccountJobsAccessLevel.JOBS_LEAD
  const { job, numRemainingCreditsForMonth, setOpenNoBatchLeft } =
    useContext(MyJobContext)
  const currentBatch = job!.currentBatch

  return (
    <FullScreenDialog.Provider
      value={{
        openJobSetting: openJobSettingDialog,
        setOpenJobSetting: setOpenJobSettingDialog,
        openBatchRequest: openBatchRequestDialog,
        setOpenBatchRequest: setOpenBatchRequestDialog
      }}
    >
      {job && (
        <>
          <JobSettings />
          <RequestBatch />
        </>
      )}

      {currentBatch?.status === BatchStatus.COMPLETED && (
        <InfoBanner
          type='info'
          fullWidth
          sx={{ padding: '16px 40px' }}
          content={
            isJobsLead ? (
              <>
                This batch is complete. To have new leads added to this
                pipeline,{' '}
                <Typography
                  variant='body2Medium'
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onClick={() => {
                    numRemainingCreditsForMonth < 1
                      ? setOpenNoBatchLeft(true)
                      : setOpenBatchRequestDialog(true)
                  }}
                >
                  request leads
                </Typography>
                .
              </>
            ) : (
              <>This batch is complete.</>
            )
          }
        />
      )}
      {currentBatch?.status === BatchStatus.CANCELLED && (
        <InfoBanner
          type='info'
          fullWidth
          sx={{ padding: '16px 40px' }}
          content={
            isJobsLead ? (
              <>
                This batch is cancelled.{' '}
                <Typography
                  variant='body2Medium'
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }}
                  onClick={() => {
                    numRemainingCreditsForMonth < 1
                      ? setOpenNoBatchLeft(true)
                      : setOpenBatchRequestDialog(true)
                  }}
                >
                  Request leads
                </Typography>
                .
              </>
            ) : (
              <>This batch is cancelled.</>
            )
          }
        />
      )}
      <Header />
      <Grid container padding={'36px'}>
        <Board title={'Leads delivered'} count={currentBatch?.deliveredLeads} />
        <Board
          title={'Remaining leads'}
          count={
            currentBatch?.status === BatchStatus.CANCELLED
              ? undefined
              : currentBatch?.remainingLeads
          }
        />
        <Board
          title={'Batch due'}
          dueDate={currentBatch?.dueAt}
          showDueDate={
            currentBatch?.status === BatchStatus.REQUESTED ||
            currentBatch?.status === BatchStatus.IN_PROGRESS
          }
        />
      </Grid>
      <Grid container padding={'0px 32px 40px'}>
        <CandidateTable />
      </Grid>
      <NoBatchLeft />
    </FullScreenDialog.Provider>
  )
}

export default SelectedJob
