import { gql } from '@apollo/client'
import {
  Profile,
  ContactInfoBlock,
  ContactInfoEnrichmentStatus
} from './fragments'
// Queries.

export const GET_PROFILE = gql`
  query GetProfile($nanoid: String!, $imgixParams: ImgixParamsInput) {
    profile(nanoid: $nanoid) {
      ...profile
    }
  }
  ${Profile}
`

export const GET_PROFILE_CONTACT_INFO = gql`
  query GetProfileContactInfo($profileId: ID!) {
    profileContactInfo(profileId: $profileId) {
      ... on ContactInfoBlock {
        ...contactInfoBlock
      }
      ... on ContactInfoEnrichmentStatus {
        ...contactInfoEnrichmentStatus
      }
    }
  }
  ${ContactInfoBlock}
  ${ContactInfoEnrichmentStatus}
`

// Commands.
// Subscription

export const CONTACT_INFO_STATUS_UPDATED_SUBSCRIPTION = gql`
  subscription ContactInfoStatusUpdated($profileId: ID!) {
    contactInfoEnrichmentStatusUpdated(profileId: $profileId) {
      ... on ContactInfoBlock {
        ...contactInfoBlock
      }
      ... on ContactInfoEnrichmentStatus {
        ...contactInfoEnrichmentStatus
      }
    }
  }
  ${ContactInfoBlock}
  ${ContactInfoEnrichmentStatus}
`
