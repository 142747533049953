import { gql } from '@apollo/client'
import {
  PageInfo,
  PaginationInput,
  PaginationType,
  Profile,
  AvatarBlock,
  WorkHistoryBlock
} from './fragments'

// Queries.

export const GET_CANDIDATES = gql`
  query LoadCandidates(
    ${PaginationType}
    $jobId: ID
    $stage: CandidateStage
    $imgixParams: ImgixParamsInput
  ) {
    candidates(${PaginationInput}, jobId: $jobId, stage: $stage) {
      pageInfo{
        ...pageInfo
      }
      count
      edges {
        node {
          id
          insertedAt
          stage
          jobBatchNumber
          profile{
            ...profile
          }
        }
      }
    }
  }
  ${Profile}
  ${PageInfo}
`

export const GET_CANDIDATES_FOR_TABLE = gql`
  query LoadCandidatesForTable(
    ${PaginationType}
    $jobId: ID
    $stage: CandidateStage
    $imgixParams: ImgixParamsInput
  ) {
    candidates(${PaginationInput}, jobId: $jobId, stage: $stage) {
      pageInfo{
        ...pageInfo
      }
      count
      edges {
        node {
          id
          insertedAt
          stage
          jobBatchNumber
          profile{
            id
            nanoid
            avatarBlock{
              ...avatarBlock
            }
            basicInfoBlock{
              name
            }
            socialMediaBlock{
              primaryLinkedinUrl
            }
            workHistoryBlock{
              ...workHistoryBlock
            }
          }
        }
      }
    }
  }
  ${AvatarBlock}
  ${WorkHistoryBlock}
  ${PageInfo}
`
export const GET_CANDIDATE_COUNT = gql`
  query GetCandidatesCount($jobId: ID) {
    numCandidatesByStage(jobId: $jobId) {
      contacted
      toReview
      toContact
    }
  }
`
// Commands.

export const ACCEPT_CANDIDATE = gql`
  mutation AcceptCandidate($input: AcceptCandidateInput!) {
    acceptCandidate(input: $input)
  }
`

export const REJECT_CANDIDATE = gql`
  mutation RejectCandidate($input: RejectCandidateInput!) {
    rejectCandidate(input: $input)
  }
`

export const MOVE_CANDIDATE = gql`
  mutation MoveCandidate($input: MoveCandidateInput!) {
    moveCandidate(input: $input)
  }
`

export const TRANSFER_CANDIDATE = gql`
  mutation TransferCandidateToAnotherJob(
    $input: TransferCandidateToAnotherJobInput!
  ) {
    transferCandidateToAnotherJob(input: $input)
  }
`

export const SET_TALENT_MASKING_FLAG = gql`
  mutation SetTalentMaskingFlag(
    $setTalentMaskingFlagInput: SetTalentMaskingFlagInput!
  ) {
    setTalentMaskingFlag(input: $setTalentMaskingFlagInput)
  }
`
// Subscription

export const CANDIDATE_ACCEPTED_SUBSCRIPTION = gql`
  subscription CandidateAccepted($id: ID!) {
    candidateAccepted(id: $id) {
      id
      stage
    }
  }
`
export const CANDIDATE_REJECTED_SUBSCRIPTION = gql`
  subscription CandidateRejected($id: ID!) {
    candidateRejected(id: $id) {
      id
      stage
    }
  }
`
export const CANDIDATE_MOVED_SUBSCRIPTION = gql`
  subscription CandidateMoved($id: ID!) {
    candidateMoved(id: $id) {
      id
      stage
    }
  }
`
export const CANDIDATE_TRANSFERRED_SUBSCRIPTION = gql`
  subscription CandidateTransferred($id: ID!) {
    candidateTransferredFromAnotherJob(newCandidateId: $id) {
      id
    }
  }
`
