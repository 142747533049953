import React, { useEffect, useReducer, useState } from 'react'
import { useQuery, useSubscription } from 'gql/hooks'
import { GET_STAFF_MEMBERS } from 'gql/query'
import { PaginationState } from 'model'
import { Table } from 'components'
import TableContextProvider from 'context/TableContext'
import TableRowBuilder from './TableRowBuilder'
import {
  TABLE,
  INITIAL_STAFF_MEMBER_FILTER,
  INITIA_PAGINATION_STATE
} from './constants'
import { EMPLOYER_STAFF_ACCOUNT_INVITED } from 'gql/subscription'
import TableFilters from './TableFilters'
import TableTitle from './TableTitle'

const StaffMember = ({
  numEmployerStaffAccounts,
  numOpenSeats,
  numUsedSeats
}: any) => {
  const [isInEditMode, setIsInEditMode] = useState(false)
  const [filterState, dispatchFilter] = useReducer(
    (formState: any, action: any) => {
      return {
        ...formState,
        [action.index]: action.value
      }
    },
    INITIAL_STAFF_MEMBER_FILTER
  )
  const [paginationState, setPaginationState] = useReducer(
    (state: PaginationState, action: any) => {
      switch (action.type) {
        case 'rowsPerPageChange':
          return {
            ...state,
            rowsPerPage: action.value,
            currentPage: 0,
            cursor: undefined
          }
        case 'reset':
          return {
            ...state,
            cursor: undefined,
            currentPage: 0
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    INITIA_PAGINATION_STATE
  )
  const {
    data = { employerStaffAccounts: { edges: [] } },
    fetchMore,
    loading,
    refetch,
    subscribeToMore
  } = useQuery(GET_STAFF_MEMBERS, {
    variables: {
      first: paginationState.rowsPerPage,
      after: paginationState.cursor,
      searchTerm: filterState.name,
      ehiAccessLevels: filterState.ehiAccess,
      jobsAccessLevels: filterState.jobAccess,
      roles: filterState.role,
      isUsingSeat: filterState.isUsingSeat,
      statuses: filterState.statuses
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })
  const { employerStaffAccounts } = data
  const staffMember = data ? employerStaffAccounts.edges : undefined
  useSubscription(EMPLOYER_STAFF_ACCOUNT_INVITED, {
    onSubscriptionData: () => {
      refetch({
        searchTerm: filterState.name,
        ehiAccessLevels: filterState.ehiAccess,
        jobsAccessLevels: filterState.jobAccess,
        roles: filterState.role,
        isUsingSeat: filterState.isUsingSeat,
        statuses: filterState.statuses
      })
    },
    isSubscriptionEphemeral: false
  })
  useEffect(() => {
    if (refetch) {
      setPaginationState({
        type: 'reset'
      })
      refetch({
        searchTerm: filterState.name,
        ehiAccessLevels: filterState.ehiAccess,
        jobsAccessLevels: filterState.jobAccess,
        roles: filterState.role,
        isUsingSeat: filterState.isUsingSeat,
        statuses: filterState.statuses
      })
    }
    // eslint-disable-next-line
  }, [filterState, paginationState.rowsPerPage])
  const nextPage = (num: number) => {
    const { endCursor } = data.employerStaffAccounts.pageInfo
    if (fetchMore && num * paginationState.rowsPerPage >= staffMember.length)
      fetchMore({
        variables: {
          after: endCursor
        }
      })
  }

  return (
    <TableContextProvider
      searchBoxPlaceholder={'Search by user name or email'}
      emptyTabelText={'Try editing your search or filters.'}
      emptyTableTitle={'No results'}
      tableHeads={TABLE.TABLE_HEADS}
      tableTitle={''}
      TableRowBuilder={(props: any) => (
        <TableRowBuilder
          {...props}
          isInEditMode={isInEditMode}
          subscribeToMore={subscribeToMore}
        />
      )}
    >
      <Table
        loading={loading || staffMember === undefined}
        filterState={filterState}
        data={staffMember?.slice(
          paginationState.currentPage * paginationState.rowsPerPage,
          paginationState.currentPage * paginationState.rowsPerPage +
            paginationState.rowsPerPage
        )}
        dispatch={dispatchFilter}
        SecondaryFilters={TableFilters}
        SecondaryTitle={(props: any) => (
          <TableTitle
            {...props}
            isInEditMode={isInEditMode}
            setIsInEditMode={setIsInEditMode}
            numEmployerStaffAccounts={numEmployerStaffAccounts}
            numOpenSeats={numOpenSeats}
            numUsedSeats={numUsedSeats}
            subscribeToMore={subscribeToMore}
          ></TableTitle>
        )}
        count={data ? data.employerStaffAccounts.count : 0}
        paginationState={paginationState}
        setPaginationState={setPaginationState}
        fetchMore={nextPage}
      />
    </TableContextProvider>
  )
}

export default StaffMember
