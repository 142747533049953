import { makeStyles } from '@mui/styles'
import { Color } from 'components'
const useStyles = makeStyles(() => ({
  placeHolder: {
    height: 960
  },
  statisticsBlockContainer: {
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    borderRadius: 8,
    padding: '75px 104px',

    width: '100%',
    backgroundColor: '#FFF',
    position: 'relative',
    height: 464
  },
  videoContainer: { marginTop: 48 },
  newLeads: {
    height: 255,
    width: 255,
    backgroundColor: Color.blue10,
    borderRadius: '100%',
    marginTop: 48
  },
  totalLeads: {
    height: 255,
    width: 255,
    borderRadius: '100%',
    marginTop: 48
  },
  centralize: {
    margin: 'auto',
    textAlign: 'center'
  },
  redShape: {
    position: 'absolute',
    left: 0,
    bottom: 0
  },
  dashboardButtonLink: {
    display: 'contents'
  },
  dashboardButton: {
    margin: 'auto',
    display: 'flex',
    width: 200,
    height: 56,
    marginTop: 24
  },
  blueShape: { position: 'absolute', right: 0, bottom: 0 }
}))
export default useStyles
