import React, { useState } from 'react'
import { Color, Paper } from 'components'
import { CardContent, Typography, Grid, TypographyProps } from '@mui/material'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material'
import { WorkRecord } from 'model/profile'
import ExperienceDetail from './ExperienceDetail'
import { useStyles } from '../../style'
import { styled } from '@mui/material/styles'

interface Props {
  workRecords: WorkRecord[]
}

const ShowMoreButton = styled(Typography)<TypographyProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: '500',
  '& .MuiSvgIcon-root': {
    fontSize: 16,
    marginLeft: '8px'
  }
}))
const Experience = ({ workRecords }: Props): React.ReactElement => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  return (
    <Paper className={classes.paper}>
      <CardContent>
        <Typography
          variant='h4'
          color={Color.black87}
          paddingBottom={3}
          fontWeight='500'
        >
          Experience
        </Typography>
        {workRecords && (
          <ExperienceDetail workRecords={workRecords.slice(0, 3)} main={true} />
        )}
        {workRecords?.length > 3 && (
          <Grid>
            {expanded && (
              <ExperienceDetail workRecords={workRecords.slice(3)} />
            )}
            <Grid container alignItems='center'>
              <ShowMoreButton
                variant='button'
                color='primary'
                onClick={() => setExpanded(!expanded)}
              >
                {`Show ${workRecords.length - 3} ${expanded ? 'less' : 'more'}`}
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ShowMoreButton>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Paper>
  )
}

export default Experience
