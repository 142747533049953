import React, { createContext } from 'react'

type AccordionControllContextData = {
  expanded: string
  setExpanded: React.Dispatch<any>
  handleChangeExpanded: (
    panel: string
  ) =>
    | ((event: React.SyntheticEvent<Element, Event>, expanded: boolean) => void)
    | undefined
}

export const AccordionControllContext =
  createContext<AccordionControllContextData>({
    expanded: '',
    setExpanded: () => {},
    handleChangeExpanded: () => undefined
  })

export default AccordionControllContext
