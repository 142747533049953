import React, { memo, useState, useEffect } from 'react'
import { Typography, Grid, CircularProgress } from '@mui/material'
import { ErrorRounded as ErrorIcon } from '@mui/icons-material'
import { MailIcon, Color } from 'components'
import WorkExperience from './WorkExperience'

import { TalentEmail } from 'model/profile'
import useStyles from './style'
import Affiliations from './Affiliations'
import { useLazyQuery } from 'gql/hooks'
import { GET_PROFILE_CONTACT_INFO } from 'gql/query'
import { CONTACT_INFO_STATUS_UPDATED_SUBSCRIPTION } from 'gql/subscription'
interface Props {
  row: any
}

const Profile = (props: Props): React.ReactElement => {
  const { row } = props
  const { profile } = row
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const startSubscription = () => {
    subscribeToMore({
      document: CONTACT_INFO_STATUS_UPDATED_SUBSCRIPTION,
      variables: {
        profileId: profile.id
      },
      updateQuery: (_prev: any, { subscriptionData }: any) => {
        if (subscriptionData.data) {
          setLoading(false)
          return {
            profileContactInfo: {
              ...subscriptionData.data.contactInfoEnrichmentStatusUpdated
            }
          }
        }
      }
    })
  }
  const [
    getContactInfo,
    { data = { profileContactInfo: {} }, subscribeToMore }
  ] = useLazyQuery(GET_PROFILE_CONTACT_INFO, {
    isSubscriptionEphemeral: false,
    variables: {
      profileId: profile.id
    },
    onError: (data: any) => {
      if (
        data.message ===
        'Can not enrich a profile that is currently being enriched from the same provider'
      ) {
        if (!loading) {
          setLoading(true)
        }
        startSubscription()
      }
    }
  })

  const classes = useStyles()

  const { profileContactInfo } = data
  const { __typename: typeName, status } = profileContactInfo
  useEffect(() => {
    if (profileContactInfo?.status === 'PENDING') {
      setLoading(true)
      startSubscription()
    }
    if (status === 'FAILED' || typeName === 'ContactInfoBlock') {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, typeName])

  return (
    <Grid container className={classes.contentContainer}>
      {profile.workHistoryBlock?.current && (
        <Grid item container marginBottom={'16px'}>
          <Grid item xs={2}>
            <Typography variant='body2' color={Color.defaultText}>
              Current
            </Typography>
          </Grid>
          <Grid item xs>
            <WorkExperience workRecords={profile.workHistoryBlock.current} />
          </Grid>
        </Grid>
      )}
      {profile.workHistoryBlock?.workRecords && (
        <Grid item container marginBottom={'16px'}>
          <Grid item xs={2}>
            <Typography variant='body2' color={Color.defaultText}>
              Past
            </Typography>
          </Grid>
          <Grid item xs>
            <WorkExperience
              workRecords={profile.workHistoryBlock.workRecords.filter(
                (workRecord: any) => workRecord.endDate !== null
              )}
            />
          </Grid>
        </Grid>
      )}
      {profile.affiliationsBlock?.affiliations[0] && (
        <Grid item container marginBottom={'16px'}>
          <Grid item xs={2}>
            <Typography variant='body2' color={Color.defaultText}>
              Affiliations
            </Typography>
          </Grid>
          <Grid item xs>
            <Affiliations
              affiliations={profile.affiliationsBlock.affiliations}
            />
          </Grid>
        </Grid>
      )}
      <Grid item container marginBottom={'16px'}>
        <Grid item xs={2}>
          <Typography variant='body2' color={Color.defaultText}>
            <MailIcon size={14} className={classes.mailIcon} /> Contact
          </Typography>
        </Grid>
        <Grid item xs>
          {!loading && (
            <Typography
              variant='button'
              paddingRight='8px'
              color='primary'
              component='div'
              marginBottom='8px'
              sx={{ cursor: 'pointer', fontWeight: 500 }}
              onClick={() => {
                if (!expanded) {
                  getContactInfo()
                }
                setExpanded(!expanded)
              }}
            >
              {expanded ? 'Hide' : 'Show'} Contact
            </Typography>
          )}
          {expanded && (
            <>
              {typeName === 'ContactInfoBlock' &&
                profileContactInfo?.emails.map(
                  (email: TalentEmail, index: number) => (
                    <Typography
                      gutterBottom
                      key={`email_${index}`}
                      variant='body2'
                      color={Color.black80}
                    >
                      {email.address}
                    </Typography>
                  )
                )}
              {typeName === 'ContactInfoEnrichmentStatus' &&
                status === 'FAILED' && (
                  <Grid container>
                    <ErrorIcon className={classes.errorIcon} />
                    <Typography variant='body2' color={Color.black}>
                      Sorry, we can’t find contact information for this lead.
                    </Typography>
                  </Grid>
                )}
              {loading && (
                <CircularProgress
                  size={20}
                  className={classes.circularProgress}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(Profile)
