import { makeStyles } from '@mui/styles'
import { Color } from 'components'
const useStyles = makeStyles({
  dialogButtons: {
    height: 44,
    width: 112,
    '&:not(:first-of-type)': { marginLeft: 32, width: 98 }
  },
  archiveBox: { marginLeft: '0', marginRight: 'auto' },
  clickable: {
    cursor: 'pointer'
  },
  circularProgress: {
    position: 'relative',
    padding: 15
  },
  errorIcon: {
    color: Color.grey700,
    fontSize: 18,
    marginRight: 10,
    alignSelf: 'center'
  },
  mailIcon: {
    width: '14px !important',
    height: '12px !important'
  },
  textarea: {
    '& textarea': { padding: 0 },
    '& .placeholder': { color: Color.defaultText },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue'
    }
  },
  infobar: { marginTop: 16, width: '100%' },
  contentContainer: {
    paddingTop: 0
  },
  avatarContainer: {
    minWidth: 120
  },
  maskedAvatar: {
    backgroundColor: '#D7DCDF',
    width: 96,
    height: 96,
    borderRadius: 96
  }
})
export default useStyles
