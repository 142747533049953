import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const RightArrowIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g className='mathison-icon' stroke='#000' fill='none' fillRule='evenodd'>
      <path d='M5.452 11.887h11.89M13.38 7.626l3.962 4.36-3.963 4.36' />
      <g transform='translate(1 1)'>
        <circle cx='11' cy='11' r='11' />
      </g>
    </g>
  </SvgIcon>
)

export default RightArrowIcon
