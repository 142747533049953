import React from 'react'
import { generateLink } from 'utils/stringHelper'
import { Color } from 'components'
import { ChevronRightRounded as ChevronRightIcon } from '@mui/icons-material'
import { useStyles, Tabs, Tab } from './style'
import { Typography, Grid, Button } from '@mui/material'
import { PipelineTabs } from '../constants'
import { PipelineStage } from 'model/jobPipeline'
import { useParams } from 'react-router-dom'

interface Props {
  countData: any
  selectedTab: PipelineStage | string
  setSelectedTab: (selectedTab: PipelineStage) => void
  batchNotStarted?: boolean
}
const StageTab = ({
  countData,
  selectedTab,
  setSelectedTab
}: Props): React.ReactElement => {
  const classes = useStyles()
  const params = useParams()
  const { nanoid } = params
  const counts = countData?.numCandidatesByStage
    ? [
        countData.numCandidatesByStage.toReview,
        countData.numCandidatesByStage.toContact,
        countData.numCandidatesByStage.contacted
      ]
    : [0, 0, 0]

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    newValue: PipelineStage
  ) => {
    setSelectedTab(newValue)
  }

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      paddingTop='57px'
    >
      <Grid item>
        {selectedTab === PipelineStage.TO_REVIEW && (
          <Button
            disabled={counts[0] === 0}
            onClick={() => {
              window.open(generateLink(`/jobs/${nanoid}/review-queue`))
            }}
            className={classes.button}
            endIcon={<ChevronRightIcon color='inherit' />}
          >
            Open in queue
          </Button>
        )}
      </Grid>
      <Tabs value={selectedTab} onChange={handleChange}>
        {PipelineTabs.map((tab: any, index: number) => (
          <Tab
            key={`tab-${index}`}
            disableRipple
            value={tab.value}
            label={
              <Typography
                variant='body2'
                color={Color.black}
                sx={{ fontWeight: selectedTab === tab.value ? 700 : 'normal' }}
              >
                {tab.value === PipelineStage.ARCHIVED
                  ? `${tab.tag}`
                  : `${tab.tag} (${counts[index]})`}
              </Typography>
            }
          />
        ))}
      </Tabs>
    </Grid>
  )
}

export default StageTab
