import React from 'react'
import { CircularProgress as MuiCircularProgress } from '@mui/material'

import { makeStyles } from '@mui/styles'
const useStyles = makeStyles(() => ({
  circularProgress: { position: 'relative' },
  circular: { position: 'absolute', zIndex: 2, left: 0 },
  circularBackground: { position: 'absolute', zIndex: 1, left: 4, top: 4 }
}))
interface Props {
  color: string
  value: number
}
const CircularProgress = ({ color, value }: Props): React.ReactElement => {
  const classes = useStyles()
  return (
    <div className={classes.circularProgress}>
      <MuiCircularProgress
        variant='determinate'
        className={classes.circular}
        value={value}
        size={255}
        style={{ color: color }}
      />
      <MuiCircularProgress
        className={classes.circularBackground}
        variant='determinate'
        value={100}
        size={245}
        color='secondary'
        thickness={1.5}
      />
    </div>
  )
}

export default CircularProgress
