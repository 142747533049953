import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import { Chip } from './style'
import { RequestBatchContext } from 'context/RequestBatch'

interface Props {
  data: any[]
  tag: string
  target: string
}
const RequiredInput = ({ data, target, tag }: Props): React.ReactElement => {
  const { setRequestBatchInput } = useContext(RequestBatchContext)
  const handleDeleteChip = (target: string, index: number, prev: any[]) => {
    const newInput = [...prev]
    newInput.splice(index, 1)
    setRequestBatchInput({ index: target, value: newInput })
  }
  return (
    <Grid container marginBottom={2} justifyContent='flex-start' spacing={2}>
      {data.map(
        (item: any, index: number) =>
          item !== '' && (
            <Grid key={`${tag}-${index}`} item>
              <Chip
                label={item.city || item}
                onDelete={() => handleDeleteChip(target, index, data)}
              />
            </Grid>
          )
      )}
    </Grid>
  )
}

export default RequiredInput
