import React, { useEffect, useState, useContext } from 'react'
import { Typography, Grid, Divider, IconButton } from '@mui/material'
import useStyles from './style'
import { Color, Paper, FileUploader, CameraIcon, InfoBanner } from 'components'
import Dialog from './Dialog'
import AtsIntegration from './AtsIntegration'
import { useLazyQuery, useMutation } from 'gql/hooks'
import { GET_PRESIGNED_EMPLOYER_IMAGE_URL } from 'gql/query'
import { EMPLOYER_UPDATED } from 'gql/subscription'
import { UPDATE_EMPLOYER_LOGO } from 'gql/command'
import { IMG_LOGO_PARAMS, LOGIN_STRATEGY, PresignedFile } from './constants'
import { MicroServiceContext } from 'context/MicroService'
import { AtsContext } from 'context/AtsContext'

const Overview = ({ myEmployer: employer, refetch, subscribeToMore }: any) => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState(false)
  const [uploading, setUploading] = useState(false)

  const { isAtsAvailable } = useContext(AtsContext)

  const { currentAccount, displayNotification, openContactDialog } =
    useContext(MicroServiceContext)
  const [getEmployerUrl, { data: memberUrl }] = useLazyQuery(
    GET_PRESIGNED_EMPLOYER_IMAGE_URL,
    {
      onError: () => {
        displayNotification('Invalid file name.')
        setUploading(false)
      }
    }
  )
  const [updateEmployerLogo] = useMutation(
    UPDATE_EMPLOYER_LOGO,
    undefined,
    'Employer logo updated.'
  )

  const { user } = currentAccount
  const [presignedAvatar, setPresignedAvatar] = useState<PresignedFile>()
  const handleTempAvatarURL = (file: PresignedFile) => {
    setUploading(true)
    if (presignedAvatar?.file.name === file.file.name) {
      setUploading(false)
      displayNotification('Can not update employer logo to be the same.')
      return
    }
    setPresignedAvatar(file)
    getEmployerUrl({
      variables: { imageFileName: file.file.name, employerId: employer.id }
    })
  }

  useEffect(() => {
    if (memberUrl && memberUrl.presignedEmployerImageUrl.url) {
      const { presignedEmployerImageUrl } = memberUrl
      const upload = async () => {
        await fetch(presignedEmployerImageUrl.url, {
          method: 'PUT',
          body: presignedAvatar?.file
        })
        subscribeToMore({
          document: EMPLOYER_UPDATED,
          variables: {
            id: employer.id,
            type: 'LOGO',
            imgixParams: IMG_LOGO_PARAMS
          },
          updateQuery: (prev: any, { subscriptionData }: any) => {
            return Object.assign({}, prev, {
              myEmployer: {
                ...prev.myEmployer,

                logo: subscriptionData.data.employerUpdated.logo
              }
            })
          }
        })
        updateEmployerLogo({
          variables: {
            input: {
              employerId: employer.id,
              logoS3Key: presignedEmployerImageUrl.s3Key
            }
          }
        })
        setUploading(false)
      }
      upload()
    }
    // eslint-disable-next-line
  }, [memberUrl])

  const { authenticationSettings, authenticationStatus } = employer

  return (
    <Paper className={classes.overviewContainer}>
      <Typography
        variant='h3'
        color={Color.black}
        className={classes.profileTitle}
      >
        Profile
      </Typography>
      <Grid container className={classes.profile}>
        <Grid item container xs={12}>
          <Grid item xs={2} className={classes.fileUploader}>
            <FileUploader
              fileType='Image'
              presignedFileChange={handleTempAvatarURL}
              uploading={uploading}
            >
              <div className={classes.cameraIcon}>
                <IconButton className={classes.cameraIconButton} size='large'>
                  <CameraIcon />
                </IconButton>
              </div>
              <div className={classes.avatarPlaceholder}>
                {employer.logo && (
                  <img
                    src={`https://${employer.logo}`}
                    width={88}
                    height={88}
                  />
                )}
              </div>
            </FileUploader>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body2' color={Color.black60}>
              Name
            </Typography>
            <Typography variant='body1Medium' color={Color.black}>
              {employer.name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2' color={Color.black60}>
              Main contact
            </Typography>
            <Typography
              variant='body1Medium'
              color={Color.black}
              paragraph
              className={classes.name}
            >
              {employer.mainContact?.name}
            </Typography>
            <Typography variant='body1' color={Color.black80}>
              {employer.mainContact?.email}
            </Typography>
            <Typography variant='body1High' color={Color.black80}>
              {employer.mainContact?.phone}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.infoBar}>
        <InfoBanner
          type='info'
          content={
            <>
              Contact your{' '}
              <Typography
                variant='link'
                fontSize={14}
                color={Color.grey700}
                onClick={openContactDialog}
                className={classes.pointer}
              >
                Impact Manager
              </Typography>{' '}
              to edit your organization name or main contact.
            </>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider flexItem sx={{ marginTop: '16px', marginBottom: '24px' }} />
      </Grid>
      {isAtsAvailable && (
        <>
          <AtsIntegration employerName={employer?.name} />
          <Grid item xs={12}>
            <Divider flexItem sx={{ marginBottom: '24px' }} />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant='h3' color={Color.black}>
          Log in settings
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.loginSetting}>
          <Typography
            variant='body1Medium'
            color={Color.black80}
            className={classes.loginSettingTitle}
            paragraph
          >
            {authenticationSettings.strategy &&
              (LOGIN_STRATEGY as any)[authenticationSettings.strategy].title}
          </Typography>
          <Typography variant='body2' color={Color.black60}>
            {authenticationSettings.strategy &&
              (LOGIN_STRATEGY as any)[authenticationSettings.strategy].subtitle}
          </Typography>
          <Typography
            variant='body2Medium'
            color={Color.main}
            paragraph
            className={classes.clickableText}
            margin='12px auto 0px 0px'
            onClick={() => {
              if (employer.authenticationStatus !== 'FAILED') {
                setOpenDialog(true)
              } else {
                displayNotification('Please contact your impact manager!')
              }
            }}
          >
            Edit
          </Typography>
          <Dialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            authenticationSettings={authenticationSettings}
            user={user}
            subscribeToMore={subscribeToMore}
            refetch={refetch}
            authenticationStatus={authenticationStatus!}
          ></Dialog>
        </div>
      </Grid>
    </Paper>
  )
}
export default Overview
