import React from 'react'
import ReactDOM from 'react-dom'
import {
  StyledEngineProvider,
  ThemeProvider as CoreThemeProvider
} from '@mui/material/styles'
import { theme } from 'components'

import './index.css'
import * as Sentry from './utils/sentry'
import App from './App'
import { StylesProvider, createGenerateClassName } from '@mui/styles'
import { MicroServiceProvider } from 'context/MicroService'
import { ApolloProvider } from '@apollo/client'
import { AtsProvider } from 'context/AtsContext'

import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
const myCache = createCache({
  speedy: false,
  key: 'css',
  prepend: true
})
Sentry.init()
const generateClassName = createGenerateClassName({
  productionPrefix: 'app-portal',
  disableGlobal: true
})

const render = (props: any) => {
  const {
    container,
    currentAccount,
    isImpersonator,
    cmsApiKey,
    apolloClient,
    setHideHeader,
    displayNotification,
    openContactDialog,
    maestroClient
  } = props

  ReactDOM.render(
    <MicroServiceProvider
      currentAccount={currentAccount}
      isImpersonator={isImpersonator}
      cmsApiKey={cmsApiKey}
      setHideHeader={setHideHeader}
      displayNotification={displayNotification}
      openContactDialog={openContactDialog}
      maestroClient={maestroClient}
    >
      <StyledEngineProvider injectFirst>
        <ApolloProvider client={apolloClient}>
          <StylesProvider generateClassName={generateClassName}>
            <CacheProvider value={myCache}>
              <CoreThemeProvider theme={theme}>
                <AtsProvider>
                <App />
                </AtsProvider>
              </CoreThemeProvider>
            </CacheProvider>
          </StylesProvider>
        </ApolloProvider>
      </StyledEngineProvider>
    </MicroServiceProvider>,
    container
      ? container.querySelector('#app-root')
      : document.querySelector('#app-root')
  )
}
export const bootstrap = async () => {
  console.log('app portal react app bootstraped')
}

export const mount = async (props: any) => {
  render(props)
}

export const unmount = async (props: any) => {
  const { container } = props

  ReactDOM.unmountComponentAtNode(
    container
      ? container.querySelector('#app-root')
      : document.querySelector('#app-root')
  )
}
export const update = async (props: any) => {
  render(props)
}
if (!(window as any).__POWERED_BY_QIANKUN__) {
  render({})
}
