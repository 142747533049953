import { makeStyles, withStyles } from '@mui/styles'

import { MenuItem as MuiMenuItem } from '@mui/material'
import { Color } from 'components'
export const useStyles = makeStyles(() => {
  return {
    menuItem: { width: 263, height: 50, padding: 0 },
    menuItemContainer: { padding: 8 },
    formLabel: {
      width: '100%',
      marginRight: 0,
      padding: '8px 8px'
    },
    menuHeader: { display: 'flex' },
    clearButton: {
      marginLeft: 0,
      marginRight: 'auto'
    },
    clearText: { textDecoration: 'underline' },
    paper: {
      padding: 0
    },
    hoverAndSelected: {
      backgroundColor: Color.blue5,
      '&:hover': {
        backgroundColor: Color.blue10
      }
    }
  }
})

export const MenuItem = withStyles({
  root: {
    height: 34,
    width: 255,
    padding: '0px 8px',
    borderRadius: 5
  }
})(MuiMenuItem)
