import { LocationInput } from 'model'

export enum PipelineStage {
  TO_REVIEW = 'TO_REVIEW',
  TO_CONTACT = 'TO_CONTACT',
  CONTACTED = 'CONTACTED',
  ARCHIVED = 'ARCHIVED'
}

export enum PipelineTag {
  TO_REVIEW = 'To review',
  TO_CONTACT = 'To contact',
  CONTACTED = 'Contacted',
  ARCHIVED = 'Archived'
}

export const PipelineStageOptions = [
  {
    value: PipelineStage.TO_REVIEW,
    tag: PipelineTag.TO_REVIEW
  },
  {
    value: PipelineStage.TO_CONTACT,
    tag: PipelineTag.TO_CONTACT
  },
  {
    value: PipelineStage.CONTACTED,
    tag: PipelineTag.CONTACTED
  },
  {
    value: PipelineStage.ARCHIVED,
    tag: PipelineTag.ARCHIVED
  }
]
export enum BatchStatus {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export enum StructuredFeedback {
  INTERESTED = 'INTERESTED',
  VERY_INTERESTED = 'VERY_INTERESTED',
  NOT_INTERESTED = 'NOT_INTERESTED'
}

export interface CandidateReviewInput {
  generalFeedback: string
  structuredFeedback: StructuredFeedback | undefined
}

export interface JobBatch {
  batchNumber: number
  status: BatchStatus
  deliveredLeads: number
  dueAt: string
  remainingLeads: number
  size: number
}

export interface BatchDeliveryPreference {
  id: string
  name: string
  numberOfLeads: number
  recurringPeriod: null | number
  availableOnTypes: string[]
  businessDaysUntilDue: number
}
export enum JobTypeValue {
  STANDARD = 'STANDARD',
  SENIOR = 'SENIOR'
}

export enum JobTypeTag {
  STANDARD = 'Standard',
  SENIOR = 'Senior and specialized'
}

export interface RequestBatchInput {
  jobType: JobTypeValue | null
  batchSizeName: string
  batchDeliveryPreferenceId: string
  displayTitle: string
  description: string
  exampleProfiles: string[]
  requiredSkills: string[]
  preferredSkills: string[]
  locations: LocationInput[]
  companiesToInclude: string[]
  companiesToExclude: string[]
  commentsAndFeedback: string
}
