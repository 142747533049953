import React from 'react'
import Stack, { StackProps } from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { BookmarkBorderSharp } from '@mui/icons-material'
import Box, { BoxProps } from '@mui/material/Box'
import ChapterChip from './ChapterChip'

interface IProps {
  bookmark?: () => void
  chapterNumber: number
}

const StyledStack = styled(Stack)<StackProps>(() => ({
  paddingBottom: '20px'
}))

const ChipWrapper = styled(Box)<BoxProps>(() => ({
  marginTop: '3.5px'
}))

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => ({
  color: 'black',
  '& svg': {
    fontSize: '1.6rem'
  },
  marginLeft: 'auto'
}))

export const ChapterCardEyebrow = (props: IProps) => {
  const { chapterNumber } = props

  return (
    <StyledStack direction='row'>
      <ChipWrapper>
        <ChapterChip chapterNumber={chapterNumber} />
      </ChipWrapper>
      <StyledIconButton aria-label='bookmark'>
        <BookmarkBorderSharp />
      </StyledIconButton>
    </StyledStack>
  )
}

export default ChapterCardEyebrow
