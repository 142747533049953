import React, { useState } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { Dialog } from 'components'
import { useMutation } from 'gql/hooks'
import { RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE } from 'gql/command'

interface Props {
  selectedStaffAccountId: string
  anchorEl: any
  setAnchorEl: (_e: any) => void
}

const StaffMenu = ({
  selectedStaffAccountId,
  anchorEl,
  setAnchorEl
}: Props) => {
  const [open, setOpen] = useState(false)
  const [resendInvite] = useMutation(RESEND_EMPLOYER_STAFF_ACCOUNT_INVITE, {
    successNotification: 'Invite resent'
  })

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        autoFocus
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(undefined)
        }}
        PaperProps={{
          sx: { width: 296 }
        }}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(undefined)
            setOpen(true)
          }}
        >
          Resend invite
        </MenuItem>
      </Menu>
      <Dialog
        open={open}
        setOpen={setOpen}
        title={'Resend invite?'}
        confirm={{
          text: 'Send',
          handleAction: () => {
            resendInvite({
              variables: {
                input: { employerStaffAccountId: selectedStaffAccountId }
              }
            })
          }
        }}
        banner={{
          content:
            "New staff are invited as Basic Users and don't count against your seats. You can edit roles once they've created an account.",
          type: 'info',
          multiline: true
        }}
        content={'It may take a few hours for invitations to arrive.'}
        onClose={() => setOpen(false)}
      />
    </>
  )
}

export default StaffMenu
