import React, { createContext, useContext, useEffect, useState } from 'react'
import { useQuery, useLazyQuery, useMutation } from 'gql/hooks'
import { MicroServiceContext } from './MicroService'
import { IS_ATS_AVAILABLE, LINKED_ACCOUNT } from 'gql/query'
import { DELETE_LINKED_ACCOUNT } from 'gql/command'

export type AtsContextData = {
  isAtsAvailable: boolean | undefined
  isAtsSetup: boolean | undefined
  linkedAccount: any
  loading: boolean
  deleteLinkedAccount: () => void
}

const INITIAL_DATA: AtsContextData = {
  isAtsAvailable: undefined,
  isAtsSetup: undefined,
  linkedAccount: undefined,
  loading: true,
  deleteLinkedAccount: () => {}
}

const DEFAULT_DATA: AtsContextData = {
  isAtsAvailable: false,
  isAtsSetup: false,
  linkedAccount: undefined,
  loading: false,
  deleteLinkedAccount: () => {}
}

export const AtsContext = createContext<AtsContextData>(INITIAL_DATA)

export const AtsProvider = ({ children }: any): JSX.Element => {
  const [data, setData] = useState<any>(INITIAL_DATA)
  const { maestroClient } = useContext(MicroServiceContext)
  const [getLinkedAccount, linkedAccountResponse] = useLazyQuery(
    LINKED_ACCOUNT,
    {
      client: maestroClient
    }
  )
  const { data: isAtsAvailableData, error: isAtsAvailableError } = useQuery(
    IS_ATS_AVAILABLE,
    {
      client: maestroClient
    }
  )
  const {
    data: linkedAccountData,
    error: linkedAccountError,
    loading
  } = linkedAccountResponse

  const [onDeleteLinkedAccount] = useMutation(DELETE_LINKED_ACCOUNT, {
    client: maestroClient,
    successNotification: 'Your ATS platform has successfully been disconnected.'
  })

  const deleteLinkedAccount = async () => {
    await onDeleteLinkedAccount()
    getLinkedAccount()
  }

  useEffect(() => {
    if (isAtsAvailableData === undefined) return

    if (isAtsAvailableData.isAtsAvailable) {
      setData({
        isAtsAvailable: true,
        isAtsSetup: undefined,
        linkedAccount: undefined
      })
      getLinkedAccount()
    } else {
      setData(DEFAULT_DATA)
    }
  }, [isAtsAvailableData, getLinkedAccount])

  useEffect(() => {
    if (linkedAccountData || linkedAccountError) {
      setData({
        isAtsAvailable: true,
        isAtsSetup: !!linkedAccountData.linkedAccount,
        linkedAccount: linkedAccountData.linkedAccount
      })
    }
  }, [linkedAccountData, linkedAccountError])

  useEffect(
    () => isAtsAvailableError && setData(DEFAULT_DATA),
    [isAtsAvailableError]
  )

  return (
    <AtsContext.Provider
      value={{
        ...data,
        loading: loading,
        deleteLinkedAccount: deleteLinkedAccount
      }}
    >
      {children}
    </AtsContext.Provider>
  )
}
