import React, { useState, useContext } from 'react'
import { format } from 'date-fns'
import { useStyles, TableCell } from './style'
import { openInNewTab } from 'utils/openInNewTab'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { TableRow, Typography, IconButton, Grid } from '@mui/material'
import { Color, UserAvatar, MathisonIcon, LinkedInVector } from 'components'
import CandidateActions from '../CandidateActions'
import { MyJobContext } from 'context/JobContext'
import { Profile } from 'model/profile'

interface Props {
  data: any
  setOpenConfirmDialog: (bool: boolean) => void
  setSelectedProfile: (profile: Profile) => void
}

const TableRowBuilder = ({
  data,
  setOpenConfirmDialog,
  setSelectedProfile
}: Props) => {
  const classes = useStyles()
  const profile = data?.profile
  const [anchorEl, setAnchorEl] = useState()
  const { maskingFlag } = useContext(MyJobContext)
  const openCandidateDialog = () => {
    setSelectedProfile(data)
    setOpenConfirmDialog(true)
  }
  if (!profile) return <></>
  return (
    <>
      <CandidateActions
        selectedRow={data}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
      <TableRow key={data.id} onClick={() => openCandidateDialog()}>
        <TableCell>
          <div className={classes.basicInfoContainer}>
            <div className={classes.clickable}>
              {maskingFlag ? (
                <div className={classes.maskedAvatar} />
              ) : (
                <UserAvatar
                  username={profile.basicInfoBlock.name}
                  avatarURL={
                    profile.avatarBlock?.url
                      ? `https://${profile.avatarBlock.url}`
                      : ''
                  }
                />
              )}
            </div>
            <div className={classes.basicInfo}>
              <Typography
                variant='body1Medium'
                color={maskingFlag ? 'transparent' : Color.main}
                sx={{
                  '&:hover': {
                    cursor: 'pointer'
                  },
                  textShadow: maskingFlag ? '0 0 20px rgba(0,0,0,0.5)' : 'none'
                }}
              >
                {profile.basicInfoBlock.name}
              </Typography>

              <Typography
                variant='body2'
                color={Color.black80}
                className='jobTitle'
              >
                {profile.workHistoryBlock.current.length > 0 &&
                  profile.workHistoryBlock.current[0].job}
              </Typography>
            </div>
          </div>
        </TableCell>
        <TableCell
          align='left'
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <Grid container>
            <div
              onClick={() => {
                openCandidateDialog()
              }}
            >
              <MathisonIcon className={classes.viewButton} />
            </div>
            <div
              className={classes.viewButton}
              onClick={() =>
                profile.socialMediaBlock?.primaryLinkedinUrl &&
                openInNewTab(profile.socialMediaBlock?.primaryLinkedinUrl)
              }
            >
              <LinkedInVector filled size={18} />
            </div>
          </Grid>
        </TableCell>
        <TableCell align='left'>
          <Typography variant='body2'>
            {data?.jobBatchNumber ? data.jobBatchNumber : '-'}
          </Typography>
        </TableCell>
        <TableCell align='left'>
          <Typography variant='body2' noWrap>
            {format(new Date(data.insertedAt), 'MMM dd, yyyy')}
          </Typography>
        </TableCell>
        <TableCell
          align='right'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <IconButton
            className={classes.expendButton}
            onClick={(e: any) => {
              e.preventDefault()
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          >
            <MoreVertIcon fontSize='inherit' />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

export default TableRowBuilder
