import React, { useState, useReducer, useEffect, useContext } from 'react'
import { TABLE_HEADS, IMG_LOGO_PARAMS } from '../constants'
import { InitiaPaginationState } from 'pages/Jobs/constants'
import StageTab from './StageTab'
import { Table } from 'components'
import { PaginationState } from 'model'
import { JobCandidateContextProvider } from 'context/JobCandidateContext'
import { useQuery } from 'gql/hooks'
import { CandidatesQueryData } from 'model/profile'
import TableRowBuilder from './TableRowBuilder'
import {
  Button,
  ButtonProps,
  Paper,
  PaperProps,
  Typography
} from '@mui/material'
import TableContextProvider from 'context/TableContext'
import { GET_CANDIDATES_FOR_TABLE, GET_CANDIDATE_COUNT } from 'gql/query'
import { MyJobContext } from 'context/JobContext'
import { JobStatus } from 'model'
import { FullScreenDialogContext } from 'context/FullScreenDialog'
import { PipelineStage } from 'model/jobPipeline'
import Dialogs from './RowDialog'
import { EmployerStaffAccountJobsAccessLevel } from 'model/organization'
import { NetworkStatus } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import { styled } from '@mui/material/styles'

const StyledPaper = styled(Paper)<PaperProps>(() => ({
  boxShadow:
    '0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
  border: '1px solid #E0E7EB',
  borderRadius: '0 0 10px 10px',
  width: '100%'
}))

const StyledButton = styled(Button)<ButtonProps>(() => ({
  marginTop: '-16px',
  padding: '8px 24px',
  borderRadius: '6px'
}))

const CandidateTable = (): React.ReactElement => {
  const {
    job,
    numRemainingCreditsForMonth,
    setOpenNoBatchLeft,
    isBatchAlreadyRequested
  } = useContext(MyJobContext)
  const { setOpenBatchRequest } = useContext(FullScreenDialogContext)
  const { currentAccount, displayNotification } =
    useContext(MicroServiceContext)
  const isJobsLead =
    currentAccount?.jobsAccess === EmployerStaffAccountJobsAccessLevel.JOBS_LEAD
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [selectedTab, setSelectedTab] = useState<PipelineStage | string>(
    PipelineStage.TO_REVIEW
  )

  const [selectedProfile, setSelectedProfile] = useState<any>()
  const [paginationState, setPaginationState] = useReducer(
    (state: PaginationState, action: any) => {
      switch (action.type) {
        case 'rowsPerPageChange':
          return {
            currentPage: 0,
            rowsPerPage: action.value,
            cursor: undefined
          }
        case 'back':
          return { ...state, currentPage: state.currentPage - 1 }
        case 'reset':
          return { ...state, currentPage: 0 }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    InitiaPaginationState
  )
  const { data: countData, refetch: refetchCount } = useQuery(
    GET_CANDIDATE_COUNT,
    {
      variables: {
        jobId: job!.id
      },
      fetchPolicy: 'network-only'
    }
  )
  const { loading, data, refetch, fetchMore, subscribeToMore, networkStatus } =
    useQuery(GET_CANDIDATES_FOR_TABLE, {
      variables: {
        first: paginationState.rowsPerPage,
        jobId: job!.id,
        stage: PipelineStage.TO_REVIEW,
        after: paginationState.cursor,
        imgixParams: IMG_LOGO_PARAMS
      } as CandidatesQueryData,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    })
  const candidates = data ? data.candidates?.edges : undefined
  const nextPage = (num: number, callback?: (data: any) => void) => {
    const { endCursor } = data.candidates.pageInfo
    if (fetchMore && num * paginationState.rowsPerPage >= candidates.length)
      fetchMore({
        variables: {
          after: endCursor
        }
      }).then((data: any) => {
        if (callback) callback(data.data)
      })
  }

  useEffect(() => {
    if (refetch && selectedTab) {
      refetch({
        stage: selectedTab
      })?.then(() => {
        setPaginationState({ type: 'reset' })
      })
    }
    // eslint-disable-next-line
  }, [selectedTab])

  const selectedCandidateIndex = candidates?.findIndex(
    (candidate: any) => candidate.node.id === selectedProfile?.id
  )
  const previous = () => {
    setSelectedProfile(candidates[selectedCandidateIndex - 1].node)
  }
  const next = () => {
    const isTheLastCandidate =
      selectedCandidateIndex === data.candidates.count - 1
    if (isTheLastCandidate) {
      setOpenConfirmDialog(false)
    } else {
      if (candidates[selectedCandidateIndex + 1] === undefined) {
        nextPage(paginationState.currentPage + 1, (data: any) =>
          setSelectedProfile(data.candidates.edges[0].node)
        )
      } else {
        setSelectedProfile(candidates[selectedCandidateIndex + 1].node)
      }
    }
  }

  return (
    <JobCandidateContextProvider
      subscribeToMore={subscribeToMore}
      displayNotification={displayNotification}
      refetch={refetch}
      refetchCount={refetchCount}
      paginationState={paginationState}
      setPaginationState={setPaginationState}
      onlyItemInList={candidates?.length % paginationState.rowsPerPage === 1}
    >
      {selectedProfile && (
        <Dialogs
          openConfirmDialog={openConfirmDialog}
          setOpenConfirmDialog={setOpenConfirmDialog}
          row={selectedProfile}
          stage={selectedTab as PipelineStage}
          previous={previous}
          next={next}
          disablePreviousButton={selectedCandidateIndex === 0}
        />
      )}

      <StageTab
        countData={countData}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />

      <TableContextProvider
        emptyTableTitle={'No leads to show'}
        emptyTableText={
          selectedTab === PipelineStage.TO_REVIEW ? (
            <Typography
              variant='body2'
              lineHeight='20px'
              textAlign='center'
              display='block'
              component={'span'}
              sx={{ whiteSpace: 'pre' }}
            >
              {`If you're waiting on remaining leads, check back soon.
To get a new batch of leads for this job you can request leads.`}
            </Typography>
          ) : selectedTab === PipelineStage.TO_CONTACT ? (
            "This is where you'll find leads your team is interested in contacting for this job."
          ) : selectedTab === PipelineStage.CONTACTED ? (
            'This is where you’ll find leads that have been contacted.'
          ) : (
            "This is where you'll find leads that your team has said they aren't interested in talking to for this job."
          )
        }
        emptyTableButton={
          isJobsLead && selectedTab === PipelineStage.TO_REVIEW ? (
            <StyledButton
              variant='contained'
              disableElevation
              disabled={
                isBatchAlreadyRequested || job?.status === JobStatus.CLOSED
                  ? true
                  : false
              }
              sx={
                isBatchAlreadyRequested || job?.status === JobStatus.CLOSED
                  ? {
                      '&.Mui-disabled': {
                        backgroundColor: '#99c9e2',
                        color: '#ffffff'
                      }
                    }
                  : null
              }
              onClick={() => {
                numRemainingCreditsForMonth < 1
                  ? setOpenNoBatchLeft(true)
                  : setOpenBatchRequest!(true)
              }}
            >
              Request Leads
            </StyledButton>
          ) : null
        }
        tableHeads={TABLE_HEADS}
        Tab
        TableRowBuilder={(props: any) => {
          return (
            <TableRowBuilder
              {...props}
              setOpenConfirmDialog={setOpenConfirmDialog}
              setSelectedProfile={setSelectedProfile}
            />
          )
        }}
      >
        <StyledPaper>
          <Table
            loading={loading || networkStatus === NetworkStatus.fetchMore}
            data={candidates?.slice(
              paginationState.currentPage * paginationState.rowsPerPage,
              paginationState.currentPage * paginationState.rowsPerPage +
                paginationState.rowsPerPage
            )}
            count={data ? data.candidates?.count : 0}
            paginationState={paginationState}
            setPaginationState={setPaginationState}
            fetchMore={nextPage}
            customizedWrapper
          />
        </StyledPaper>
      </TableContextProvider>
    </JobCandidateContextProvider>
  )
}

export default CandidateTable
