import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'

const App = (): React.ReactElement => (
  <Router>
    <Routes />
  </Router>
)

export default App
