import React, { memo, useContext, useState } from 'react'
import { Color, FormField } from 'components'
import { Typography, Grid } from '@mui/material'
import { RequestBatchContext } from 'context/RequestBatch'
import { JOB_REQUIREMENT_STEPS } from '../constant'
import { useStyles, StyledTextField, AddButton } from './style'
import ExpansionPanel from './ExpansionPanel'
import RenderChips from './RenderChips'

const RequiredInput = (): React.ReactElement => {
  const classes = useStyles()
  const { requestBatchInput, setRequestBatchInput, errors } =
    useContext(RequestBatchContext)
  const [requiredSkill, setRequiredSkill] = useState('')
  const [preferredSkill, setPreferredSkill] = useState('')
  const [companyToInclude, setCompanyToInclude] = useState('')
  const [companyToExclude, setCompanyToExclude] = useState('')

  const handleAddChip = (target: string, prev: any[], newValue: string) => {
    if (newValue === '') return
    const newInput = [...prev]
    newInput.push(newValue)
    setRequestBatchInput({
      index: target,
      value: newInput
    })
  }

  return (
    <Grid>
      <Typography
        variant='subheading'
        color={Color.black87}
        display='block'
        marginTop={2}
        marginBottom={1}
      >
        OPTIONAL
      </Typography>
      <Typography variant='h3'>Customize your search</Typography>
      <ExpansionPanel
        title={JOB_REQUIREMENT_STEPS[4]}
        RenderPreview={() => (
          <React.Fragment>
            <Typography variant='body2Medium' marginLeft={'18px'}>
              Required Skills
            </Typography>
            {requestBatchInput.requiredSkills.map(
              (skill: string, index: number) => (
                <Typography
                  variant='body2'
                  key={`required_skill-${index}`}
                  className={classes.displayText}
                >
                  {skill}
                </Typography>
              )
            )}
            <Typography
              variant='body2Medium'
              className={classes.previewHeading}
            >
              Preferred Skills
            </Typography>
            {requestBatchInput.preferredSkills.map(
              (skill: string, index: number) => (
                <Typography
                  variant='body2'
                  key={`preferred_skill-${index}`}
                  className={classes.displayText}
                >
                  {skill}
                </Typography>
              )
            )}
          </React.Fragment>
        )}
        description='Tell us what skills you’re looking for.'
        disableNextStep={errors.requiredSkills && errors.preferredSkills}
      >
        <FormField
          fullWidth
          value={requiredSkill}
          className={classes.rightPadding}
          label={'Required skills'}
          placeholder={'Eg. C++, JavaScript, project management'}
          onChange={(e: any) => setRequiredSkill(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleAddChip(
                'requiredSkills',
                requestBatchInput.requiredSkills,
                requiredSkill
              )
              setRequiredSkill('')
            }
          }}
          InputProps={{
            endAdornment: (
              <AddButton
                onClick={() => {
                  handleAddChip(
                    'requiredSkills',
                    requestBatchInput.requiredSkills,
                    requiredSkill
                  )
                  setRequiredSkill('')
                }}
              />
            )
          }}
        />
        <RenderChips
          data={requestBatchInput.requiredSkills}
          target={'requiredSkills'}
          tag='required_skill'
        />
        <FormField
          fullWidth
          value={preferredSkill}
          className={classes.rightPadding}
          label={'Preferred skills'}
          placeholder={'Eg. C++, JavaScript, project management'}
          onChange={(e: any) => setPreferredSkill(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleAddChip(
                'preferredSkills',
                requestBatchInput.preferredSkills,
                preferredSkill
              )
              setPreferredSkill('')
            }
          }}
          InputProps={{
            endAdornment: (
              <AddButton
                onClick={() => {
                  handleAddChip(
                    'preferredSkills',
                    requestBatchInput.preferredSkills,
                    preferredSkill
                  )
                  setPreferredSkill('')
                }}
              />
            )
          }}
        />
        <RenderChips
          data={requestBatchInput.preferredSkills}
          target={'preferredSkills'}
          tag='preferred_skill'
        />
      </ExpansionPanel>
      <ExpansionPanel
        title={JOB_REQUIREMENT_STEPS[5]}
        RenderPreview={() => (
          <React.Fragment>
            <Typography variant='body2Medium' marginLeft={'18px'}>
              Companies To Include
            </Typography>
            {requestBatchInput.companiesToInclude.map(
              (company: string, index: number) => (
                <Typography
                  variant='body2'
                  key={`company_include-${index}`}
                  className={classes.displayText}
                >
                  {company}
                </Typography>
              )
            )}
            <Typography
              variant='body2Medium'
              className={classes.previewHeading}
            >
              Companies To Exclude
            </Typography>
            {requestBatchInput.companiesToExclude.map(
              (company: string, index: number) => (
                <Typography
                  variant='body2'
                  key={`company_exclude-${index}`}
                  className={classes.displayText}
                >
                  {company}
                </Typography>
              )
            )}
          </React.Fragment>
        )}
        description='Companies we should include and/or exclude.'
        disableNextStep={errors.companiesToInclude && errors.companiesToExclude}
      >
        <FormField
          fullWidth
          value={companyToInclude}
          className={classes.rightPadding}
          label={'Companies to include'}
          onChange={(e: any) => setCompanyToInclude(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleAddChip(
                'companiesToInclude',
                requestBatchInput.companiesToInclude,
                companyToInclude
              )
              setCompanyToInclude('')
            }
          }}
          InputProps={{
            endAdornment: (
              <AddButton
                onClick={() => {
                  handleAddChip(
                    'companiesToInclude',
                    requestBatchInput.companiesToInclude,
                    companyToInclude
                  )
                  setCompanyToInclude('')
                }}
              />
            )
          }}
        />
        <RenderChips
          data={requestBatchInput.companiesToInclude}
          target={'companiesToInclude'}
          tag='company_include'
        />
        <FormField
          fullWidth
          value={companyToExclude}
          className={classes.rightPadding}
          label={'Companies to exclude'}
          onChange={(e: any) => setCompanyToExclude(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleAddChip(
                'companiesToExclude',
                requestBatchInput.companiesToExclude,
                companyToExclude
              )
              setCompanyToExclude('')
            }
          }}
          InputProps={{
            endAdornment: (
              <AddButton
                onClick={() => {
                  handleAddChip(
                    'companiesToExclude',
                    requestBatchInput.companiesToExclude,
                    companyToExclude
                  )
                  setCompanyToExclude('')
                }}
              />
            )
          }}
        />
        <RenderChips
          data={requestBatchInput.companiesToExclude}
          target={'companiesToExclude'}
          tag='company_exclude'
        />
      </ExpansionPanel>
      <ExpansionPanel
        title={JOB_REQUIREMENT_STEPS[6]}
        preview={[requestBatchInput.commentsAndFeedback]}
        description='Anything else you’d like to share.'
        disableNextStep={errors.commentsAndFeedback}
      >
        <StyledTextField
          fullWidth
          multiline
          rows={3}
          placeholder='Leave a comment for our team.'
          value={requestBatchInput.commentsAndFeedback}
          onChange={e =>
            setRequestBatchInput({
              index: 'commentsAndFeedback',
              value: e.target.value
            })
          }
        />
      </ExpansionPanel>
    </Grid>
  )
}

export default memo(RequiredInput)
