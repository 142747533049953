import {
  Category,
  Colors,
  ContentBucket,
  ContentBucketType,
  TabContent,
  TranscriptType
} from 'model/cms'

import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

export const getChipColorByContentBucketSlug = (type?: string): string => {
  if (type) {
    const contentType: string =
      ContentBucketType[type as keyof typeof ContentBucketType]
    return Colors[contentType as keyof typeof Colors]
  }
  return Colors.DEFAULT
}

export const constructEyebrowTimeLabel = (
  timeInMinutes: number,
  onPage: boolean,
  isVideo?: boolean,
  isEvent?: boolean
): string => {
  // Convert to hours and minutes if greater than 60 minutes
  let minutes: number = timeInMinutes
  let roundedMinutes: number = timeInMinutes
  let hours: number = 0
  let roundedHours: number = 0
  const timeSuffix: string = isVideo
    ? 'view time'
    : isEvent
    ? 'meeting'
    : 'read'
  let timeLabel = onPage
    ? `${roundedMinutes} minute ${timeSuffix}`
    : `${roundedMinutes} min`

  if (timeInMinutes > 59) {
    hours = timeInMinutes / 60
    roundedHours = Math.floor(hours)
    minutes = (hours - roundedHours) * 60
    roundedMinutes = Math.round(minutes)
    if (roundedMinutes > 0) {
      timeLabel = onPage
        ? `${roundedHours} hour ${roundedMinutes} minute ${timeSuffix}`
        : `${roundedHours} hr ${roundedMinutes} min`
    } else {
      timeLabel = onPage
        ? `${roundedHours} hour ${timeSuffix}`
        : `${roundedHours} hr`
    }
  }

  return timeLabel
}

export const constructEventTimeLabel = (date: string): string => {
  const parsedEventDate: Date = parseISO(date)
  const monthDay: string = format(
    utcToZonedTime(parsedEventDate, 'America/Los_Angeles'),
    'MMM d'
  )
  const time: string = format(
    utcToZonedTime(parsedEventDate, 'America/Los_Angeles'),
    'h:mm a'
  )
  return `${monthDay} . ${time} PST`
}

export const constructChapterLabel = (chapterNumber: number): string => {
  if (!chapterNumber) {
    return ''
  }
  let number: string = chapterNumber.toString()
  if (chapterNumber < 10) {
    number = `0${chapterNumber}`
  }
  return `CHAPTER ${number}`
}

export const constructChapterLinkLabel = (chapterNumber: number): string => {
  let number: string = chapterNumber.toString()
  if (chapterNumber < 10) {
    number = `0${chapterNumber}`
  }
  return `Read chapter ${number}`
}

export const getLinkUrl = (
  slug: string,
  contentBucket?: ContentBucket | null,
  category?: Category | null
): string => {
  if (contentBucket && contentBucket.slug === 'book') {
    return `/knowledge-center/hiring-for-diversity/chapter/${slug}/`
  } else {
    // If the content item belongs to a category
    if (category && contentBucket) {
      if (contentBucket && contentBucket.slug === 'toolkit') {
        return `/knowledge-center/category/${category.slug}/${contentBucket.slug}/${slug}/slide/intro/`
      }
      return `/knowledge-center/category/${category.slug}/${contentBucket.slug}/${slug}/`
    } else if (contentBucket) {
      return `/knowledge-center/${contentBucket.slug}/${slug}/`
    }
  }
  return `/${slug}/`
}

export const getTagsArray = (tags: string): string[] => {
  return tags.split(',')
}

export const constructTranscriptTimeLabel = (
  transcript: TranscriptType
): string => {
  let minuteMark: string = '00'
  let secondsMark: string = '00'
  if (transcript) {
    transcript.minuteMark && transcript.minuteMark < 10
      ? (minuteMark = `0${transcript.minuteMark}`)
      : (minuteMark = `${transcript.minuteMark}`)
    transcript.secondsMark && transcript.secondsMark < 10
      ? (secondsMark = `0${transcript.secondsMark}`)
      : (secondsMark = `${transcript.secondsMark}`)
  }
  return `${minuteMark}:${secondsMark}`
}

export const constructLandingPageTabsContent = (
  categories: TabContent[]
): TabContent[] => {
  // Construct category arrays in order of preference
  const defining = categories.filter(
    category => category.slug === 'defining-tracking-dei'
  )
  const sourcing = categories.filter(
    category => category.slug === 'sourcing-attracting'
  )
  const interviewing = categories.filter(
    category => category.slug === 'interviewing-engaging'
  )
  const onboarding = categories.filter(
    category => category.slug === 'onboarding-advancing'
  )
  return [...defining, ...sourcing, ...interviewing, ...onboarding]
}
