export interface InterestLevel {
  title: string
  action: string
  subtitle: string
  value: string
}
export const InterestLevels: InterestLevel[] = [
  {
    title: 'Very interested',
    action: 'to contact',
    subtitle: 'I’m looking forward to contacting this lead',
    value: 'VERY_INTERESTED'
  },
  {
    title: 'Interested',
    action: 'to contact',
    subtitle: 'I’m curious to connect and find out more',
    value: 'INTERESTED'
  },
  {
    title: 'Not interested',
    action: 'ARCHIVE',
    subtitle: 'I’m not interested in contacting this lead',
    value: 'NOT_INTERESTED'
  }
]

export interface MoveToOption {
  title: string
  value: string
}
export const MoveOptions: MoveToOption[] = [
  {
    title: 'To contact',
    value: 'TO_CONTACT'
  },
  {
    title: 'Contacted',
    value: 'CONTACTED'
  },
  {
    title: 'Archive',
    value: 'ARCHIVED'
  }
]
