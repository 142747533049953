import React from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'
import { constructEventTimeLabel } from 'hooks/cmsComponentHooks'

interface TimeChipProps {
  date: string
  cardGroup?: 'two' | 'three' | undefined
}

const StyledChip = styled(Chip)<ChipProps>(() => ({
  fontFamily: '"D-DIN", Roboto, sans-serif',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '18px',
  letterSpacing: '2px',
  color: '#000000',
  backgroundColor: '#FFFFFF',
  textTransform: 'uppercase',
  padding: '0 15px',
  height: '36px',
  borderRadius: '25px',
  '& span': {
    paddingLeft: 0
  }
}))

const DateTimeChip = ({ date }: TimeChipProps) => {
  const label: string = constructEventTimeLabel(date)
  return <StyledChip label={label} />
}
export default DateTimeChip
