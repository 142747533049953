import React, { ReactElement, useCallback, useContext, useState } from 'react'
import { Typography, Grid, Chip, Button } from '@mui/material'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useMergeLink } from '@mergeapi/react-merge-link'
import { useQuery, useMutation } from 'gql/hooks'
import { Color } from 'components'
import useStyles from './style'
import { ATS_INTEGRATION } from './constants'
import { DialogWithoutAction } from 'components'
import { MicroServiceContext } from 'context/MicroService'
import { AtsContext } from 'context/AtsContext'
import { LINK_TOKEN } from 'gql/query'
import { CREATE_LINKED_ACCOUNT } from 'gql/command'

interface Props {
  employerName: String
}

const AtsIntegration = ({ employerName }: Props): ReactElement => {
  const classes = useStyles()
  const { maestroClient } = useContext(MicroServiceContext)
  const { linkedAccount, deleteLinkedAccount } = useContext(AtsContext)
  const { data } = useQuery(LINK_TOKEN, {
    variables: {
      organizationName: employerName
    },
    client: maestroClient
  })
  const [createLinkedAccount] = useMutation(CREATE_LINKED_ACCOUNT, {
    client: maestroClient,
    // update the cache, this is required so changes are displayed immediately
    update(cache: any, { data: { linkedAccount } }: any): void {
      cache.modify({
        fields: {
          linkedAccount(): any {
            return linkedAccount
          }
        }
      })
    }
  })
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const onMergeSuccess = useCallback(
    (publicToken: String) => {
      console.log(publicToken)
      createLinkedAccount({ variables: { publicToken } })
    },
    [createLinkedAccount]
  )

  const mergeConfig = {
    linkToken: data?.linkToken,
    onSuccess: (public_token: String) => onMergeSuccess(public_token),
    onExit: () => console.log('Something bad happened here')
  }

  const { open: openMerge } = useMergeLink(mergeConfig)

  const closeDialog = () => {
    setOpenConfirmDialog(false)
  }

  return (
    <>
      <DialogWithoutAction
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title={'Disconnect my ATS platform?'}
        banner={{
          multiline: true,
          type: 'warning',
          content:
            'For all jobs, leads from your ‘To contact’ list will no longer automatically send to your ATS platform.'
        }}
        Action={() => (
          <>
            <Button
              onClick={async () => {
                deleteLinkedAccount()
                closeDialog()
              }}
              className={classes.disconnectButton}
              // variant='outlined'
              color='secondary'
            >
              Disconnect my ATS
            </Button>
            <Button
              onClick={() => {
                setOpenConfirmDialog(false)
              }}
              variant='contained'
              color='primary'
              className={classes.dialogButtons}
            >
              Go back
            </Button>
          </>
        )}
      />
      <Grid item xs={12} container direction='row' alignItems='center'>
        <Typography variant='h3' color={Color.black}>
          Applicant Tracking System (ATS) integration
        </Typography>
        <Chip
          label='Beta'
          size='small'
          style={{
            marginLeft: '8px',
            backgroundColor: '#FCF4CC',
            color: '#395260'
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.loginSetting}>
          <Typography
            variant='body1Medium'
            color={Color.black80}
            className={classes.loginSettingTitle}
            paragraph
          ></Typography>
          <Typography variant='body2' color={Color.black60}>
            {ATS_INTEGRATION.subtitle}
          </Typography>
          <Grid container direction='row' alignItems='center'>
            {linkedAccount ? (
              <CheckCircleIcon
                sx={{
                  color: '#217D47',
                  width: 16.25
                }}
              />
            ) : (
              <DoDisturbIcon
                sx={{
                  color: '#373C3F',
                  width: 16.25
                }}
              />
            )}
            <Typography style={{ paddingLeft: '8.80px' }}>
              {linkedAccount
                ? `ATS connected: ${linkedAccount.integrationName}`
                : 'ATS not connected'}
            </Typography>
            <Grid>
              {linkedAccount ? (
                <Typography
                  style={{
                    width: 130,
                    lineHeight: '18px',
                    marginLeft: '210px',
                    paddingBottom: '6px',
                    marginTop: '20px'
                  }}
                  variant='body2Medium'
                  color={Color.main}
                  paragraph
                  className={classes.clickableText}
                  onClick={() => {
                    setOpenConfirmDialog(true)
                  }}
                >
                  Disconnect my ATS
                </Typography>
              ) : (
                <Typography
                  style={{
                    width: 108,
                    lineHeight: '18px',
                    marginLeft: '300px',
                    paddingBottom: '6px',
                    marginTop: '20px'
                  }}
                  variant='body2Medium'
                  color={Color.main}
                  paragraph
                  className={classes.clickableText}
                  onClick={() => openMerge(mergeConfig)}
                >
                  Connect my ATS
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export default AtsIntegration
