import { gql } from '@apollo/client'
import { PageInfo, PaginationInput, PaginationType } from './fragments'

const Notification = gql`
  fragment notification on Notification {
    actionUrl
    hasBeenRead
    id
    insertedAt
    message
  }
`

// Queries.

export const GET_NOTIFICATIONS = gql`
  query LoadNotification (${PaginationType}, $readFilter: NotificationsReadFilterType) {
    myNotifications( ${PaginationInput}, readFilter: $readFilter) {
      count
      pageInfo {
        ...pageInfo
      }
      edges {
        node {
          ...notification
        }
      }
    }
  }
  ${PageInfo}
  ${Notification}
`

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  query LoadUnreadNotificationCount {
    myUnreadNotificationsCount
  }
`

// Commands.
export const MARK_ALL_NOTIFICATIONS_AS_READ = gql`
  mutation MarkAllNotificationsAsRead {
    markAllUnreadNotificationAsRead
  }
`

export const MARK_NOTIFICATION_AS_READ = gql`
  mutation MarkNotificationAsRead($notificationId: ID!) {
    markNotificationAsRead(notificationId: $notificationId) {
      ...notification
    }
  }
  ${Notification}
`

// Subscription
export const NEW_NOTIFICATION_SUBSCRIPTION = gql`
  subscription NewEmployerStaffAccountNotification {
    newEmployerStaffAccountNotification {
      ...notification
    }
  }
  ${Notification}
`
