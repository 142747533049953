import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import React, { memo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import WorkExperienceBuilder from './WorkExperienceBuilder'
import { ExpandStatus } from 'components'
interface Props {
  workRecords: any
}
const useStyles = makeStyles({
  showMoreItemButton: {
    backgroundColor: 'white',
    width: '100%',
    padding: '0px !important',
    minHeight: '0px !important',
    '&.Mui-expanded': {
      margin: 0
    },
    '& .showMoreItemText': {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500
    }
  },
  accordionContent: {
    display: 'block',
    margin: '0px !important'
  },
  clickable: { cursor: 'pointer' }
})
const Dialogs = (props: Props): React.ReactElement => {
  const { workRecords } = props
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          className={classes.showMoreItemButton}
          classes={{
            content: classes.accordionContent,
            expanded: classes.accordionContent,
            root: classes.showMoreItemButton
          }}
        >
          <Grid container>
            {workRecords &&
              workRecords
                .slice(0, 2)
                .map((workRecord: any, index: number) => (
                  <WorkExperienceBuilder workRecord={workRecord} key={index} />
                ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {workRecords &&
            workRecords
              .slice(2)
              .map((workRecord: any, index: number) => (
                <WorkExperienceBuilder workRecord={workRecord} key={index} />
              ))}
        </AccordionDetails>
      </Accordion>
      {workRecords.length > 2 && !expanded && (
        <Grid container>
          <ExpandStatus
            setExpanded={setExpanded}
            length={workRecords.length - 2}
            expanded={expanded}
          />
        </Grid>
      )}
    </>
  )
}

export default memo(Dialogs)
