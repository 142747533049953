import React from 'react'
import { Color } from 'components'
import { makeStyles, withStyles, styled } from '@mui/styles'
import {
  TextField as MuiTextField,
  Chip as MuiChip,
  ChipProps,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  Button as MuiButton,
  ButtonProps
} from '@mui/material'
import {
  ExpandMore as ExpandMoreIcon,
  CancelRounded as CancelRoundedIcon,
  AddCircleOutlineRounded as AddCircleOutlineRoundedIcon
} from '@mui/icons-material'

export const useStyles = makeStyles(() => ({
  jobRequirement: {
    width: 650,
    paddingTop: 32,
    paddingBottom: 100,
    '& .exitButton': {
      position: 'absolute',
      top: 32,
      left: 24
    }
  },
  qlEditor: {
    border: `1px solid ${Color.main}`,
    borderRadius: 5,
    '& .ql-snow': {
      border: 'none',
      '&.ql-toolbar': { borderBottom: `1px solid ${Color.main}` }
    },
    '& .ql-editor': {
      minHeight: 160,
      maxHeight: 300
    }
  },
  dialogActions: {
    width: 650,
    height: 100,
    margin: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .cancelButton': {
      padding: '9px 25px',
      marginRight: 16,
      borderColor: Color.black,
      color: Color.black
    },
    '& .submitButton': { padding: '10px 30px' }
  },
  radioGroup: {
    marginBottom: 16
  },
  radioButton: {
    height: 20
  },
  errorIcon: {
    height: 15,
    width: 15,
    marginRight: 10
  },
  addProfileButton: {
    marginTop: -8
  },
  longButton: {
    marginTop: 8,
    marginLeft: 16,
    display: 'block',
    width: 170,
    height: 40
  },
  displayText: {
    wordBreak: 'break-word',
    marginLeft: 18
  },
  lineBreak: {
    flexBasis: '100%',
    width: 0
  },
  previewHeading: {
    marginLeft: 18,
    marginTop: 8,
    display: 'block'
  },
  rightPadding: {
    '& .MuiOutlinedInput-root': { paddingRight: 6 }
  },
  textFieldWithDelete: {
    '& .MuiOutlinedInput-root': { paddingRight: 7 },
    '& .MuiIconButton-root': {
      backgroundColor: '#E8EBED',
      width: 30,
      height: 30,
      '&:disabled': { display: 'none' }
    },
    '& .MuiSvgIcon-root': {
      color: Color.grey700,
      width: 22,
      height: 22
    }
  }
}))

export const StyledTextField = withStyles({
  root: {
    marginBottom: 8,
    '& .MuiOutlinedInput-root': {
      padding: 10
    },
    '& .MuiOutlinedInput-input': {
      padding: 0
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0,
      display: 'flex'
    },
    '& .MuiSvgIcon-root': {
      height: 15,
      width: 15,
      marginRight: 10,
      alignSelf: 'center'
    }
  }
})(MuiTextField)

export const Chip = styled((props: ChipProps) => (
  <MuiChip deleteIcon={<CancelRoundedIcon />} {...props} />
))(() => ({
  height: 30,
  color: Color.grey700,
  backgroundColor: '#E8EBED',
  '& .MuiChip-label': {
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.01em'
  },
  '& .MuiChip-deleteIcon': { color: Color.grey700, width: 18, height: 18 }
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(() => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    display: 'none'
  },
  '& .MuiAccordionSummary-content': {
    padding: '24px 0 16px',
    margin: 0
  }
}))

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  borderBottom: `1px solid #D7DCDF`,
  '&:last-child': {
    borderBottom: 'none'
  }
}))

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0 48px 16px'
}))

export const AddButton = styled((props: ButtonProps) => (
  <MuiButton {...props} endIcon={<AddCircleOutlineRoundedIcon />}>
    Add
  </MuiButton>
))(() => ({
  color: Color.grey700,
  padding: '6px 15px'
}))
