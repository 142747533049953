import React, { ReactElement, useContext } from 'react'
import { ImpersonatorBanner } from 'components'
import { Route, Routes } from 'react-router-dom'
import Jobs from 'pages/Jobs'
import SelectedJob from 'pages/Jobs/SelectedJob'
import BiasScanner from 'pages/BiasScanner'
import NotFound from 'pages/NotFound'
import Home from 'pages/Home'
import EqualHiringIndex from 'pages/EqualHiringIndex'
import SelectedProfile from 'pages/Talent/SelectedProfile'
import Organization from 'pages/Organization'
import { MicroServiceContext } from 'context/MicroService'

const ProtectedRoutes = (): ReactElement => {
  const { setHideHeader, isImpersonator } = useContext(MicroServiceContext)

  return (
    <>
      {isImpersonator && <ImpersonatorBanner />}

      <Routes>
        <Route path='' element={<Home />} />
        <Route path='/jobs' element={<Jobs />} />
        <Route
          path='/jobs/:nanoid/*'
          element={<SelectedJob disableOriginHeader={setHideHeader} />}
        />
        <Route path='/bias-scanner' element={<BiasScanner />} />
        <Route path='/equal-hiring-index' element={<EqualHiringIndex />} />
        <Route
          path='/talent-profile/:nanoid'
          element={<SelectedProfile fixedWidth />}
        />
        <Route path='/organization-settings' element={<Organization />} />

        <Route path='/*' element={<NotFound />} />
      </Routes>
    </>
  )
}

export default ProtectedRoutes
