import { gql } from '@apollo/client'
import { ImpactManager, EmployerStaffPermissions } from './fragments'

export const Employer = gql`
  fragment employer on Employer {
    id
    logo(imgixParams: $imgixParams)
    name
    authenticationStatus
    status
    mainContact {
      email
      name
      phone
    }
    impactManager {
      email
      id
      name
    }
  }
`
export const CURRENT_ACCOUNT = gql`
  query CurrentAccount {
    cmsApiKey
    currentAccount {
      ... on EmployerStaffAccount {
        id
        email
        user {
          id
          name
          primaryEmail
        }
        ...employerStaffPermissions
        employer {
          id
          ...impactManager
          name
          mainContact {
            email
            name
            phone
          }
        }
      }

      ... on ImpersonatorAccount {
        id
        email
        user {
          id
          name
          primaryEmail
        }
        ehiAccess
        jobsAccess
        role
        employer {
          id
          ...impactManager
          name
          numAllocatedSeats
          mainContact {
            email
            name
            phone
          }
        }
      }
    }
  }
  ${EmployerStaffPermissions}
  ${ImpactManager}
`

//command

export const SUBMIT_CONTACT_FORM = gql`
  mutation SubmitContactForm($message: String) {
    submitContactForm(message: $message)
  }
`

export const REQUEST_AUTHENTICATION_SETTINGS_CHANGE = gql`
  mutation RequestAuthenticationSettingsChange(
    $input: RequestAuthenticationsSettingsChangeInput!
  ) {
    requestAuthenticationSettingsChange(input: $input)
  }
`

export const GET_PRESIGNED_EMPLOYER_IMAGE_URL = gql`
  query presignedEmployerImageUrl($imageFileName: String!) {
    presignedEmployerImageUrl(imageFileName: $imageFileName) {
      url
      s3Key
    }
  }
`

export const UPDATE_EMPLOYER_LOGO = gql`
  mutation UpdateEmployerLogo($input: UpdateEmployerLogoInput!) {
    updateEmployerLogo(input: $input)
  }
`
export const EMPLOYER_UPDATED = gql`
  subscription EmployerUpdated(
    $id: ID!
    $type: EmployerUpdateType!
    $imgixParams: ImgixParamsInput
  ) {
    employerUpdated(id: $id, type: $type) {
      logo(imgixParams: $imgixParams)
    }
  }
`

//
export const AUTHENTICATION_SETTINGS_CHANGED_PROCESSED = gql`
  subscription AuthenticationSettingsChangeProcessed {
    authenticationSettingsChangeProcessed {
      message
      success
    }
  }
`

export const MY_ACCOUNT_PERMISSIONS_CHANGED = gql`
  subscription MyAccountPermissionsChanged {
    myAccountPermissionsChanged {
      ... on EmployerStaffAccount {
        ...employerStaffPermissions
      }
    }
  }
  ${EmployerStaffPermissions}
`
