import { Typography, Button, Grid, Divider, IconButton } from '@mui/material'
import React, { memo, useContext } from 'react'
import { useStyles } from './style'
import { useMutation } from 'gql/hooks'
import { Color, InfoBanner } from 'components'
import { FullScreenDialogContext } from 'context/FullScreenDialog'
import { RequestBatchContext } from 'context/RequestBatch'
import { MyJobContext } from 'context/JobContext'
import { toCapitalize } from 'utils/stringHelper'
import { REQUEST_JOB_BATCH } from 'gql/command'
import { JOB_BATCH_REQUESTED_SUBSCRIPTION } from 'gql/subscription'
import { Close as CloseIcon } from '@mui/icons-material'

interface Props {
  step: number
  setStep: (step: number) => void
}
const Completed = ({ step, setStep }: Props): React.ReactElement => {
  const classes = useStyles()
  const { setOpenBatchRequest } = useContext(FullScreenDialogContext)
  const { job, startSubscription, numRemainingCreditsForMonth } =
    useContext(MyJobContext)
  const { requestBatchInput } = useContext(RequestBatchContext)
  const [requetJobBatch] = useMutation(REQUEST_JOB_BATCH, {
    successNotification: 'Your request has been sent.'
  })

  const handleSubmitBatchRequest = () => {
    startSubscription(JOB_BATCH_REQUESTED_SUBSCRIPTION, () =>
      setOpenBatchRequest!(false)
    )
    requetJobBatch({
      variables: {
        input: {
          batchDeliveryPreferenceId:
            requestBatchInput.batchDeliveryPreferenceId,
          jobId: job!.id
        }
      }
    })
  }
  const numOfBatchLeft = numRemainingCreditsForMonth - 1
  return (
    <Grid className={classes.completed}>
      <Grid
        container
        marginBottom={4}
        alignItems='center'
        justifyContent='space-between'
      >
        <Typography variant='h1' lineHeight='46px' color={Color.black}>
          Confirm your batch request
        </Typography>
        <IconButton
          onClick={() => setOpenBatchRequest!(false)}
          className='closeButton'
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      <Typography
        variant='subheading'
        color={Color.black75}
        display='block'
        marginBottom={1}
      >
        JOB INFORMATION
      </Typography>
      <Typography variant='h3' color={Color.black} display='block'>
        {job!.displayTitle}
      </Typography>
      <Typography
        variant='body2'
        lineHeight={'24px'}
        color={Color.defaultText}
        display='block'
        marginBottom={3}
      >
        {job!.internalTitle}
      </Typography>
      <Grid container className={classes.redBanner}>
        <InfoBanner
          fullWidth
          type='pending'
          content={
            <>
              After submitting, you will have{' '}
              <Typography
                component='span'
                variant='body2'
                fontWeight={700}
                color={Color.black87}
              >
                {`${numOfBatchLeft} ${
                  numOfBatchLeft > 1 ? 'batches' : 'batch'
                } `}
              </Typography>{' '}
              remaining this month.
            </>
          }
        />
      </Grid>

      <Grid container height='68px'>
        <Grid marginRight={5}>
          <Typography variant='body2' color={Color.black60}>
            Batches remaining
          </Typography>
          <Grid container>
            <Typography variant='h3' color={Color.black} lineHeight={'48px'}>
              {numRemainingCreditsForMonth}
            </Typography>
          </Grid>
        </Grid>
        <Divider orientation='vertical' />
        <Grid marginLeft={5}>
          <Typography variant='body2' color={Color.black60}>
            Batches used in request
          </Typography>
          <Grid container>
            <Typography variant='h3' color={Color.black} lineHeight={'48px'}>
              1
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Typography
        variant='subheading'
        color={Color.black75}
        display='block'
        marginBottom={2}
        marginTop={5}
      >
        REQUEST SUMMARY
      </Typography>
      <Typography
        variant='body2'
        lineHeight={'24px'}
        color={Color.defaultText}
        display='block'
        gutterBottom
      >
        Job type
      </Typography>
      <Typography variant='h6' className='selectionText'>
        {requestBatchInput.jobType && toCapitalize(requestBatchInput.jobType)}
        <span className='editText' onClick={() => setStep(0)}>
          Edit
        </span>
      </Typography>
      <Typography
        variant='body2'
        lineHeight={'24px'}
        color={Color.defaultText}
        display='block'
        gutterBottom
      >
        Batch delivery
      </Typography>
      <Typography variant='h6' className='selectionText'>
        {requestBatchInput.batchSizeName}
        <span className='editText' onClick={() => setStep(1)}>
          Edit
        </span>
      </Typography>
      <Grid container justifyContent={'space-between'}>
        <Button
          variant='outlined'
          color='secondary'
          className={classes.backButton}
          onClick={() => setStep(step - 1)}
        >
          Back
        </Button>
        <Button
          variant='contained'
          className={classes.nextButton}
          onClick={() => handleSubmitBatchRequest()}
        >
          Confirm and submit
        </Button>
      </Grid>
    </Grid>
  )
}

export default memo(Completed)
