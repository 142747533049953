import React, { useContext } from 'react'

import { Grid, Typography } from '@mui/material'
import useStyles from './style'
import StatisticsBlock from './StatisticsBlock'
import Events from './Events'
import CategoryContents from './CategoryContents'
import { DashboardProvider } from 'context/DashboardContext'
import { MicroServiceContext } from 'context/MicroService'

const Home = (): React.ReactElement => {
  const classes = useStyles()

  const { currentAccount, cmsApiKey } = useContext(MicroServiceContext)

  if (!cmsApiKey) {
    return <></>
  }
  return (
    <DashboardProvider cmsApiKey={cmsApiKey}>
      <div className={classes.dashboardContainer}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h1'>
              Welcome, {`${currentAccount.user.name.split(' ')[0]}`}
            </Typography>
          </Grid>
          <Grid item container>
            <StatisticsBlock />
            <Events />
            <CategoryContents />
          </Grid>
        </Grid>
      </div>
    </DashboardProvider>
  )
}

export default Home
