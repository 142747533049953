import { SingleCheckIcon, DoubleCheckIcon, CrossIcon } from 'components'
import { StructuredFeedback } from 'model/jobPipeline'

export const IMG_LOGO_PARAMS = {
  w: 420,
  h: 420,
  ar: '1:1',
  fit: 'crop',
  mask: 'ellipse'
}

export const initialReviewInput = {
  generalFeedback: '',
  structuredFeedback: undefined
}

export const INTEREST_LEVEL = [
  {
    title: 'Very interested',
    value: StructuredFeedback.VERY_INTERESTED,
    tag: 'To contact',
    description: 'I’m excited to contact this lead',
    icon: DoubleCheckIcon
  },
  {
    title: 'Interested',
    value: StructuredFeedback.INTERESTED,
    tag: 'To contact',
    description: 'I’m curious to connect and find out more',
    icon: SingleCheckIcon
  },
  {
    title: 'Not interested',
    value: StructuredFeedback.NOT_INTERESTED,
    tag: 'Archive',
    description: 'I’m not interested in contacting this lead',
    icon: CrossIcon
  }
]
