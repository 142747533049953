import { makeStyles, withStyles } from '@mui/styles'
import { MenuItem as MuiMenuItem } from '@mui/material'

export const useStyles = makeStyles(() => {
  return {
    placeholder: { width: 32 },
    menuIcon: { marginRight: 12 }
  }
})

export const MenuItem = withStyles({
  root: {
    height: 46,
    width: 188,
    padding: '12px 8px',
    borderRadius: 5
  }
})(MuiMenuItem)
