import React, { useEffect, useContext } from 'react'
import { useStyles } from './style'
import { Color, ViewIcon } from 'components'
import { Grid, Typography, Button, ButtonProps } from '@mui/material'
import ReviewQueue from './ReviewQueue'
import ColourLogo from 'assets/images/Colour_Logo.png'
import { HashLink } from 'react-router-hash-link'
import { MyJobContext } from 'context/JobContext'
import { styled } from '@mui/material/styles'

interface Props {
  nanoid: string
  disableOriginHeader: (disabled: boolean) => void
}

interface MaskButtonProps extends ButtonProps {
  maskingFlag?: boolean
}

const MaskButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'maskingFlag'
})<MaskButtonProps>(({ maskingFlag }) => ({
  width: '168px',
  height: '32px',
  color: maskingFlag ? Color.white : Color.grey700,
  backgroundColor: maskingFlag ? Color.black75 : 'none',
  '&:hover': {
    backgroundColor: maskingFlag ? Color.black75 : '#E7ECEE'
  }
}))

const CandidateReview = ({
  nanoid,
  disableOriginHeader
}: Props): React.ReactElement => {
  const classes = useStyles()
  const { job, maskingFlag, handleToggleMasking } = useContext(MyJobContext)
  useEffect(() => {
    disableOriginHeader(true)
    return () => {
      disableOriginHeader(false)
    }
  }, [disableOriginHeader])

  return (
    <Grid container>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        className={classes.banner}
      >
        <Grid
          container
          alignItems='start'
          width='fit-content'
        >
          <HashLink to='/' className='logo'>
            <img src={ColourLogo} width='149' alt='Mathison' />
            <Typography variant='h7' color={Color.black} fontWeight={500} minWidth='fit-content' paddingRight='16px' borderRight='1px solid #E0E7EB'>
              Lead review
            </Typography>
          </HashLink>
          <Typography variant='h7' color={Color.grey700} fontWeight={500} minWidth='fit-content' paddingLeft='16px'>
            {job?.displayTitle}
          </Typography>
        </Grid>

        <MaskButton
          disableRipple
          endIcon={<ViewIcon disabled={maskingFlag} />}
          onClick={() => handleToggleMasking()}
          maskingFlag={maskingFlag}
        >
          <Typography variant='body1Medium' noWrap lineHeight='18px'>
            {maskingFlag ? 'Unmask leads' : 'Mask leads'}
          </Typography>
        </MaskButton>
      </Grid>
      {<ReviewQueue nanoid={nanoid} />}
    </Grid>
  )
}

export default CandidateReview
