import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import { generateLink, toCapitalize } from 'utils/stringHelper'
import { getLocationToDisplay } from 'utils/locationFormat'
import { useStyles, AccordionSummary, AccordionDetails } from '../style'
import { Color, Paper, UserAvatar, MailIcon, LinkIcon } from 'components'
import {
  CardContent,
  Typography,
  Grid,
  Accordion,
  CircularProgress
} from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { HashLink } from 'react-router-hash-link'
import { useLazyQuery } from 'gql/hooks'
import { GET_PROFILE_CONTACT_INFO } from 'gql/query'
import { CONTACT_INFO_STATUS_UPDATED_SUBSCRIPTION } from 'gql/subscription'
import { MyJobContext } from 'context/JobContext'

import {
  BasicInfoBlock,
  WorkRecord,
  AvatarBlock,
  TalentEmail,
  Location
} from 'model/profile'
import { ErrorRounded as ErrorIcon } from '@mui/icons-material'
import { MicroServiceContext } from 'context/MicroService'

interface Props {
  profileId: string
  basicInfoBlock: BasicInfoBlock
  currentJob?: WorkRecord[]
  avatarBlock?: AvatarBlock
  location?: Location
  currentState?: string
  nanoid: string
}

const GeneralInfo = ({
  profileId,
  basicInfoBlock,
  currentJob,
  avatarBlock,
  location,
  currentState,
  nanoid
}: Props): React.ReactElement => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [loading, setLoading] = useState(false)
  const { displayNotification } = useContext(MicroServiceContext)
  const { maskingFlag } = useContext(MyJobContext)
  const maskingOn = maskingFlag && currentState
  const [getContactInfo, { data, subscribeToMore }] = useLazyQuery(
    GET_PROFILE_CONTACT_INFO,
    {
      isSubscriptionEphemeral: false,
      variables: {
        profileId: profileId
      },
      onError: (data: any) => {
        if (
          data.message ===
          'Can not enrich a profile that is currently being enriched from the same provider'
        ) {
          if (!loading) {
            setLoading(true)
          }
          startSubscription()
        }
      },
      fetchPolicy: 'cache-and-network'
    }
  )

  const startSubscription = () => {
    subscribeToMore({
      document: CONTACT_INFO_STATUS_UPDATED_SUBSCRIPTION,
      variables: {
        profileId: profileId
      },
      updateQuery: (_prev: any, { subscriptionData }: any) => {
        if (subscriptionData.data) {
          return {
            profileContactInfo: {
              ...subscriptionData.data.contactInfoEnrichmentStatusUpdated
            }
          }
        }
      }
    })
  }

  const handleClickContactInfo = () => {
    if (!loading) {
      if (!expanded) {
        getContactInfo()
      }
      setExpanded(!expanded)
    }
  }
  const profileContactInfo = data?.profileContactInfo
  const typeName = profileContactInfo?.__typename

  useEffect(() => {
    if (profileContactInfo?.status === 'PENDING') {
      setLoading(true)
      startSubscription()
    }
    if (
      profileContactInfo?.status === 'FAILED' ||
      typeName === 'ContactInfoBlock'
    ) {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContactInfo?.status, typeName])

  return (
    <Paper className={classes.paper}>
      <CardContent className={classes.generalInfo}>
        <Grid container spacing={3}>
          <Grid item className='avatarContainer'>
            {maskingOn ? (
              <div className='maskedAvatar' />
            ) : (
              basicInfoBlock.name && (
                <UserAvatar
                  username={basicInfoBlock.name}
                  avatarURL={
                    avatarBlock?.url ? `https://${avatarBlock.url}` : ''
                  }
                  size={140}
                />
              )
            )}
          </Grid>
          <Grid item xs>
            <Grid item container alignItems='center'>
              <HashLink to={`/talent-profile/${nanoid}#`}>
                <Typography
                  variant='h2'
                  className={clsx('nameText', {
                    ['nameTextHover']: currentState && !maskingOn,
                    ['maskedText']: maskingOn
                  })}
                >
                  {basicInfoBlock.name}
                </Typography>
              </HashLink>
              {currentState && basicInfoBlock.name && (
                <Typography
                  variant='body2Medium'
                  color={Color.black80}
                  className='tag'
                  lineHeight='22px'
                  style={{
                    backgroundColor:
                      currentState === 'Not interested' ? '#E8EBED' : '#E6F8F8'
                  }}
                >
                  {toCapitalize(currentState)}
                </Typography>
              )}
            </Grid>
            {currentJob && (
              <Typography
                variant='body1High'
                color={Color.black}
                display='block'
              >
                {currentJob[0]?.job}
              </Typography>
            )}
            <Typography variant='body1High' color={Color.black} display='block'>
              {location && getLocationToDisplay(location)}
            </Typography>
          </Grid>
          {currentState && (
            <Grid item>
              <CopyToClipboard
                text={generateLink(`/talent-profile/${nanoid}`)}
                onCopy={() => {
                  displayNotification('Link copied')
                }}
              >
                <Typography
                  variant='body1Medium'
                  color={Color.main}
                  className='linkText'
                >
                  Share profile
                  <LinkIcon className='icon' />
                </Typography>
              </CopyToClipboard>
            </Grid>
          )}
        </Grid>
        <Grid>
          <Accordion expanded={expanded}>
            <AccordionSummary>
              <MailIcon className='mailIcon' />
              <Typography
                variant='body1Medium'
                color={Color.black}
                className='contactInfo'
                lineHeight='18px'
              >
                Contact info
              </Typography>
              <Typography
                variant='body1Medium'
                color='primary'
                className='toggle'
                lineHeight='18px'
                onClick={() => handleClickContactInfo()}
              >
                {expanded ? 'Hide' : 'Show'}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {typeName === 'ContactInfoBlock' &&
                profileContactInfo?.emails.map(
                  (email: TalentEmail, index: number) => (
                    <Typography
                      gutterBottom
                      key={`email_${index}`}
                      variant='body2'
                      color={Color.black80}
                    >
                      {email.address}
                    </Typography>
                  )
                )}
              {typeName === 'ContactInfoEnrichmentStatus' &&
                profileContactInfo?.status === 'FAILED' && (
                  <Grid container>
                    <ErrorIcon className={classes.errorIcon} />
                    <Typography variant='body2' color={Color.black}>
                      Sorry, we can’t find contact information for this lead.
                    </Typography>
                  </Grid>
                )}
              {loading && (
                <CircularProgress
                  size={20}
                  className={classes.circularProgress}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </CardContent>
    </Paper>
  )
}

export default GeneralInfo
