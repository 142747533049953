import { makeStyles, withStyles } from '@mui/styles'
import { Color } from 'components'
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material'

export const useStyles = makeStyles(() => ({
  stateContainer: {
    borderRadius: 20,
    height: 28,
    padding: '0 10px'
  },
  expendButton: {
    color: Color.grey700,
    padding: 6,
    fontSize: 24
  },
  blackButton: {
    height: 44,
    width: 162
  },
  shortMenuItem: {
    width: 280
  },
  selectMenuItem: {
    width: 534
  },
  selectMenuList: {
    padding: 8
  },
  disabled: { display: 'none' },
  selectButton: {
    width: 551,
    height: 45,
    border: '1px solid #A2ABB1',
    borderRadius: 5,
    padding: 12,
    '&:hover': {
      border: '1px solid #0079B6'
    },
    '& .icon': {
      color: Color.grey700
    }
  }
}))

export const Menu = withStyles({
  list: { padding: 8 }
})(MuiMenu)

export const MenuItem = withStyles({
  root: {
    height: 54,
    borderRadius: 5,
    color: Color.black
  }
})(MuiMenuItem)
