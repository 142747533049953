import React, { memo, useState, useContext } from 'react'
import {
  DialogContentText,
  Button,
  Typography,
  Grid,
  Divider,
  DividerProps,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {
  DialogWithoutAction,
  UserAvatar,
  Color,
  Chip,
  InfoBanner
} from 'components'
import { getLocationToDisplay } from 'utils/locationFormat'
import useStyles from './style'
import { toCapitalize, generateLink } from 'utils/stringHelper'
import {
  InterestLevels,
  InterestLevel,
  MoveOptions,
  MoveToOption
} from './constant'
import { JobCandidateContext } from 'context/JobCandidateContext'
import { MyJobContext } from 'context/JobContext'
import { StructuredFeedback } from 'model/jobPipeline'

import { PipelineStage } from 'model/jobPipeline'
import Profile from './Profile'
import { styled } from '@mui/material/styles'

interface Props {
  row: any
  openConfirmDialog: boolean
  setOpenConfirmDialog: (bool: boolean) => void
  stage: PipelineStage
  previous: () => void
  next: () => void
  disablePreviousButton: boolean
}

const StyledDivider = styled(Divider)<DividerProps>(() => ({
  marginTop: 24,
  marginBottom: 24,
  width: 600,
  position: 'relative',
  right: 24
}))

const Dialogs = (props: Props): React.ReactElement => {
  const {
    row,
    openConfirmDialog,
    setOpenConfirmDialog,
    stage,
    previous,

    disablePreviousButton,
    next
  } = props
  const [overallInterest, setOverallInterest] = useState<
    StructuredFeedback | ''
  >('')
  const [moveToOption, setMoveToOption] = useState<MoveToOption | ''>('')
  const [archiveLead, setArchiveLead] = useState(false)
  const [feedback, setFeedback] = useState('')
  const classes = useStyles()

  const { profile } = row
  const { onAcceptCandidate, onMoveCandidate, onRejectCandidate } =
    useContext(JobCandidateContext)
  const { maskingFlag } = useContext(MyJobContext)
  const reset = () => {
    setOverallInterest('')
    setMoveToOption('')
    setArchiveLead(false)
    setFeedback('')
  }
  return (
    <>
      <DialogWithoutAction
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        title={''}
        Action={() => (
          <>
            {stage == PipelineStage.CONTACTED && (
              <FormControlLabel
                className={classes.archiveBox}
                control={
                  <Checkbox
                    checked={archiveLead}
                    onClick={() => setArchiveLead(!archiveLead)}
                  ></Checkbox>
                }
                label={
                  <Typography variant='body1Medium' color={Color.black80}>
                    Archive lead
                  </Typography>
                }
              />
            )}
            <Button
              onClick={async () => {
                previous()
                reset()
              }}
              className={classes.dialogButtons}
              variant='outlined'
              color='secondary'
              disabled={disablePreviousButton}
            >
              Previous
            </Button>
            <Button
              onClick={() => {
                if (stage === PipelineStage.TO_REVIEW) {
                  if (
                    overallInterest === StructuredFeedback.INTERESTED ||
                    overallInterest === StructuredFeedback.VERY_INTERESTED
                  )
                    onAcceptCandidate(
                      {
                        variables: {
                          input: {
                            candidateId: row.id,
                            structuredFeedback: overallInterest,
                            generalFeedback: feedback
                          }
                        }
                      },
                      row.id
                    )
                  if (overallInterest === StructuredFeedback.NOT_INTERESTED) {
                    onRejectCandidate(
                      {
                        variables: {
                          input: {
                            candidateId: row.id,
                            structuredFeedback: overallInterest,
                            generalFeedback: feedback
                          }
                        }
                      },
                      row.id
                    )
                  }
                }
                if (
                  (stage === PipelineStage.TO_CONTACT ||
                    stage === PipelineStage.ARCHIVED) &&
                  moveToOption !== ''
                ) {
                  onMoveCandidate(
                    {
                      variables: {
                        input: { candidateId: row.id, stage: moveToOption }
                      }
                    },
                    row.id
                  )
                }
                if (stage === PipelineStage.CONTACTED && archiveLead) {
                  onMoveCandidate(
                    {
                      variables: {
                        input: {
                          candidateId: row.id,
                          stage: PipelineStage.ARCHIVED
                        }
                      }
                    },
                    row.id
                  )
                }
                next()
                reset()
              }}
              variant='contained'
              color='primary'
              className={classes.dialogButtons}
            >
              Next
            </Button>
          </>
        )}
      >
        <DialogContentText
          id='alert-dialog-description'
          component='div'
          style={{ paddingBottom: 0 }}
        >
          <IconButton
            onClick={() => setOpenConfirmDialog(false)}
            size='small'
            sx={{ position: 'absolute', right: 24, top: 24 }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container>
            <Grid item xs={3} className={classes.avatarContainer}>
              {maskingFlag ? (
                <div className={classes.maskedAvatar} />
              ) : (
                <UserAvatar
                  username={profile && profile.basicInfoBlock.name}
                  size={96}
                  avatarURL={
                    profile.avatarBlock && profile.avatarBlock.url
                      ? `https://${profile.avatarBlock.url}`
                      : ''
                  }
                />
              )}
            </Grid>
            <Grid item xs>
              <Grid item xs={12} container>
                <Typography
                  variant='h3'
                  color={maskingFlag ? 'transparent' : Color.black}
                  margin={'8px 0px'}
                  sx={{
                    '&:hover': {
                      textDecoration: 'underline',
                      cursor: 'pointer'
                    },
                    textShadow: maskingFlag
                      ? '0 0 20px rgba(0,0,0,0.5)'
                      : 'none'
                  }}
                  onClick={() =>
                    window.open(
                      generateLink(`/talent-profile/${profile.nanoid}`)
                    )
                  }
                >
                  {profile.basicInfoBlock.name}
                </Typography>
                <Chip
                  label={toCapitalize(stage)}
                  sx={{ marginTop: '13px', marginLeft: '12px' }}
                />
              </Grid>
              <Typography variant='body1' color={Color.black} component='p'>
                {profile.workHistoryBlock.current.length > 0 &&
                  profile.workHistoryBlock.current[0].job}
              </Typography>
              <Typography variant='body1' color={Color.black} paragraph>
                {getLocationToDisplay(profile.locationsBlock?.location)}
              </Typography>
            </Grid>
            <StyledDivider />
            <Profile row={row} />
            <StyledDivider />

            {stage === PipelineStage.TO_REVIEW && (
              <>
                <Typography variant='subheading' color={Color.black75}>
                  FEEDBACK
                </Typography>
                <div className={classes.infobar}>
                  <InfoBanner
                    content='Feedback is only visible to our recruiters and won’t be shared
               with this lead.'
                    type='info'
                    multiline
                  />
                </div>
                <Typography
                  variant='formLabel'
                  color={Color.black87}
                  paragraph
                  margin={'16px auto 4px 0px'}
                >
                  Overall interest
                </Typography>
                <Select
                  value={overallInterest}
                  onChange={(e: any) => {
                    setOverallInterest(e.target.value)
                  }}
                  fullWidth
                  SelectDisplayProps={{
                    style: { padding: '12.5px 14px' }
                  }}
                  renderValue={value => {
                    const selectedLevel = InterestLevels.find(
                      (level: InterestLevel) => level.value === value
                    )
                    return (
                      <>
                        <Typography
                          variant='body1Medium'
                          component='span'
                          color={Color.black80}
                        >
                          {selectedLevel!.title}
                        </Typography>
                        <Typography
                          variant='caption'
                          component='span'
                          color={Color.grey700}
                          style={{ textTransform: 'uppercase', marginLeft: 12 }}
                        >
                          {selectedLevel!.action}
                        </Typography>
                      </>
                    )
                  }}
                >
                  {InterestLevels.map((level: InterestLevel, index: number) => {
                    return (
                      <MenuItem
                        key={index}
                        value={level.value}
                        style={{
                          display: 'block',
                          paddingTop: 12,
                          paddingBottom: 12,
                          height: 66,
                          lineHeight: '24px'
                        }}
                      >
                        <Typography
                          variant='body1Medium'
                          component='span'
                          color={Color.black80}
                        >
                          {level.title}
                        </Typography>
                        <Typography
                          variant='caption'
                          component='span'
                          color={Color.grey700}
                          style={{ textTransform: 'uppercase', marginLeft: 12 }}
                        >
                          {level.action}
                        </Typography>
                        <Typography
                          variant='body2'
                          component='div'
                          color={Color.black60}
                        >
                          {level.subtitle}
                        </Typography>
                      </MenuItem>
                    )
                  })}
                </Select>
              </>
            )}
            {(stage === PipelineStage.TO_CONTACT ||
              stage === PipelineStage.ARCHIVED) && (
              <>
                <Typography
                  variant='formLabel'
                  paragraph
                  color={Color.black87}
                  margin={'16px auto 4px 0px'}
                >
                  Move lead to
                </Typography>
                <Select
                  value={moveToOption}
                  onChange={(e: any) => {
                    setMoveToOption(e.target.value)
                  }}
                  fullWidth
                  SelectDisplayProps={{
                    style: { padding: '12.5px 14px' }
                  }}
                >
                  {MoveOptions.filter(
                    (option: MoveToOption) => option.value !== stage
                  ).map((option: MoveToOption, index: number) => {
                    return (
                      <MenuItem key={index} value={option.value}>
                        {option.title}
                      </MenuItem>
                    )
                  })}
                </Select>
              </>
            )}
            {overallInterest !== '' && (
              <>
                <Typography
                  variant='formLabel'
                  color={Color.black87}
                  paragraph
                  margin={'16px auto 4px 0px'}
                >
                  Leave a comment (optional)
                </Typography>
                <Typography variant='body1' paragraph color={Color.black60}>
                  This helps our recruiters find you more relevant leads in the
                  future
                </Typography>
                <TextField
                  multiline
                  fullWidth
                  value={feedback}
                  onChange={(e: any) => {
                    setFeedback(e.target.value)
                  }}
                  rows={4}
                  className={classes.textarea}
                  placeholder='Eg. Overqualified, needs more managerial experience'
                ></TextField>
              </>
            )}
          </Grid>
        </DialogContentText>
      </DialogWithoutAction>
    </>
  )
}

export default memo(Dialogs)
