import React, { memo } from 'react'
import useStyles from './style'
import { toCapitalizeWithSpace } from 'utils/stringHelper'
import { FormControl, Select, MenuItem } from '@mui/material'

interface Props {
  selected: any
  select: (selected: any) => void
  options: any[]
}

const MoveSelector = ({
  selected,
  select,
  options
}: Props): React.ReactElement => {
  const classes = useStyles()

  return (
    <FormControl fullWidth className={classes.selectContainer}>
      <Select
        value={selected}
        displayEmpty
        className={classes.select}
        renderValue={selected => {
          return toCapitalizeWithSpace(selected)
        }}
        MenuProps={{
          classes: {
            paper: classes.selectMenuItem,
            list: classes.selectMenuList
          }
        }}
      >
        {options.map((option: any, index: number) => {
          return (
            <MenuItem
              value={option}
              key={`item_${index}`}
              onClick={() => select(option)}
              className={classes.menuItemfont}
            >
              {toCapitalizeWithSpace(option)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default memo(MoveSelector)
