import { makeStyles } from '@mui/styles'
import { Color } from 'components'

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    backgroundColor: '#FBF2EB'
  },
  button: {
    color: Color.black,
    borderColor: Color.black,
    padding: '6px 12px'
  },
  employerName: {
    fontWeight: 'bold'
  }
}))

export default useStyles
