import { makeStyles } from '@mui/styles'
import { Color } from 'components'
const useStyles = makeStyles(() => ({
  filterLabel: { lineHeight: '40px', paddingLeft: 8 },
  filterValue: { lineHeight: '40px', paddingLeft: 4 },
  container: {
    height: 40,
    marginTop: -14,
    marginBottom: 8,
    justifyContent: 'flex-end',
    display: 'inline-flex',
    width: 600,
    marginLeft: 'auto'
  },
  noPaddingTop: { paddingTop: 0 },
  roundDot: {
    display: 'block',
    height: 22,
    width: 22,
    backgroundColor: Color.main,
    verticalAlign: 'top',
    lineHeight: '22px',
    color: 'white',
    borderRadius: '50%'
  },
  defaultStateIcon: { color: Color.grey700 },
  endIcon: { marginLeft: 0, marginRight: 0 }
}))
export default useStyles
