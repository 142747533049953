import React, { useState } from 'react'
import { Dialog, Color } from 'components'
import { useStyles, Chip } from './style'
import { PipelineStage, PipelineStageOptions } from 'model/jobPipeline'
import {
  Radio,
  RadioGroup,
  Checkbox,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  FormControlLabel
} from '@mui/material'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}

const ExportCandidate = ({ open, setOpen }: Props): React.ReactElement => {
  const classes = useStyles()
  const [stages, setStages] = useState<string[]>([])
  const [exportFormat, setExportFormat] = useState<string>('')

  const handleChangeStage = (event: SelectChangeEvent<typeof stages>) => {
    const {
      target: { value }
    } = event
    setStages(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }
  const handleExport = () => {
    setOpen(false)
  }
  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={'Export leads'}
      confirm={{
        text: 'Export',
        disabled: !stages[0] || !exportFormat,
        handleAction: () => {
          handleExport()
        }
      }}
      content={
        'Choose one or more pipeline stages. All leads from the selected stages will be included in your export'
      }
      onClose={() => setOpen(false)}
    >
      <Typography variant='formLabel' gutterBottom display='block'>
        Pipeline stages
      </Typography>
      <FormControl fullWidth>
        <Select
          multiple
          value={stages}
          displayEmpty
          onChange={handleChangeStage}
          startAdornment={
            stages.length > 0 && (
              <>
                <Chip label={stages.length} onDelete={() => setStages([])} />
                <Typography color={Color.black}>Stages</Typography>
              </>
            )
          }
          renderValue={selected => {
            if (!selected.length) {
              return <Typography color='#505C63'>Select</Typography>
            }
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: stages.length > 0 ? -116 : 0
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            classes: {
              paper: classes.selectMenuItem
            }
          }}
        >
          {PipelineStageOptions.map((option: any, index: number) => {
            return (
              <MenuItem
                key={`stage_${index}`}
                value={option.value as PipelineStage}
              >
                <FormControlLabel
                  control={
                    <Checkbox checked={stages.indexOf(option.value) > -1} />
                  }
                  label={
                    <Typography variant='body2' color={Color.black}>
                      {option.tag}
                    </Typography>
                  }
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Typography
        variant='formLabel'
        gutterBottom
        display='block'
        marginTop={3}
      >
        Format
      </Typography>
      <RadioGroup
        value={exportFormat}
        onChange={(_e: any, value: string) => {
          setExportFormat(value)
        }}
      >
        <FormControlLabel
          value={'format1'}
          control={<Radio className={classes.radioButton} />}
          label={
            <Typography variant='body1High' color={Color.grey700}>
              .csv (for Google Sheets or Excel)
            </Typography>
          }
        />
        <FormControlLabel
          className={classes.formLabel}
          value={'format2'}
          control={<Radio className={classes.radioButton} />}
          label={
            <Typography variant='body1High' color={Color.grey700}>
              .csv (for LinkedIn Recruiter)
            </Typography>
          }
        />
      </RadioGroup>
    </Dialog>
  )
}

export default ExportCandidate
