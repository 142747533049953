import React, { useContext } from 'react'
import { useMutation } from 'gql/hooks'
import {
  CANDIDATE_ACCEPTED_SUBSCRIPTION,
  CANDIDATE_MOVED_SUBSCRIPTION,
  CANDIDATE_TRANSFERRED_SUBSCRIPTION,
  CANDIDATE_REJECTED_SUBSCRIPTION
} from 'gql/subscription'
import {
  ACCEPT_CANDIDATE,
  MOVE_CANDIDATE,
  TRANSFER_CANDIDATE,
  REJECT_CANDIDATE
} from 'gql/command'
import { DocumentNode } from '@apollo/client'
import { MicroServiceContext } from './MicroService'
export type ContextData = {
  onAcceptCandidate: (input: any, candidateId: string) => void
  onRejectCandidate: (input: any, candidateId: string) => void
  onMoveCandidate: (input: any, row: any) => void
  onTransferCandidate: (input: any, row: any) => void
}

export const JobCandidateContext = React.createContext<any>({})

export const JobCandidateContextProvider = ({
  children,
  subscribeToMore,

  refetch,
  refetchCount,
  paginationState,
  setPaginationState,
  onlyItemInList
}: any): JSX.Element => {
  const [acceptCandidate] = useMutation(ACCEPT_CANDIDATE)
  const [rejectCandidate] = useMutation(REJECT_CANDIDATE)
  const [moveCandidate] = useMutation(MOVE_CANDIDATE)
  const [transferCandidate] = useMutation(TRANSFER_CANDIDATE)
  const { displayNotification } = useContext(MicroServiceContext)
  const startSubscription = (
    doc: DocumentNode,
    id: string,
    notification?: string
  ) => {
    subscribeToMore({
      document: doc,
      variables: {
        id: id
      },
      updateQuery: (_prev: any, { subscriptionData }: any) => {
        if (subscriptionData.data) {
          if (notification) displayNotification(notification)
          refetch({ after: paginationState.cursor })
          refetchCount()
          if (onlyItemInList) {
            setPaginationState({ type: 'back' })
          }
        }
      }
    })
  }

  const onAcceptCandidate = (input: any, candidateId: string) => {
    startSubscription(CANDIDATE_ACCEPTED_SUBSCRIPTION, candidateId)
    acceptCandidate(input)
  }
  const onRejectCandidate = (input: any, candidateId: string) => {
    startSubscription(CANDIDATE_REJECTED_SUBSCRIPTION, candidateId)
    rejectCandidate(input)
  }
  const onMoveCandidate = (input: any, candidateId: string) => {
    startSubscription(
      CANDIDATE_MOVED_SUBSCRIPTION,
      candidateId,
      'Candidate moved.'
    )
    moveCandidate(input)
  }

  const onTransferCandidate = (input: any, newCandidateId: string) => {
    startSubscription(
      CANDIDATE_TRANSFERRED_SUBSCRIPTION,
      newCandidateId,
      'Candidate transferred.'
    )
    transferCandidate(input)
  }

  return (
    <JobCandidateContext.Provider
      value={{
        onAcceptCandidate,
        onMoveCandidate,
        onTransferCandidate,
        onRejectCandidate
      }}
    >
      {children}
    </JobCandidateContext.Provider>
  )
}
