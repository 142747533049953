import React, { memo, useContext, useState } from 'react'
import {
  Typography,
  Button,
  Grid,
  MenuItem,
  Dialog,
  Divider,
  Link
} from '@mui/material'
import { useStyles } from './style'
import { useStyles as useStylesRequestBatch } from '../RequestBatch/style'
import { FullScreenDialogContext } from 'context/FullScreenDialog'
import { MyJobContext } from 'context/JobContext'
import { StatusDot, Color, DeleteIcon, InfoBanner } from 'components'
import { toCapitalize } from 'utils/stringHelper'
import {
  CLOSE_JOB,
  REOPEN_JOB,
  REMOVE_RECRUITER_FROM_JOB,
  REMOVE_FOLLOWER_FROM_JOB
} from 'gql/command'
import {
  REOPEN_JOB_SUBSCRIPTION,
  CLOSE_JOB_SUBSCRIPTION,
  JOB_RECRUITERS_CHANGED_SUBSCRIPTION,
  JOB_FOLLOWERS_CHANGED_SUBSCRIPTION
} from 'gql/subscription'
import { useMutation } from 'gql/hooks'
import { JobStatus, EmployerStaffAccountNode } from 'model'
import ConfirmDialog from './Dialog'
import AddRecruiterOrFollower from './AddRecruiterOrFollower'
import {
  Add as AddIcon,
  KeyboardBackspace as KeyboardBackspaceIcon
} from '@mui/icons-material'
const JobSettings = (): React.ReactElement => {
  const classes = useStyles()
  const classesRB = useStylesRequestBatch()
  const { job, startSubscription } = useContext(MyJobContext)
  const jobId = job!.id
  const { openJobSetting, setOpenJobSetting } = useContext(
    FullScreenDialogContext
  )

  const [closeJobMutation] = useMutation(CLOSE_JOB)
  const [reopenJobMutation] = useMutation(REOPEN_JOB)
  const [removeRecruiterMutation] = useMutation(REMOVE_RECRUITER_FROM_JOB, {
    successNotification: 'Recruiter removed.'
  })
  const [removeFollowerMutation] = useMutation(REMOVE_FOLLOWER_FROM_JOB, {
    successNotification: 'Follower removed.'
  })

  const [closeJobOpen, setCloseJobOpen] = useState(false)
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const [addDialogType, setAddDialogType] = useState<
    'recruiter' | 'follower' | 'edit_title' | undefined
  >(undefined)
  const closeJob = () => {
    startSubscription(CLOSE_JOB_SUBSCRIPTION)
    closeJobMutation({
      variables: {
        closeJobInput: {
          jobId: jobId
        }
      }
    })
  }
  if (!job) return <></>
  const existingAccountIds = new Set(
    job.recruiters
      .map(account => account.id)
      .concat(job.followers.map(account => account.id))
  )

  const { displayTitle, description, exampleProfiles } = job
  const isJobTitleEditable =
    description && exampleProfiles!.length > 0 ? true : false

  return (
    <>
      <Dialog
        sx={{ top: 56, zIndex: 999, boxShadow: 'none' }}
        fullScreen
        hideBackdrop
        open={openJobSetting!}
        onClose={() => {
          setOpenJobSetting!(false)
        }}
      >
        <div className={classes.jobSettingContainer}>
          <Button
            color='secondary'
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() => setOpenJobSetting!(false)}
          >
            Back
          </Button>
          <Typography variant='h1' marginTop={'32px'}>
            Job settings
          </Typography>
          <Typography
            variant='h3'
            fontWeight={500}
            marginTop={'32px'}
            marginBottom={'14px'}
          >
            Job Title
          </Typography>
          <Typography
            color={Color.black60}
            variant='body1'
            marginBottom={'16px'}
          >
            Enter a public facing job title to request leads for a role.{' '}
            <Link
              href='https://mathison.notion.site/What-types-of-jobs-does-Mathison-support-d777397973d143fab90bb82aea6f7c73'
              target='_blank'
              rel='noopener'
              sx={{
                '&:any-link, &:hover, &:-webkit-any-link, &:-webkit-any-link:hover':
                  {
                    textDecoration: 'underline'
                  },
                fontWeight: 500
              }}
            >
              Learn more about the roles we specialize in sourcing.
            </Link>
          </Typography>
          <div className={classes.statusContainer}>
            <Grid container justifyContent='space-between'>
              {displayTitle}
              <Button
                color='primary'
                disabled={!isJobTitleEditable}
                onClick={() => {
                  setAddDialogType('edit_title')
                  setOpenAddDialog(true)
                }}
              >
                Edit Job title
              </Button>
            </Grid>
            <ConfirmDialog
              closeJob={closeJob}
              closeJobOpen={closeJobOpen}
              setCloseJobOpen={setCloseJobOpen}
            />
          </div>
          {!isJobTitleEditable ? (
            <Grid container className={classesRB.infoBanner}>
              <InfoBanner
                type='info'
                fullWidth
                multiline
                sx={{
                  marginTop: '24px',
                  paddingTop: '16px',
                  paddingBottom: '16px'
                }}
                content={
                  'Request your first batch of leads to edit this Job Title'
                }
              />
            </Grid>
          ) : null}
          <Divider sx={{ marginTop: '36px', marginBottom: '36px' }} />
          <Typography
            variant='h3'
            fontWeight={500}
            marginTop={'32px'}
            marginBottom={'14px'}
          >
            Job Status
          </Typography>
          <div className={classes.statusContainer}>
            <StatusDot
              color={
                job && job.status === JobStatus.OPEN
                  ? Color.active
                  : Color.inactive
              }
            />
            {job?.status && toCapitalize(job.status)}
            <Button
              color='secondary'
              variant='outlined'
              className={classes.closeJobButton}
              onClick={() => {
                if (job && job.status === JobStatus.OPEN) {
                  setCloseJobOpen(true)
                }
                if (job && job.status === JobStatus.CLOSED) {
                  startSubscription(REOPEN_JOB_SUBSCRIPTION)
                  reopenJobMutation({
                    variables: {
                      reopenJobInput: {
                        jobId: jobId
                      }
                    }
                  })
                }
              }}
            >
              {job && job.status === JobStatus.OPEN ? 'Close job' : 'Open job'}
            </Button>
            <ConfirmDialog
              closeJob={closeJob}
              closeJobOpen={closeJobOpen}
              setCloseJobOpen={setCloseJobOpen}
            />
          </div>
          <Divider sx={{ marginTop: '36px', marginBottom: '36px' }} />
          <Grid container justifyContent='space-between'>
            <Typography variant='h3' fontWeight={500} marginBottom={'14px'}>
              Recruiters
            </Typography>
            <Button
              color='primary'
              startIcon={<AddIcon />}
              onClick={() => {
                setAddDialogType('recruiter')
                setOpenAddDialog(true)
              }}
            >
              Add recruiters
            </Button>
          </Grid>
          <Typography
            color={Color.black60}
            variant='body1'
            marginBottom={'16px'}
          >
            Recruiters get email notifications when batches are complete and are
            who we contact when we have questions about requirements.{' '}
          </Typography>
          {job?.recruiters.map(
            (recruiter: EmployerStaffAccountNode, index: number) => (
              <MenuItem
                key={`recruiter-${index}`}
                className={classes.menuItem}
                disableTouchRipple
              >
                {recruiter.user.name}
                <Button
                  className='removeButton'
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    startSubscription(JOB_RECRUITERS_CHANGED_SUBSCRIPTION)
                    removeRecruiterMutation({
                      variables: {
                        input: {
                          jobId: jobId,
                          employerStaffAccountId: recruiter.id
                        }
                      }
                    })
                  }}
                >
                  <Typography variant='body2' lineHeight='20px'>
                    Remove
                  </Typography>
                </Button>
              </MenuItem>
            )
          )}

          {/* Seems like a mui bug, so using inline style here */}
          <Divider style={{ marginTop: 38, marginBottom: 38 }} />
          <Grid container justifyContent='space-between'>
            <Typography variant='h3' fontWeight={500} marginBottom={'14px'}>
              Followers
            </Typography>
            <Button
              color='primary'
              startIcon={<AddIcon />}
              onClick={() => {
                setAddDialogType('follower')
                setOpenAddDialog(true)
              }}
            >
              Add followers
            </Button>
          </Grid>
          <Typography
            color={Color.black60}
            variant='body1'
            marginBottom={'16px'}
          >
            Followers get in-app notifications when batches are complete and see
            stats for this job on their dashboard.
          </Typography>
          {job?.followers.map(
            (follower: EmployerStaffAccountNode, index: number) => (
              <MenuItem
                key={`follower-${index}`}
                className={classes.menuItem}
                disableTouchRipple
              >
                {follower.user.name}
                <Button
                  className='removeButton'
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    startSubscription(JOB_FOLLOWERS_CHANGED_SUBSCRIPTION)
                    removeFollowerMutation({
                      variables: {
                        input: {
                          jobId: jobId,
                          employerStaffAccountId: follower.id
                        }
                      }
                    })
                  }}
                >
                  <Typography variant='body2' lineHeight='20px'>
                    Remove
                  </Typography>
                </Button>
              </MenuItem>
            )
          )}
        </div>
        {addDialogType && (
          <AddRecruiterOrFollower
            open={openAddDialog}
            setOpen={setOpenAddDialog}
            type={addDialogType}
            existingAccountIds={existingAccountIds}
          />
        )}
      </Dialog>
    </>
  )
}

export default memo(JobSettings)
