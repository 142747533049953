import { Typography, Grid } from '@mui/material'
import React, { memo } from 'react'
import { Color } from 'components'
import { formatDateInterval } from 'utils/timeFormat'
interface Props {
  workRecord: any
  key: number
}

const WorkExperienceBuilder = (props: Props): React.ReactElement => {
  const { workRecord } = props

  return (
    <Grid item xs={12}>
      <Typography
        variant='body2Medium'
        color={Color.black}
        gutterBottom
        component='span'
      >
        {workRecord.job},{' '}
      </Typography>
      <Typography
        variant='body2'
        color={Color.grey700}
        lineHeight='20px'
        component='span'
      >
        {workRecord.organization}{' '}
      </Typography>
      <Typography
        variant='body2'
        color={Color.grey700}
        gutterBottom
        component='span'
      >
        {formatDateInterval(workRecord.startDate, workRecord.endDate)}
      </Typography>
    </Grid>
  )
}

export default memo(WorkExperienceBuilder)
