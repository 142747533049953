import { useMemo } from 'react'

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const Client = (url: string, key?: string) => {
  return useMemo(() => {
    const httpLink = createHttpLink({
      uri: url
    })
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: key ? `Bearer ${key}` : undefined
        }
      }
    })
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    })
    // eslint-disable-next-line
  }, [])
}
export default Client
