import React, { useState, useContext, useEffect } from 'react'
import { ApolloError } from '@apollo/client'
import { useLazyQuery, useMutation } from 'gql/hooks'
import { Dialog } from 'components'
import useDebounceInput from 'hooks/debounce'
import { EmployerStaffAccount } from 'model'
import { GET_EMPLOYER_STAFF_ACCOUNTS } from 'gql/query'
import { MyJobContext } from 'context/JobContext'
import {
  ADD_RECRUITER_TO_JOB,
  ADD_FOLLOWER_TO_JOB,
  CHANGE_JOB_REQUIREMENTS
} from 'gql/command'
import {
  JOB_RECRUITERS_CHANGED_SUBSCRIPTION,
  JOB_FOLLOWERS_CHANGED_SUBSCRIPTION,
  JOB_REQUIREMENTS_CHANGED_SUBSCRIPTION
} from 'gql/subscription'
import { Typography, MenuItem, Autocomplete, TextField } from '@mui/material'
import { useStyles } from './style'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  type: 'recruiter' | 'follower' | 'edit_title'
  existingAccountIds: Set<string>
}

const AddRecruiterOrFollower = ({
  open,
  setOpen,
  type,
  existingAccountIds
}: Props): React.ReactElement => {
  const classes = useStyles()
  const { debouncedSearchTerm, setSearchTerm, searchTerm } = useDebounceInput()
  const [addRecruiterMutation] = useMutation(ADD_RECRUITER_TO_JOB, {
    successNotification: 'Recruiter added.'
  })
  const [addFollowerMutation] = useMutation(ADD_FOLLOWER_TO_JOB, {
    successNotification: 'Follower added.'
  })

  /**
   * 🚨 Hack Warning 🚨
   *
   * Here, we are reusing the `CHANGE_JOB_REQUIREMENTS` mutation to only edit the `displayTitle`
   * field. The original mutation is intended for the "Request Leads" flow. It validates the `displayTitle`,
   * `description` and `exampleProfiles` fields on the BE. In other words, this reuse is not as the mutation
   * was originally intended. This dual-use workaround may lead to unexpected behavior so this implementation
   * risk was warned against. Creating a separate mutation dedicated to edit the `displayTitle` field is the
   * recommended approach.
   *
   * The risk here is acknowledged. We are also in an initiative to move the Jobs product quickly into
   * Maintenance Mode.
   *
   * Let's NOT build on top of this!
   */
  const [editJobTitleMutation] = useMutation(CHANGE_JOB_REQUIREMENTS, {
    onErrorAction: (error: ApolloError) => {
      if (error?.message) {
        console.log(error!.message) // Keep these console logs. See Warning above.
        console.log('Error: Check Job Mutation')
        return true
      }
    },
    successNotification: 'Job Title saved.'
  })

  const { job, startSubscription } = useContext(MyJobContext)
  const jobId = job!.id
  const [selectedAccount, setSelectedAccount] =
    useState<EmployerStaffAccount | null>(null)
  const [getEmployerStaffAccounts, { data }] = useLazyQuery(
    GET_EMPLOYER_STAFF_ACCOUNTS,
    {
      variables: {
        first: 10,
        searchTerm: debouncedSearchTerm,
        jobsAccessLevels: ['JOBS_LEAD', 'BASIC_JOBS_ACCESS']
      }
    }
  )
  const {
    displayTitle: jobDisplayTitle,
    description: jobDescription,
    exampleProfiles: jobExampleProfiles
  } = job!
  const [jobTitle, setJobTitle] = useState(jobDisplayTitle)

  useEffect(() => {
    if (debouncedSearchTerm) {
      getEmployerStaffAccounts()
    }
  }, [debouncedSearchTerm, getEmployerStaffAccounts])

  const employerStaffAccounts = data ? data.employerStaffAccounts.edges : []
  const onAddRecruiter = (accountId: string) => {
    setSelectedAccount(null)
    startSubscription(JOB_RECRUITERS_CHANGED_SUBSCRIPTION)
    addRecruiterMutation({
      variables: {
        input: {
          employerStaffAccountId: accountId,
          jobId: jobId
        }
      }
    })
  }
  const onAddFollower = (accountId: string) => {
    setSelectedAccount(null)
    startSubscription(JOB_FOLLOWERS_CHANGED_SUBSCRIPTION)
    addFollowerMutation({
      variables: {
        input: {
          employerStaffAccountId: accountId,
          jobId: jobId
        }
      }
    })
  }
  const onEditJobTitle = (title: string) => {
    startSubscription(JOB_REQUIREMENTS_CHANGED_SUBSCRIPTION)
    editJobTitleMutation({
      variables: {
        input: {
          jobId: jobId,
          displayTitle: title,
          description: jobDescription || '',
          exampleProfiles: jobExampleProfiles || []
        }
      }
    })
  }

  useEffect(() => {
    if (type) setSelectedAccount(null)
  }, [type])

  const dialogInformation = (type: any) => {
    switch (type) {
      case 'recruiter':
        return {
          title: 'Add recruiter',
          confirm: 'Add',
          content:
            'Choose the staff member that you’d like to add as a recruiter.'
        }
      case 'follower':
        return {
          title: 'Add follower',
          confirm: 'Add',
          content:
            'Choose the staff member that you’d like to add as a follower.'
        }
      case 'atsJobId':
        return { title: 'Add ATS Job ID', confirm: 'Save', content: ' ' }
      default:
        return { title: 'Edit Job Id', confirm: 'Save', content: ' ' }
    }
  }
  const dialogInfo = dialogInformation(type)

  return (
    <Dialog
      open={open}
      setOpen={setOpen}
      title={dialogInfo.title}
      confirm={
        ['recruiter', 'follower'].includes(type)
          ? {
              text: 'Add',
              disabled: !selectedAccount,
              handleAction: () => {
                type === 'recruiter'
                  ? onAddRecruiter(selectedAccount!.node.id)
                  : onAddFollower(selectedAccount!.node.id)
              }
            }
          : {
              text: 'Save',
              disabled: jobTitle?.length < 1,
              handleAction: () => {
                onEditJobTitle(jobTitle)
              }
            }
      }
      content={
        type === 'recruiter'
          ? 'Choose the staff member that you’d like to add as a recruiter.'
          : type === 'follower'
          ? 'Choose the staff member that you’d like to add as a follower.'
          : ''
      }
      onClose={() => setOpen(false)}
    >
      {type === 'edit_title' ? (
        <div className={classes.autoComplete}>
          <Typography variant='formLabel' gutterBottom>
            Title
          </Typography>
          <TextField
            className={classes.input}
            variant='outlined'
            fullWidth
            placeholder='Job title'
            defaultValue={jobDisplayTitle}
            value={jobTitle}
            onChange={(e: any) => setJobTitle(e.target.value)}
          />
        </div>
      ) : (
        <Autocomplete
          id='employerSaffAccounts'
          fullWidth
          options={employerStaffAccounts}
          getOptionDisabled={(option: any) =>
            existingAccountIds.has(option?.node.id)
          }
          className={classes.autoComplete}
          value={selectedAccount}
          onChange={(e: any, value: any) => {
            if (value && value.node) {
              setSelectedAccount(value)
            } else {
              setSelectedAccount(null)
            }
          }}
          freeSolo
          autoHighlight
          getOptionLabel={(option: any) => {
            if (option.node) {
              return option?.node?.user.name
            }
            return ''
          }}
          renderOption={(props: any, option: any) => {
            props.key = option.node.id
            return (
              <MenuItem {...props}>
                <Typography variant='body2' color='black'>
                  {option?.node?.user.name}
                </Typography>
              </MenuItem>
            )
          }}
          renderInput={params => {
            const newParams = { ...params }
            newParams.inputProps.style = { padding: 0 }
            return (
              <>
                <Typography variant='formLabel' gutterBottom>
                  Staff member
                </Typography>
                <TextField
                  {...newParams}
                  className={classes.input}
                  variant='outlined'
                  placeholder='Start typing to select'
                  value={searchTerm}
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                />
              </>
            )
          }}
        />
      )}
    </Dialog>
  )
}

export default AddRecruiterOrFollower
