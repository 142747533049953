import React, { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { RightArrowIcon } from '../../Icons'
import { styled } from '@mui/material/styles'

const StyledButton = styled(Button)<ButtonProps>(({ variant, theme }) => ({
  fontFamily: '"D-DIN", sans-serif',
  fontWeight: 700,
  fontSize: 16,
  letterSpacing: '0.1px',
  ...(variant && {
    color:
      variant === 'outlined'
        ? theme.palette.common.black
        : theme.palette.common.white,
    backgroundColor:
      variant === 'contained'
        ? theme.palette.common.black
        : theme.palette.common.white,
    textTransform: variant === 'outlined' ? 'capitalize' : 'none',
    border: `1px solid ${theme.palette.common.black}`,
    padding: variant === 'outlined' ? '12px 15px' : '15px 40px 15px 70px',
    transition: 'transform 200ms ease',
    '& .MuiSvgIcon-root': {
      fontSize: '25px'
    },
    '& .MuiButton-endIcon': {
      marginLeft: '20px'
    },
    '& .mathison-icon': {
      display: variant === 'outlined' ? 'block' : 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
      transform: variant === 'contained' ? 'translateY(-4px)' : 'none',
      boxShadow:
        variant === 'contained' ? '4px 4px 10px rgb(0 0 0 / 25%)' : 'none',
      '& .mathison-icon': {
        stroke: theme.palette.common.white
      }
    }
  })
}))

// eslint-disable-next-line react/prop-types
const CtaButton: FC<ButtonProps> = React.forwardRef((props, ref) => {
  // eslint-disable-next-line react/prop-types
  const { variant } = props
  return (
    <StyledButton
      ref={ref}
      variant={variant}
      endIcon={<RightArrowIcon />}
      {...props}
      disableElevation
    />
  )
})
CtaButton.displayName = 'CtaButton'

export default CtaButton
