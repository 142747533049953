import { createContext } from 'react'
import { Job } from 'model'
import { DocumentNode } from '@apollo/client'

type MyJobContextData = {
  job: Job | undefined
  maskingFlag: boolean
  setMaskingFlag: React.Dispatch<any>
  handleToggleMasking: () => void
  startSubscription: (doc: DocumentNode, onComplete?: () => void) => void
  numRemainingCreditsForMonth: number
  openNoBatchLeft: boolean
  setOpenNoBatchLeft: React.Dispatch<any>
  isBatchAlreadyRequested: boolean
}
export const MyJobContext = createContext<MyJobContextData>({
  job: undefined,
  maskingFlag: false,
  setMaskingFlag: () => {},
  handleToggleMasking: () => {},
  startSubscription: () => {},
  numRemainingCreditsForMonth: 0,
  openNoBatchLeft: false,
  setOpenNoBatchLeft: () => {},
  isBatchAlreadyRequested: false
})
export default MyJobContext
