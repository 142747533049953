import { Profile } from 'model/profile'

export const IMG_LOGO_PARAMS = {
  w: 420,
  h: 420,
  ar: '1:1',
  fit: 'crop',
  mask: 'ellipse'
}

export const SocialMediaTypes = [
  {
    tag: 'LinkedIn',
    value: 'LINKEDIN'
  },
  {
    tag: 'Instagram',
    value: 'INSTAGRAM'
  },
  {
    tag: 'GitHub',
    value: 'GITHUB'
  },
  {
    tag: 'Twitter',
    value: 'TWITTER'
  }
]

export const INITIAL_PROFILE_DATA = {
  profile: {
    id: '',
    nanoid: '',
    avatarBlock: { url: '', source: '' },
    basicInfoBlock: { name: '', about: '' },
    locationsBlock: { location: { city: '', country: '', state: '' } },
    workHistoryBlock: {
      current: [
        {
          job: '',
          location: '',
          description: '',
          organization: '',
          startDate: '',
          endDate: ''
        }
      ],
      workRecords: [
        {
          job: '',
          location: '',
          description: '',
          organization: '',
          startDate: '',
          endDate: ''
        }
      ]
    },
    educationHistoryBlock: {
      educationRecords: [{ diploma: '', school: '' }]
    },
    skillsBlock: { skills: [''] },
    languagesBlock: { languages: [] },
    socialMediaBlock: {
      otherAccounts: [],
      primaryLinkedinUrl: ''
    },
    affiliationsBlock: { affiliations: [] }
  } as Profile
}
