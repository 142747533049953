export const IMG_LOGO_PARAMS = {
  w: 88 * 3,
  h: 88 * 3,
  ar: '1:1',
  fit: 'crop',
  mask: 'ellipse'
}

export const LOGIN_STRATEGY = {
  DOMAIN_LOCK: {
    title: 'Domain lock',
    subtitle:
      'Anyone with an email address at your chosen domain can log in. All new staff are assigned as a basic user, you can edit permissions later.'
  },
  INVITATION_ONLY: {
    title: 'Invite only',
    subtitle:
      'Only staff you invite will be able to access your organization on Mathison. The account must be created using the email at which the invitation was sent.'
  }
}

export const ATS_INTEGRATION = {
  subtitle:
    'With your ATS account connected, leads will automatically be sent from your ‘To Contact’ list to your ATS platform (Greenhouse, Bamboo HR, Jobvite, Lever, and more) so candidates can move faster through the hiring process.'
}

export type PresignedFile = {
  url?: string
  file: File
}

export const INITIAL_EMPLOYER = {
  myEmployer: {
    mainContact: { email: '', name: '', phone: '' },
    authenticationSettings: {
      domain: ''
    },
    id: '',
    name: '',
    impactManager: { email: '', id: '', name: '' }
  }
}
