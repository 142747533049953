import React, { useEffect } from 'react'
import clsx from 'clsx'
import { useParams } from 'react-router-dom'
import { useStyles } from './style'
import { GET_PROFILE } from 'gql/query'
import { useLazyQuery } from 'gql/hooks'
import { IMG_LOGO_PARAMS, INITIAL_PROFILE_DATA } from './constants'
import { Profile } from 'model/profile'
import {
  GeneralInfo,
  Affiliations,
  About,
  Experience,
  Education,
  Skills,
  Languages
} from './ProfileFields'
import NotFound from 'pages/NotFound'

interface Props {
  profileData?: Profile
  fixedWidth?: boolean
  currentState?: string
}

const SelectedProfile = ({
  profileData,
  fixedWidth,
  currentState
}: Props): React.ReactElement => {
  const classes = useStyles()
  const params = useParams()
  const { nanoid } = params
  const [getProfile, { data = INITIAL_PROFILE_DATA, error }] = useLazyQuery(
    GET_PROFILE,
    {
      variables: {
        nanoid: nanoid,
        imgixParams: IMG_LOGO_PARAMS
      }
    }
  )

  useEffect(() => {
    if (!profileData) getProfile()
    // eslint-disable-next-line
  }, [])

  if (error) return <NotFound />
  const profile = profileData ? profileData : (data?.profile as Profile)
  return (
    <div
      className={clsx(classes.sectionContainer, {
        [classes.fixedWidth]: fixedWidth
      })}
    >
      <GeneralInfo
        profileId={profile.id}
        basicInfoBlock={profile.basicInfoBlock}
        currentJob={profile.workHistoryBlock?.current}
        location={profile.locationsBlock?.location}
        avatarBlock={profile.avatarBlock}
        currentState={currentState}
        nanoid={profile.nanoid}
      />
      {profile.affiliationsBlock?.affiliations[0] && (
        <Affiliations affiliations={profile.affiliationsBlock.affiliations} />
      )}
      <About
        about={profile.basicInfoBlock?.about}
        socialMedias={profile.socialMediaBlock}
      />
      {profile.workHistoryBlock?.workRecords && (
        <Experience workRecords={profile.workHistoryBlock.workRecords} />
      )}
      {profile.educationHistoryBlock?.educationRecords && (
        <Education
          educationRecords={profile.educationHistoryBlock.educationRecords}
        />
      )}
      {profile.skillsBlock?.skills && (
        <Skills skills={profile.skillsBlock.skills} />
      )}
      {profile.languagesBlock?.languages && (
        <Languages languagesBlock={profile.languagesBlock.languages} />
      )}
    </div>
  )
}

export default SelectedProfile
