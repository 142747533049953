import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

export const init = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      release: `ketchup@${process.env.REACT_APP_VERSION_NUMBER}`,
      environment: process.env.REACT_APP_RELEASE_LEVEL,
      tracesSampleRate: 1.0,
      ignoreErrors: ['ResizeObserver loop limit exceeded']
    })
  }
}
