import {
  Typography,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton
} from '@mui/material'
import React, { memo, useContext } from 'react'
import { useStyles } from './style'
import { JOB_TYPE_OPTIONS, learnMoreUrl } from './constant'
import { Color, InfoBanner } from 'components'
import { RequestBatchContext } from 'context/RequestBatch'
import { FullScreenDialogContext } from 'context/FullScreenDialog'
import { openInNewTab } from 'utils/openInNewTab'
import { Close as CloseIcon } from '@mui/icons-material'

interface Props {
  step: number
  setStep: (step: number) => void
}
const JobType = ({ step, setStep }: Props): React.ReactElement => {
  const classes = useStyles()
  const { setOpenBatchRequest } = useContext(FullScreenDialogContext)
  const { requestBatchInput, setRequestBatchInput } =
    useContext(RequestBatchContext)
  return (
    <Grid className={classes.jobType}>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        marginBottom={2}
      >
        <Typography variant='h1' lineHeight='46px' color={Color.black}>
          What kind of job is this?
        </Typography>
        <IconButton
          onClick={() => setOpenBatchRequest!(false)}
          className='closeButton'
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid container className={classes.infoBanner}>
        <InfoBanner
          type='info'
          fullWidth
          multiline
          content={
            <>
              We currently don’t offer support for internships or entry-level
              roles.{'\n'}
              <Typography
                variant='body2Medium'
                color={Color.black87}
                className='bannerText'
                onClick={() => openInNewTab(learnMoreUrl)}
              >
                Learn more
              </Typography>{' '}
              about the types of jobs we support.
            </>
          }
        />
      </Grid>
      {JOB_TYPE_OPTIONS.map((item: any, index: number) => {
        return (
          <ListItem
            button
            key={`job_type-${index}`}
            className={classes.selection}
            classes={{ selected: classes.selected }}
            selected={requestBatchInput.jobType === item.value}
            onClick={() =>
              setRequestBatchInput({
                type: 'changeJobtype',
                value: item.value
              })
            }
          >
            <ListItemText
              primary={
                <Typography
                  variant='h4'
                  fontWeight={'bold'}
                  marginBottom={'4px'}
                >
                  {item.tag}
                </Typography>
              }
              secondary={
                <Typography
                  variant='body2'
                  lineHeight={'20px'}
                  color={Color.black75}
                >
                  {item.description}
                </Typography>
              }
            />
          </ListItem>
        )
      })}
      <Grid container justifyContent='flex-end' paddingTop={2}>
        <Button
          variant='contained'
          className={classes.nextButton}
          disabled={!requestBatchInput.jobType}
          onClick={() => setStep(step + 1)}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  )
}

export default memo(JobType)
