import React, { useState, useContext } from 'react'
import { useStyles } from './style'
import JobMenu from './JobMenu'
import { MoreVert as MoreVertIcon, PersonAddAltOutlined} from '@mui/icons-material'
import { Chip, Color, ExportIcon, ViewIcon } from 'components'
import { BatchContent } from './constants'
import {
  Grid,
  Typography,
  Button,
  ButtonProps,
  IconButton
} from '@mui/material'
import { MyJobContext } from 'context/JobContext'
import ExportCandidate from './ExportCandidate'
import { styled } from '@mui/material/styles'
import { JobStatus } from 'model'
import { EmployerStaffAccountJobsAccessLevel } from 'model/organization'
import { MicroServiceContext } from 'context/MicroService'
import { FullScreenDialogContext } from 'context/FullScreenDialog'

interface MaskButtonProps extends ButtonProps {
  maskingFlag?: boolean
}

const MaskButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'maskingFlag'
})<MaskButtonProps>(({ maskingFlag }) => ({
  width: '168px',
  height: '32px',
  marginRight: '24px',
  fontWeight: 500,
  color: maskingFlag ? Color.white : Color.grey700,
  backgroundColor: maskingFlag ? Color.black75 : 'none',
  '&:hover': {
    backgroundColor: maskingFlag ? Color.black75 : '#E7ECEE'
  }
}))

const Header = (): React.ReactElement => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState()
  const { job, maskingFlag, handleToggleMasking, numRemainingCreditsForMonth, setOpenNoBatchLeft, isBatchAlreadyRequested } = useContext(MyJobContext)
  let batchLabel
  if (job?.currentBatch) {
    const currBatch = BatchContent.find(
      (batch: any) => batch.value === job?.currentBatch.status
    )
    batchLabel = currBatch?.tag
  } else {
    batchLabel = 'Not started'
  }

  const { setOpenBatchRequest } = useContext(
    FullScreenDialogContext
  )

  const { currentAccount } = useContext(MicroServiceContext)

  const isJobsLead =
    currentAccount?.jobsAccess === EmployerStaffAccountJobsAccessLevel.JOBS_LEAD
  const disableRequestBatch = isBatchAlreadyRequested || job?.status === JobStatus.CLOSED

  const disabled = job?.candidates.length === 0
  const [openExport, setOpenExport] = useState(false)
  return (
    <>
      <ExportCandidate open={openExport} setOpen={setOpenExport} />
      <JobMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      <Grid container alignItems='flex-start' padding={'36px'}>
        <Grid item>
          <Typography
            variant='h1'
            color={Color.black}
            fontWeight='bold'
            marginBottom={2}
          >
            {job?.displayTitle}
          </Typography>
          {job?.internalTitle && (
            <Typography variant='button' color={Color.black80}>
              {job?.internalTitle}
            </Typography>
          )}
        </Grid>
        <Grid item paddingLeft={3} paddingTop={'12px'}>
          <Chip label={batchLabel}></Chip>
        </Grid>
        <Grid item container xs justifyContent='flex-end' paddingTop={'12px'}>
          <MaskButton
            disableRipple
            disabled={disabled}
            endIcon={<ViewIcon disabled={maskingFlag} />}
            onClick={() => handleToggleMasking()}
            maskingFlag={maskingFlag}
          >
            {maskingFlag ? 'Unmask leads' : 'Mask leads'}
          </MaskButton>
          {isJobsLead && (
            <MaskButton
              disabled={disableRequestBatch}
              onClick={() => {
                setAnchorEl(undefined)
                numRemainingCreditsForMonth < 1
                  ? setOpenNoBatchLeft(true)
                  : setOpenBatchRequest!(true)
              }}
              endIcon={<PersonAddAltOutlined/>}
            >
              <Typography variant='body1'>Request Leads</Typography>
            </MaskButton>
          )}
          {
            // this functionality is temporarily disabled for the upcoming launch
            false && (
              <MaskButton
                disableRipple
                disabled={disabled}
                endIcon={<ExportIcon />}
                sx={{
                  color: disabled ? '#A2ABB1' : Color.grey700
                }}
              >
                Export leads
              </MaskButton>
            )
          }
          <IconButton
            className={classes.expendButton}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget)
            }}
          >
            <MoreVertIcon fontSize='inherit' />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

export default Header
