import React, { useContext, useState } from 'react'
import {
  Typography,
  Grid,
  Divider,
  Button,
  TextField,
  Stack,
  CircularProgress
} from '@mui/material'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Error as ErrorIcon
} from '@mui/icons-material'
import {
  Color,
  EditIcon,
  InfoBanner,
  Menu,
  AddIcon,
  InfoIcon,
  DialogWithoutAction
} from 'components'
import { INVITE_EMPLOYER_STAFF_ACCOUNTS } from 'gql/command'
import { useMutation } from 'gql/hooks'
import useStyles from './style'
import { MicroServiceContext } from 'context/MicroService'

interface Props {
  isInEditMode: boolean
  setIsInEditMode: (bool: boolean) => void
  numEmployerStaffAccounts: number
  numOpenSeats: number
  numUsedSeats: number
}

const TableTitle = (props: Props) => {
  const {
    isInEditMode,
    setIsInEditMode,
    numEmployerStaffAccounts,
    numOpenSeats,
    numUsedSeats
  } = props
  const [manageAnchorEl, setManageAnchorEl] = useState<
    undefined | HTMLElement
  >()
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false)
  const { displayNotification } = useContext(MicroServiceContext)
  const [inviteStaff] = useMutation(INVITE_EMPLOYER_STAFF_ACCOUNTS)
  const [errorEmails, setErrorEmails] = useState<string[] | undefined>()
  const [loading, setLoading] = useState<boolean>(false)
  const [emails, setEmails] = useState('')
  const classes = useStyles()

  const handleSubmitInvite = async () => {
    setLoading(true)
    const emailList = emails.split('\n')
    const {
      data: {
        inviteEmployerStaffAccounts: { failedEmails }
      }
    } = await inviteStaff({
      variables: { emails: emailList.filter(email => email) }
    })
    setEmails('')
    setLoading(false)
    if (failedEmails.length > 0) {
      setErrorEmails(failedEmails)
    } else {
      setInviteDialogOpen(false)
      setErrorEmails(undefined)
      displayNotification('Invite sent')
    }
  }
  return (
    <>
      <DialogWithoutAction
        open={inviteDialogOpen}
        setOpen={setInviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        title={errorEmails ? 'Invalid email address' : 'Invite staff'}
        content={
          !errorEmails
            ? 'Add the email addresses of the people you want to invite to your organization. It may take a few hours for invitations to arrive.'
            : undefined
        }
        Action={() => (
          <Stack direction='row' spacing={1}>
            <Button
              color='secondary'
              className={classes.button}
              onClick={() => setInviteDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              startIcon={loading && <CircularProgress size={20} />}
              variant='contained'
              disabled={loading || !emails}
              className={classes.button}
              onClick={() => handleSubmitInvite()}
            >
              Send
            </Button>
          </Stack>
        )}
      >
        {errorEmails ? (
          <Grid
            item
            className={classes.errorBanner}
            container
            flexDirection='column'
            wrap='nowrap'
          >
            <Typography variant='body1' color={Color.black}>
              Check the following email address(es) for errors and reenter the
              correct version(s) below. If that still doesn’t work, contact your
              Impact Manager for help.
            </Typography>
            <Grid
              container
              flexDirection='column'
              maxHeight='120px'
              flexWrap='nowrap'
              marginTop='3px'
              className={classes.emailList}
            >
              {errorEmails.map((email: string, index: number) => (
                <Grid
                  key={`item_${index}`}
                  item
                  marginTop='9px'
                  container
                  alignItems='center'
                >
                  <ErrorIcon sx={{ color: '#DC1849' }} fontSize='small' />
                  <Typography
                    key={`invalid_${email}`}
                    component='span'
                    variant='body1'
                    color={Color.black}
                    marginLeft='13px'
                  >
                    {email}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            className={classes.banner}
            container
            flexDirection='row'
            wrap='nowrap'
            marginBottom='16px'
          >
            <InfoIcon filled className={classes.infoIcon} />
            <Typography variant='body1' color={Color.black80}>
              New staff are invited as Basic Users and don’t count against your
              seats. You can edit roles once they’ve created an account.
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} paddingBottom='8px'>
          <Typography variant='formLabel' marginBottom='5px'>
            Email addresses
          </Typography>
          <Typography variant='body2' color={Color.black60}>
            Separate email addresses onto new lines
          </Typography>
        </Grid>
        <Grid item xs={12} paddingBottom='16px'>
          <TextField
            multiline
            fullWidth
            value={emails}
            inputProps={{ className: classes.input }}
            rows={3}
            maxRows={50}
            onChange={e => setEmails(e.target.value)}
            placeholder={
              errorEmails ? undefined : 'name@company.com\nname1@company.com'
            }
          ></TextField>
          {!errorEmails && (
            <Typography
              component='p'
              variant='caption'
              color={Color.black60}
              textAlign='right'
            >
              50 lines max
            </Typography>
          )}
        </Grid>
      </DialogWithoutAction>
      <Grid position='relative' width='100%'>
        {isInEditMode && (
          <InfoBanner
            type='info'
            sx={{
              borderRadius: '10px',
              padding: '24px 14px',
              width: 'calc(100% + 24px)'
            }}
            content={
              <>
                <Typography variant='body2' color={Color.grey700}>
                  You&apos;re in editing mode
                </Typography>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => {
                    setIsInEditMode(false)
                  }}
                  sx={{ position: 'absolute', right: -8, top: 16 }}
                >
                  Stop editing
                </Button>
              </>
            }
          />
        )}
        <Grid container item margin='32px auto 32px 16px'>
          <Grid item xs={6} container spacing={3}>
            <Grid
              item
              xs={3}
              sx={{ marginLeft: '8px', width: 140, paddingTop: '0px' }}
            >
              <Typography variant='body1' color={Color.black60}>
                Staff members
              </Typography>
              <Typography variant='h2' color={Color.black80} marginTop={'16px'}>
                {numEmployerStaffAccounts}
              </Typography>
            </Grid>
            <Divider orientation='vertical' flexItem />
            <Grid item xs={3} sx={{ width: 140, paddingTop: 0 }}>
              <Typography variant='body1' color={Color.black60}>
                Seats in use
              </Typography>
              <Typography variant='h2' color={Color.black80} marginTop={'16px'}>
                {numUsedSeats}
              </Typography>
            </Grid>
            <Divider orientation='vertical' flexItem />
            <Grid item xs={3} sx={{ width: 140, paddingTop: 0 }}>
              <Typography variant='body1' color={Color.black60}>
                Seats available
              </Typography>
              <Typography variant='h2' color={Color.black80} marginTop={'16px'}>
                {numOpenSeats}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs>
            <Button
              variant='outlined'
              color='secondary'
              onClick={(e: any) => {
                setManageAnchorEl(e.currentTarget)
              }}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                width: 140,
                height: 36,
                margin: '32px 12px auto auto',
                display: 'flex'
              }}
            >
              Manage
            </Button>
            <Menu
              anchorEl={manageAnchorEl}
              setAnchorEl={setManageAnchorEl}
              items={[
                {
                  label: 'Invite staff',
                  onClick: () => {
                    setInviteDialogOpen(true)
                  },
                  icon: <AddIcon />
                },
                {
                  label: 'Edit permissions',
                  onClick: () => {
                    setIsInEditMode(true)
                  },
                  icon: <EditIcon />
                }
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default TableTitle
