import React, { useState } from 'react'
import clsx from 'clsx'
import { useStyles } from '../style'
import { Color, Paper } from 'components'
import { SocialMedia, SocialMediaBlock } from 'model/profile'
import { SocialMediaTypes } from '../constants'
import { openInNewTab } from 'utils/openInNewTab'
import { CardContent, Typography, Divider, Grid } from '@mui/material'

interface Props {
  about?: string
  socialMedias: SocialMediaBlock
}

const About = ({ about, socialMedias }: Props): React.ReactElement => {
  const classes = useStyles()
  const [hidden, setHidden] = useState(true)
  const getSocialMediaUrl = (type: string) => {
    if (type === 'LINKEDIN') {
      return socialMedias?.primaryLinkedinUrl
    }
    const accounts = socialMedias?.otherAccounts as SocialMedia[]
    const result = accounts.find(account => account.type === type)
    return result?.url
  }

  return (
    <Paper className={classes.paper}>
      <CardContent className={classes.about}>
        <Typography
          variant='h4'
          color={Color.black87}
          paddingBottom={3}
          fontWeight='500'
        >
          About
        </Typography>
        {about && (
          <>
            <Typography variant='subheading' color={Color.black60}>
              BIO
            </Typography>
            <Typography
              id='about'
              variant='body2'
              gutterBottom
              lineHeight='20px'
              paddingTop={2}
              color={Color.black}
              className={clsx({
                [classes.seeLess]: hidden
              })}
            >
              {about}
            </Typography>

            {document.getElementById('about')?.scrollHeight! > 76 && (
              <Typography
                color='primary'
                variant='body2Medium'
                onClick={() => setHidden(!hidden)}
                className={classes.seeMoreButton}
              >
                {hidden ? 'See more' : 'See less'}
              </Typography>
            )}

            <Divider />
          </>
        )}
        <Typography variant='subheading' color={Color.black60}>
          SOCIAL
        </Typography>
        <Grid container marginTop={2} width={'288px'}>
          {SocialMediaTypes.map((type: any) => {
            const url = getSocialMediaUrl(type.value)
            return (
              <Grid
                item
                key={type.value}
                width='144px'
                display={url ? '' : 'none'}
                paddingBottom='12px'
              >
                <Typography
                  variant='body1Medium'
                  onClick={() => url && openInNewTab(url)}
                  className='socialMedia'
                >
                  {type.tag}
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      </CardContent>
    </Paper>
  )
}

export default About
