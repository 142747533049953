import React from 'react'
import {
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button
} from '@mui/material'
import clsx from 'clsx'

import { toCapitalize } from 'utils/stringHelper'
import { Color } from 'components'
import { useStyles } from './style'
interface Props {
  anchorEl?: HTMLElement
  setAnchorEl: (_e?: HTMLElement) => void
  items: string[]
  selectedItems: any[]
  updateSelectedItems: any
}

const PopupSelectList = (props: Props) => {
  const { anchorEl, setAnchorEl, items, selectedItems, updateSelectedItems } =
    props
  const classes = useStyles()

  const handleClose = () => {
    setAnchorEl!(undefined)
  }

  return (
    <Menu
      id='simple-menu'
      anchorEl={anchorEl}
      keepMounted
      classes={{ paper: classes.paper }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <div className={classes.menuHeader}>
        <Button
          onClick={() => {
            updateSelectedItems.clear()
          }}
          className={classes.clearButton}
        >
          <Typography
            variant='body2Medium'
            className={classes.clearText}
            color={Color.main}
          >
            Clear
          </Typography>
        </Button>
      </div>

      {items.map((item: string) => {
        const isSelected = !!selectedItems.find(
          (thisItem: any) => thisItem === item
        )

        return (
          <MenuItem
            key={item}
            className={clsx(
              isSelected && classes.hoverAndSelected,
              classes.menuItem
            )}
          >
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={() => {
                    const selectedItem = selectedItems.find(
                      (thisItem: any) => thisItem === item
                    )
                    if (selectedItem) {
                      updateSelectedItems.remove(selectedItem)
                    } else {
                      updateSelectedItems.add(item)
                    }
                  }}
                  color='primary'
                />
              }
              label={
                <Typography variant='body2' color={Color.black}>
                  {toCapitalize(item)}
                </Typography>
              }
            />
          </MenuItem>
        )
      })}
    </Menu>
  )
}

export default PopupSelectList
