import { gql } from '@apollo/client'

export const PageInfo = gql`
  fragment pageInfo on PageInfo {
    endCursor
    startCursor
    hasNextPage
    hasPreviousPage
  }
`

export const PaginationType = `
    $first: Int
    $last: Int
    $after: String
    $before: String
`

export const PaginationInput = `
    first: $first
    last: $last
    after: $after
    before: $before
`

export const AvatarBlock = gql`
  fragment avatarBlock on AvatarBlock {
    source
    url(imgixParams: $imgixParams)
  }
`

export const BasicInfoBlock = gql`
  fragment basicInfoBlock on BasicInfoBlock {
    name
    about
  }
`

const LocationsBlock = gql`
  fragment locationsBlock on LocationsBlock {
    location {
      city
      country
      state
    }
  }
`

export const ContactInfoBlock = gql`
  fragment contactInfoBlock on ContactInfoBlock {
    emails {
      address
    }
  }
`

export const ContactInfoEnrichmentStatus = gql`
  fragment contactInfoEnrichmentStatus on ContactInfoEnrichmentStatus {
    message
    status
  }
`

export const WorkHistoryBlock = gql`
  fragment workHistoryBlock on WorkHistoryBlock {
    current {
      job
      location
    }
    workRecords {
      job
      location
      organization
      description
      startDate
      endDate
    }
  }
`

const EducationHistoryBlock = gql`
  fragment educationHistoryBlock on EducationHistoryBlock {
    educationRecords {
      school
      diploma
    }
  }
`

const SkillsBlock = gql`
  fragment skillsBlock on SkillsBlock {
    skills
  }
`

const LanguagesBlock = gql`
  fragment languagesBlock on LanguagesBlock {
    languages {
      language
      level
    }
  }
`

export const SocialMediaBlock = gql`
  fragment socialMediaBlock on SocialMediaBlock {
    primaryLinkedinUrl
    otherAccounts {
      type
      url
    }
  }
`

const AffiliationsBlock = gql`
  fragment affiliationsBlock on AffiliationsBlock {
    affiliations {
      name
      linkedinGroups {
        id
        name
      }
    }
  }
`

export const Profile = gql`
  fragment profile on Profile {
    id
    nanoid
    avatarBlock {
      ...avatarBlock
    }
    basicInfoBlock {
      ...basicInfoBlock
    }
    locationsBlock {
      ...locationsBlock
    }
    workHistoryBlock {
      ...workHistoryBlock
    }
    educationHistoryBlock {
      ...educationHistoryBlock
    }
    skillsBlock {
      ...skillsBlock
    }
    languagesBlock {
      ...languagesBlock
    }
    socialMediaBlock {
      ...socialMediaBlock
    }
    affiliationsBlock {
      ...affiliationsBlock
    }
  }
  ${AvatarBlock}
  ${BasicInfoBlock}
  ${LocationsBlock}
  ${WorkHistoryBlock}
  ${EducationHistoryBlock}
  ${SkillsBlock}
  ${LanguagesBlock}
  ${SocialMediaBlock}
  ${AffiliationsBlock}
`

export const ImpactManager = gql`
  fragment impactManager on Employer {
    impactManager {
      id
      name
      email
    }
  }
`
export const EmployerStaffPermissions = gql`
  fragment employerStaffPermissions on EmployerStaffAccount {
    ehiAccess
    isUsingSeat
    jobsAccess
    role
  }
`
export const AccountWithName = gql`
  fragment accountWithName on EmployerStaffAccount {
    id
    user {
      id
      name
      primaryEmail
    }
  }
`
