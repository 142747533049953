import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  icon: {
    height: 24,
    position: 'absolute',
    right: 0
  },
  sortLabel: { padding: '4px 8px', paddingRight: 28 },
  sortLabelSelected: {
    backgroundColor: '#0079B6',
    padding: '4px 8px',
    color: '#fff !important',
    borderRadius: 5,
    '& svg': {
      color: '#fff !important'
    },
    paddingRight: 28
  }
}))
export default useStyles
