import React, { useState, useReducer, useEffect, useContext } from 'react'
import { Dialog, Color, InfoBanner } from 'components'
import { Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import {
  AuthenticationSettings,
  User,
  EmployerAuthenticationStatus
} from 'model/organization'
import { useMutation } from 'gql/hooks'
import { REQUEST_AUTHENTICATION_SETTINGS_CHANGE } from 'gql/command'
import { AUTHENTICATION_SETTINGS_CHANGED_PROCESSED } from 'gql/subscription'
import {
  AccessMethod,
  InitialDomainState,
  DomainState,
  ServiceProvider
} from './constants'
import useStyles from './style'
import DomainLogIn from './DomainLogIn'
import { MicroServiceContext } from 'context/MicroService'

interface Props {
  openDialog: boolean
  setOpenDialog: (bool: boolean) => void
  authenticationSettings: AuthenticationSettings
  user: User
  subscribeToMore: (options: any) => void
  refetch: () => void
  authenticationStatus: EmployerAuthenticationStatus
}
const Overview = (props: Props) => {
  const {
    openDialog,
    setOpenDialog,
    authenticationSettings,
    user,
    subscribeToMore,
    refetch,
    authenticationStatus
  } = props
  const classes = useStyles()
  const [selectedRadio, setSelectedRadio] = useState<any>(
    AccessMethod.INVITATION_ONLY
  )
  const { displayNotification } = useContext(MicroServiceContext)
  const [isInProgress, setIsInProgress] = useState(false)
  const [domainState, dispatch] = useReducer(
    (formState: DomainState, action: any) => {
      return { ...formState, [action.index]: action.value }
    },
    InitialDomainState
  )

  const startSubscription = () => {
    subscribeToMore({
      document: AUTHENTICATION_SETTINGS_CHANGED_PROCESSED,
      updateQuery: (prev: any, { subscriptionData }: any) => {
        setIsInProgress(false)
        if (
          subscriptionData.data.authenticationSettingsChangeProcessed.success
        ) {
          displayNotification('Authentication has been updated!')
          refetch()
          setOpenDialog(false)
        } else {
          displayNotification(
            'Authentication update failed, please contact your impact manager!'
          )
          refetch()
          setOpenDialog(false)
        }
      }
    })
  }
  useEffect(() => {
    if (authenticationStatus === 'IN_PROGRESS') {
      setIsInProgress(true)
      startSubscription()
    }
    // eslint-disable-next-line
  }, [authenticationStatus])

  useEffect(() => {
    if (authenticationSettings.strategy) {
      setSelectedRadio(authenticationSettings.strategy)
      if (authenticationSettings.strategy === AccessMethod.DOMAIN_LOCK) {
        dispatch({
          index: 'serviceProvider',
          value: authenticationSettings.idpType
        })
      }
    }
  }, [authenticationSettings])
  const [requestAuthenticationSettingsChange] = useMutation(
    REQUEST_AUTHENTICATION_SETTINGS_CHANGE
  )

  return (
    <Dialog
      open={openDialog}
      setOpen={setOpenDialog}
      title='Log in settings'
      confirm={{
        text: 'Save',
        loading: isInProgress,
        disabled: domainState.serviceProvider === ServiceProvider.Select,
        handleAction: () => {
          setIsInProgress(true)
          const result: any = {}
          if (selectedRadio === AccessMethod.INVITATION_ONLY) {
            result.strategy = AccessMethod.INVITATION_ONLY
          } else {
            result.strategy = AccessMethod.DOMAIN_LOCK
            result.idpType = domainState.serviceProvider
          }
          startSubscription()
          requestAuthenticationSettingsChange({
            variables: { input: result }
          })
        }
      }}
    >
      <Typography
        variant='body2'
        color={Color.black60}
        className={classes.subtitle}
      >
        Choose how staff members access your organization.
      </Typography>
      <RadioGroup
        defaultValue='female'
        className={classes.ratioGroup}
        value={selectedRadio}
        onChange={(e, value) => {
          setSelectedRadio(value)
        }}
      >
        <FormControlLabel
          value={AccessMethod.INVITATION_ONLY}
          control={<Radio size='small' />}
          label={
            <Typography variant='body1Medium' color={Color.black80}>
              Invite only
            </Typography>
          }
        />
        <div className={classes.indentContainer}>
          <Typography
            variant='body2'
            color={Color.black60}
            className={classes.description}
          >
            Only staff you invite will be able to access your organization on
            Mathison. The account must be created using the email at which the
            invitation was sent.
          </Typography>
        </div>
        <FormControlLabel
          value={AccessMethod.DOMAIN_LOCK}
          control={<Radio size='small' />}
          label={
            <Typography variant='body1Medium' color={Color.black80}>
              Domain log in
            </Typography>
          }
        />
        <div className={classes.indentContainer}>
          <Typography
            variant='body2'
            color={Color.black60}
            className={classes.description}
          >
            Anyone with an email address at your chosen domain can log in. All
            new staff are assigned as a Viewer, you can edit permissions later.
          </Typography>
          <InfoBanner
            multiline
            type='info'
            content={
              <Typography variant='body2' lineHeight={'20px'} paragraph={false}>
                If you’re upgrading from{' '}
                <Typography
                  variant='body2'
                  fontWeight={700}
                  paragraph={false}
                  component='span'
                >
                  Invite only
                </Typography>
                , staff that created an account using an email address that
                isn’t at your domain will need to create an account with an
                email address at your domain.
              </Typography>
            }
          />
          {selectedRadio === AccessMethod.DOMAIN_LOCK && (
            <>
              <DomainLogIn
                dispatch={dispatch}
                authenticationSettings={authenticationSettings}
                user={user}
                domainState={domainState}
              ></DomainLogIn>
            </>
          )}
        </div>
      </RadioGroup>
    </Dialog>
  )
}
export default Overview
