import { gql } from '@apollo/client'

export const SEARCH_LOCATION = gql`
  query SearchLocation($searchTerm: String!) {
    searchLocations(searchTerm: $searchTerm) {
      ... on LocationGroupingResult {
        locations {
          city
          cityId
          state
          stateId
          country
          countryId
        }
      }
      ... on LocationResult {
        location {
          city
          cityId
          state
          stateId
          country
          countryId
        }
      }
    }
  }
`
