import { gql } from '@apollo/client'

export const VALIDATE_INVITE_TOKEN = gql`
  query ValidateInviteToken($token: String!) {
    validateInviteToken(token: $token) {
      email
      status
    }
  }
`
export const CREATE_USER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`

export const VALIDATE_PASSWORD_RESET_TOKEN = gql`
  query ValidatePasswordResetToken($token: String!) {
    validatePasswordResetToken(token: $token)
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`
