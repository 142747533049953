import React, { useState, useContext } from 'react'
import { TableCell, TableRow, Typography, IconButton } from '@mui/material'
import { Color } from 'components'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import useStyles from './Create/style'
import { format } from 'date-fns'
import { HashLink } from 'react-router-hash-link'
import RowDialogs from './RowDialogs'
import { useNavigate } from 'react-router'
import clsx from 'clsx'
import { AtsContext } from 'context/AtsContext'
interface Props {
  data: any
  updateQuery: (prev: any) => void
  status: string
}

const TableRowBuilder = (props: Props) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { data, status, updateQuery } = props
  const [anchorEl, setAnchorEl] = useState()

  const { isAtsAvailable, isAtsSetup } = useContext(AtsContext)

  return (
    <>
      <RowDialogs
        selectedRow={data}
        anchorEl={anchorEl}
        updateQuery={updateQuery}
        setAnchorEl={setAnchorEl}
        status={status}
      />
      <TableRow
        key={data.nanoid}
        onClick={() => navigate(`/jobs/${data.nanoid}`)}
      >
        <TableCell scope='row' width='30%'>
          <HashLink to={`/jobs/${data.nanoid}`}>
            <Typography variant='body1Medium' color={Color.main}>
              {data.displayTitle}
            </Typography>
            <Typography
              variant='body2'
              color={Color.black80}
              style={{ marginRight: '70px' }}
            >
              {!isAtsAvailable && data.internalTitle}
            </Typography>
          </HashLink>
        </TableCell>
        <TableCell align='left'>
          <Typography variant='body2'>{data.numCandidates}</Typography>
        </TableCell>
        <TableCell align='left'>
          <Typography
            variant='body2Medium'
            lineHeight='28px'
            paragraph
            className={clsx(
              classes.candidatesNum,
              data.numCandidatesInLastWeek !== 0
                ? classes.lastWeekCandidatesNum
                : classes.lastWeekCandidatesZero
            )}
          >
            +{data.numCandidatesInLastWeek}
          </Typography>
        </TableCell>
        <TableCell align='left' width='30%'>
          <Typography variant='body2'>
            {format(new Date(data.insertedAt), 'MMM dd, yyyy')}
          </Typography>
        </TableCell>
        {isAtsSetup &&
        <TableCell>
          <Typography
            variant='body2'
            color={Color.black80}
            style={{ marginRight: '70px' }}
          >
            {isAtsSetup && data.internalTitle}
          </Typography>
        </TableCell>
}
        <TableCell
          align='right'
          width='auto'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation()
              setAnchorEl(e.currentTarget)
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

export default TableRowBuilder
