const scheme_rx = new RegExp('^https?://')

export const openInNewTab = (url: string) => {
  if (!url) return

  if (!scheme_rx.test(url)) url = `https://${url}`

  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

  if (newWindow) newWindow.opener = null
}
