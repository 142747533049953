import { makeStyles } from '@mui/styles'
import { Color } from 'components'

const useStyles = makeStyles(() => ({
  infobar: { borderRadius: 10, paddingTop: 24, paddingBottom: 24 },
  banner: {
    padding: '12px 28px 12px 14px',
    backgroundColor: Color.blue5,
    borderRadius: 5
  },
  errorBanner: {
    padding: '12px 28px 12px 14px',
    marginBottom: '16px',
    backgroundColor: Color.error10,
    borderRadius: 5
  },
  button: {
    padding: '12px 40px'
  },
  infoIcon: {
    marginRight: 14,
    '& path': {
      fill: Color.main
    }
  },
  input: {
    '&::placeholder': {
      color: '#505C63 !important'
    }
  },
  emailList: {
    overflow: 'scroll',
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      '-webkit-box-shadow': 'none',
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.2)',
      borderRadius: 4
    }
  }
}))

export default useStyles
