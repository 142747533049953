import { gql } from '@apollo/client'
import { ImpactManager } from './fragments'

export const GET_MY_EMPLOYER = gql`
  query MyEmployer($imgixParams: ImgixParamsInput) {
    myEmployer {
      id
      logo(imgixParams: $imgixParams)
      name
      authenticationStatus
      numAllocatedSeats
      numOpenSeats
      numUsedSeats
      status
      authenticationSettings {
        domain
        idpType
        strategy
      }
      mainContact {
        email
        name
        phone
      }
      ...impactManager
    }
    numEmployerStaffAccounts
  }
  ${ImpactManager}
`
