import React, { ReactNode } from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

interface CmsLinkProps {
  url: string
  children: ReactNode
}

const CmsLinkWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  '&:hover': {
    cursor: 'pointer'
  },
  a: {
    display: 'flex',
    color: '#000000',
    width: '100%'
  }
}))

const CmsLink = ({ url, children }: CmsLinkProps) => {
  const externalUrl: boolean = url.includes('http')
  return (
    <CmsLinkWrapper>
      <a href={url} target={externalUrl ? '_blank' : '_self'} rel='noreferrer'>
        {children}
      </a>
    </CmsLinkWrapper>
  )
}
export default CmsLink
