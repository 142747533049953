import React from 'react'

export const MicroServiceContext = React.createContext<any>({})

export const MicroServiceProvider = (props: any): JSX.Element => {
  return (
    <MicroServiceContext.Provider value={props}>
      {props.children}
    </MicroServiceContext.Provider>
  )
}
