import React from 'react'
import { BaseArticle, ContentLabel } from 'model/cms'
import { styled } from '@mui/material/styles'
import {
  getChipColorByContentBucketSlug,
  constructChapterLinkLabel
} from 'hooks/cmsComponentHooks'
import Card, { CardProps } from '@mui/material/Card'
import CardMedia, { CardMediaProps } from '@mui/material/CardMedia'
import CardActions from '@mui/material/CardActions'
import CardContent, { CardContentProps } from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import CardEyebrow from './CardEyebrow'
import Grid from '@mui/material/Grid'
import Box, { BoxProps } from '@mui/material/Box'
import CtaButton from './CtaButton'
import TagsComponent from './TagsComponent'
import CmsLink from '../CmsLink/CmsLink'
import ChapterCardEyebrow from './ChapterCardEyebrow'
import CardButtonLink from './CardButtonLink'
import { theme } from '../../index'

const ButtonWrapper = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: 'auto'
}))

interface CardContentWrapperProps extends BoxProps {
  articleVariant: 'content' | 'featured' | 'chapter'
  cardGroup: 'two' | 'three' | undefined
}

const CardContentWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'articleVariant' && prop !== 'cardGroup'
})<CardContentWrapperProps>(({ articleVariant, cardGroup }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: '"D-DIN", Roboto, sans-serif',
  [theme.breakpoints.up('xs')]: {
    padding: cardGroup === 'three' ? '32px' : '32px 32px 24px 40px'
  },
  [theme.breakpoints.up('xl')]: {
    padding:
      articleVariant === 'featured'
        ? '72px 56px 48px 64px'
        : cardGroup === 'three'
        ? '32px'
        : '32px 32px 24px 40px'
  },
  flexGrow: 1
}))

const CardContentEyebrowContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'articleVariant'
})<CardContentWrapperProps>(({ articleVariant }) => ({
  [theme.breakpoints.up('xs')]: {
    paddingBottom: '40px'
  },
  [theme.breakpoints.up('xl')]: {
    paddingBottom: articleVariant === 'featured' ? '86px' : '40px'
  }
}))

const StyledCardMedia = styled(CardMedia)<CardMediaProps>(() => ({
  [theme.breakpoints.up('xs')]: {
    height: '100%',
    minHeight: '440px'
  }
}))

interface ArticleCardContentProps extends CardContentProps {
  articleVariant: 'content' | 'featured' | 'chapter'
}

const ArticleCardContent = styled(CardContent, {
  shouldForwardProp: prop => prop !== 'articleVariant'
})<ArticleCardContentProps>(({ articleVariant }) => ({
  flexGrow: 1,
  padding: '0 0 32px 0',
  [theme.breakpoints.up('xs')]: {
    maxWidth: '100%'
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '100%'
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: articleVariant === 'featured' ? '75%' : '360px'
  },
  '& .MuiTypography-h5': {
    fontWeight: 700,
    paddingBottom: '1rem',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      letterSpacing: '-0.5px'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: articleVariant === 'featured' ? '1.75rem' : '1.5rem',
      lineHeight: articleVariant === 'featured' ? '2.2rem' : '1.75rem',
      letterSpacing: '0.5px'
    }
  }
}))

interface ArticleCardContainerProps extends CardProps {
  articleVariant: 'content' | 'featured' | 'chapter'
}

const ArticleCardContainer = styled(Card, {
  shouldForwardProp: prop => prop !== 'articleVariant'
})<ArticleCardContainerProps>(({ articleVariant }) => ({
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  display: 'flex',
  minHeight: articleVariant === 'featured' ? '500px' : '425px',
  '&:hover': {
    border: '1px solid #CDCDCD',
    cursor: 'pointer'
  }
}))

const FeaturedBlurbContainer = styled(Box)<BoxProps>(({ theme }) => ({
  color: '#000000',
  fontFamily: 'D-DIN',
  fontWeight: 400,
  paddingBottom: '1rem',
  marginBottom: '0',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.01em'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.375rem',
    lineHeight: '1.875rem',
    letterSpacing: '-0.5px'
  }
}))

const BlurbContainer = styled(Box)<BoxProps>(() => ({
  color: '#050B0E',
  fontFamily: 'D-DIN',
  fontWeight: 400,
  fontSize: '1.125rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.01em',
  paddingBottom: '1rem',
  marginBottom: '0'
}))

interface ArticleCardProps extends BaseArticle {
  articleVariant: 'content' | 'featured' | 'chapter'
  cardGroup?: 'two' | 'three' | undefined
  url: string
  bookmark?: () => void
  chapterNumber?: number
}

const ArticleCard = ({
  articleVariant,
  cardGroup,
  url,
  chapterNumber,
  contentBucket,
  blurb,
  title,
  tags,
  minutes,
  startDate,
  image
}: ArticleCardProps) => {
  const label = ContentLabel[contentBucket.slug as keyof typeof ContentLabel]
  const chipColor: string = getChipColorByContentBucketSlug(contentBucket.slug)
  const chapterLabel: string | null = chapterNumber
    ? constructChapterLinkLabel(chapterNumber)
    : null

  return (
    <ArticleCardContainer
      articleVariant={articleVariant}
      className='article-card'
      variant='outlined'
    >
      <CardActions sx={{ alignItems: 'flex-start', padding: 0, width: '100%' }}>
        <CmsLink url={url}>
          <Grid container flexGrow={1}>
            {articleVariant === 'featured' && image ? (
              <Grid item xs={12} lg={6}>
                <StyledCardMedia image={image.responsiveImage.src} />
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              lg={articleVariant === 'featured' ? 6 : 12}
              display='flex'
            >
              <CardContentWrapper
                articleVariant={articleVariant}
                cardGroup={cardGroup}
              >
                {chapterNumber ? (
                  <ChapterCardEyebrow chapterNumber={chapterNumber} />
                ) : (
                  <CardContentEyebrowContainer
                    articleVariant={articleVariant}
                    cardGroup={cardGroup}
                  >
                    <CardEyebrow
                      label={label}
                      backgroundColor={chipColor}
                      readTime={minutes}
                      dateTime={startDate}
                      cardGroup={cardGroup}
                    />
                  </CardContentEyebrowContainer>
                )}
                <ArticleCardContent articleVariant={articleVariant}>
                  <Typography variant='h5' component='div'>
                    {title}
                  </Typography>
                  {articleVariant && articleVariant === 'featured' ? (
                    <FeaturedBlurbContainer>{blurb}</FeaturedBlurbContainer>
                  ) : (
                    <BlurbContainer>{blurb}</BlurbContainer>
                  )}
                </ArticleCardContent>
                <ButtonWrapper>
                  <TagsComponent tags={tags} />
                  <Box sx={{ width: '100%' }}>
                    {articleVariant === 'featured' ? (
                      <CtaButton variant='outlined'>
                        {contentBucket.slug === 'video'
                          ? 'Watch video'
                          : contentBucket.slug === 'event'
                          ? 'Register'
                          : 'Read more'}
                      </CtaButton>
                    ) : (
                      <CardButtonLink>
                        {chapterNumber
                          ? chapterLabel
                          : contentBucket.slug === 'video'
                          ? 'Watch video'
                          : contentBucket.slug === 'event'
                          ? 'Register'
                          : 'Read more'}
                      </CardButtonLink>
                    )}
                  </Box>
                </ButtonWrapper>
              </CardContentWrapper>
            </Grid>
          </Grid>
        </CmsLink>
      </CardActions>
    </ArticleCardContainer>
  )
}
export default ArticleCard
