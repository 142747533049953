import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  description: { position: 'relative', bottom: 8 },
  subtitle: { position: 'relative', bottom: 12 },
  ratioGroup: { marginTop: 24 },
  indentContainer: { marginLeft: 28 },
  inputBox: {
    display: 'inline-block'
  },
  selectBox: {
    marginBottom: 12
  },
  menuList: { padding: 8 },
  selectDisplay: { padding: '12.5px 14px' }
}))
export default useStyles
