import React, { useState } from 'react'
import { Color } from 'components'
import { Typography, Grid } from '@mui/material'
import { LinkedinGroup } from 'model/profile'

interface Props {
  linkedinGroups: LinkedinGroup[]
}

const AffiliationsGroup = ({ linkedinGroups }: Props): React.ReactElement => {
  const [showMore, setShowMore] = useState(false)
  if (linkedinGroups.length === 0) return <></>
  return (
    <Grid>
      <Typography
        variant='button'
        color='primary'
        onClick={() => setShowMore(!showMore)}
        style={{ cursor: 'pointer' }}
      >
        {showMore ? '' : `+ ${linkedinGroups.length} more`}
      </Typography>
      <Grid container display={showMore ? '' : 'none'}>
        {linkedinGroups.map((linkedinGroup: LinkedinGroup, index: number) => {
          return (
            <Typography
              key={`linkedinGroup_${index}`}
              variant='body2'
              color={Color.grey700}
              marginRight={'2px'}
            >
              {linkedinGroup.name}
              {index !== linkedinGroups.length - 1 ? ' • ' : ''}
            </Typography>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default AffiliationsGroup
