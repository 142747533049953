import React, { createContext } from 'react'
import { useQuery } from 'gql/hooks/http/useQuery'
import { GET_DASHBOARD } from 'gql/query'

import client from 'utils/createApolloHttpClient'
const INITIAL_DATA = { allCategories: [], allEvents: [], homePage: {} }
export const DashboardContext: any = createContext(INITIAL_DATA)

export const DashboardProvider = ({
  children,
  cmsApiKey
}: any): JSX.Element => {
  const { data = INITIAL_DATA, loading } = useQuery(GET_DASHBOARD, {
    client: client('https://graphql.datocms.com', cmsApiKey)
  })

  const { allCategories, allEvents, homePage } = data

  return (
    <DashboardContext.Provider value={{ allCategories, allEvents, homePage }}>
      {!loading && children}
    </DashboardContext.Provider>
  )
}
