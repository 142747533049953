import React, { memo, useState, useContext, useEffect } from 'react'
import {
  Typography,
  Button,
  IconButton,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material'
const ReactQuill = require('react-quill')
import { toolbarOptions, JOB_REQUIREMENT_STEPS } from '../constant'
import 'react-quill/dist/quill.snow.css'

import * as Showdown from 'showdown'
import {
  Add as AddIcon,
  ClearRounded as ClearRoundedIcon,
  ErrorRounded as ErrorIcon
} from '@mui/icons-material'
import { useStyles, StyledTextField, AddButton } from './style'
import ExpansionPanel from './ExpansionPanel'
import { LocationInput } from 'model'
import RenderChips from './RenderChips'
import { Color, UrlTextField, FormField } from 'components'
import { RequestBatchContext } from 'context/RequestBatch'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
})

const RequiredInput = (): React.ReactElement => {
  const classes = useStyles()
  const {
    requestBatchInput,
    setRequestBatchInput,
    errors,
    setIsValidDescriptionUrl,
    setIsValidProfileUrl,
    reachLimit,
    setReachLimit
  } = useContext(RequestBatchContext)

  const [location, setLocation] = useState('')

  const handleAddChip = (target: string, prev: any[], newValue: string) => {
    if (newValue === '') return
    const newInput = [...prev]
    if (target === 'locations') {
      newInput.push({ city: newValue })
    }
    setRequestBatchInput({
      index: target,
      value: newInput
    })
  }

  const [urlDescription, setUrlDescription] = useState(
    requestBatchInput.description.length < 100
      ? requestBatchInput.description
      : ''
  )
  const [textDescription, setTextDescription] = useState(
    requestBatchInput.description.length >= 100
      ? requestBatchInput.description
      : ''
  )
  const [descriptionToggle, setDescriptionToggle] = useState<string | false>(
    requestBatchInput.description.length > 100 ? 'create' : 'add'
  )

  const handleChangeInput = (index: number, value: any) => {
    const newInput = [...requestBatchInput.exampleProfiles]
    newInput.splice(index, 1, value)
    setRequestBatchInput({ index: 'exampleProfiles', value: newInput })
  }
  const handleRemoveExampleProfile = (index: number) => {
    const newInput = [...requestBatchInput.exampleProfiles]
    newInput.splice(index, 1)
    setRequestBatchInput({ index: 'exampleProfiles', value: newInput })
  }
  useEffect(() => {
    if (descriptionToggle === 'add') {
      setRequestBatchInput({
        index: 'description',
        value: urlDescription
      })
    } else {
      if (reachLimit) {
        setRequestBatchInput({
          index: 'description',
          value: textDescription
        })
      }
    }
    // eslint-disable-next-line
  }, [descriptionToggle, urlDescription, reachLimit, textDescription])
  const handleQuillChange = (
    content: string,
    _delta: any,
    _source: any,
    editor: any
  ) => {
    setTextDescription(content)
    const length = editor.getLength()
    if (length < 100) {
      setReachLimit(false)
    } else {
      setReachLimit(true)
    }
  }
  return (
    <Grid>
      <Typography
        variant='subheading'
        color={Color.black87}
        display='block'
        marginBottom={1}
      >
        REQUIRED
      </Typography>
      <Typography variant='h3'> Basic details</Typography>
      <ExpansionPanel
        required
        title={JOB_REQUIREMENT_STEPS[0]}
        preview={[requestBatchInput?.displayTitle]}
        disableNextStep={errors.displayTitle}
      >
        <StyledTextField
          fullWidth
          error={errors.displayTitle}
          defaultValue={requestBatchInput.displayTitle}
          onChange={e =>
            setRequestBatchInput({
              index: 'displayTitle',
              value: e.target.value
            })
          }
          helperText={
            errors.displayTitle && (
              <>
                <ErrorIcon color='error' />
                <Typography variant='body2' component='span'>
                  Please enter at least 3 characters
                </Typography>
              </>
            )
          }
        />
      </ExpansionPanel>
      <ExpansionPanel
        required
        title={JOB_REQUIREMENT_STEPS[1]}
        description={
          'Either add a URL to this job or create your own job description.'
        }
        RenderPreview={() => {
          return (
            <React.Fragment>
              {requestBatchInput.description.length >= 100 ? (
                <Typography
                  variant='body2'
                  className={classes.displayText}
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(requestBatchInput.description)
                  }}
                />
              ) : (
                <Typography variant='body2' marginLeft={'18px'}>
                  {requestBatchInput.description}
                </Typography>
              )}
            </React.Fragment>
          )
        }}
        disableNextStep={errors.description}
      >
        <RadioGroup
          row
          className={classes.radioGroup}
          value={descriptionToggle}
          onChange={(_e: any, value: string) => {
            setDescriptionToggle(value)
          }}
        >
          <FormControlLabel
            value={'add'}
            control={<Radio className={classes.radioButton} />}
            label={
              <Typography variant='body2'>
                Add a URL to job description
              </Typography>
            }
          />
          <FormControlLabel
            value={'create'}
            control={<Radio className={classes.radioButton} />}
            label={
              <Typography variant='body2'>Create a job description</Typography>
            }
          />
        </RadioGroup>
        {descriptionToggle === 'add' ? (
          <UrlTextField
            defaultValue={urlDescription}
            placeholder={'http://example.com'}
            setValue={setUrlDescription}
            setIsValidUrl={setIsValidDescriptionUrl}
          />
        ) : (
          <>
            <ReactQuill
              theme='snow'
              defaultValue={textDescription}
              onChange={handleQuillChange}
              modules={{ toolbar: toolbarOptions }}
              className={classes.qlEditor}
            />
            {reachLimit === false && (
              <Grid container alignItems='center' marginTop={0.5}>
                <ErrorIcon color='error' className={classes.errorIcon} />
                <Typography variant='body2' color={Color.error}>
                  Please enter at least 100 characters
                </Typography>
              </Grid>
            )}
          </>
        )}
      </ExpansionPanel>
      <ExpansionPanel
        required
        title={JOB_REQUIREMENT_STEPS[2]}
        preview={requestBatchInput.locations.map(
          (location: LocationInput) => location.city!
        )}
        description='Locations that we should include.'
        disableNextStep={errors.locations}
      >
        <FormField
          fullWidth
          value={location}
          className={classes.rightPadding}
          placeholder={'Eg. New York, Los Angeles, San Francisco'}
          onChange={(e: any) => setLocation(e.target.value)}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleAddChip('locations', requestBatchInput.locations, location)
              setLocation('')
            }
          }}
          InputProps={{
            endAdornment: (
              <AddButton
                onClick={() => {
                  handleAddChip(
                    'locations',
                    requestBatchInput.locations,
                    location
                  )
                  setLocation('')
                }}
              />
            )
          }}
        />
        <RenderChips
          data={requestBatchInput.locations}
          target={'locations'}
          tag='location'
        />
      </ExpansionPanel>
      <ExpansionPanel
        required
        title={JOB_REQUIREMENT_STEPS[3]}
        preview={requestBatchInput.exampleProfiles}
        description='Add at least one link to an example profile or resume of someone who could potentially succeed in this role.'
        disableNextStep={errors.exampleProfiles}
      >
        {requestBatchInput.exampleProfiles.map(
          (profile: string, index: number) => (
            <div key={`example-${index}`}>
              <UrlTextField
                index={index}
                defaultValue={profile}
                setIsValidUrl={setIsValidProfileUrl}
                placeholder={'http://exampleprofile.com'}
                dispatchValue={handleChangeInput}
                endAdornment={
                  <IconButton
                    disabled={index === 0}
                    onClick={() => handleRemoveExampleProfile(index)}
                  >
                    <ClearRoundedIcon />
                  </IconButton>
                }
              />
            </div>
          )
        )}

        <Button
          size={'small'}
          startIcon={<AddIcon />}
          className={classes.addProfileButton}
          onClick={() =>
            setRequestBatchInput({
              type: 'addExampleProfile'
            })
          }
        >
          <Typography variant='button' color='primary'>
            Add another profile
          </Typography>
        </Button>
      </ExpansionPanel>
    </Grid>
  )
}

export default memo(RequiredInput)
