import React, { useState, useContext } from 'react'
import CategoryTabs from 'components/CMS/CategoryTabs'
import { Button, Typography, Grid } from '@mui/material'
import { DashboardContext } from 'context/DashboardContext'
import { constructLandingPageTabsContent } from 'hooks/cmsComponentHooks'
const CategoryContents = () => {
  const { allCategories, homePage } = useContext(DashboardContext)
  const [value, setValue] = useState(0)
  const tabsContent = constructLandingPageTabsContent(allCategories)

  return (
    <Grid container flexDirection='column' alignItems='center'>
      <CategoryTabs
        tabsContent={tabsContent}
        fallbackImage={homePage.articleFallbackImage}
        value={value}
        setValue={setValue}
      />

      <Button
        sx={{
          width: 116,
          height: 56,
          marginBottom: '16px'
        }}
        variant='contained'
        color='secondary'
        href={`/knowledge-center/category/${
          allCategories.length > 0 && allCategories[value].slug
        }/`}
      >
        <Typography variant='h3' fontSize='16px' color='white'>
          See more
        </Typography>
      </Button>
    </Grid>
  )
}
export default CategoryContents
