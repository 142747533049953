import React from 'react'
import { IconButton } from '@mui/material'
import { GoogleIcon, LinkedInIcon, Office365Icon } from 'components'
import { makeStyles } from '@mui/styles'

interface Props {
  redirectURL: string
  authenticateWithMicrosoft: (path: string) => void
  authenticateWithLinkedIn: (path: string) => void
  authenticateWithGoogle: (path: string) => void
}
const SocialLoginBox = ({
  redirectURL,
  authenticateWithMicrosoft,
  authenticateWithLinkedIn,
  authenticateWithGoogle
}: Props): React.ReactElement => {
  const classes = useStyles()
  return (
    <div className={classes.iconGroup}>
      <IconButton
        className='iconButton'
        onClick={() => authenticateWithMicrosoft(redirectURL)}
      >
        <Office365Icon size={24} />
      </IconButton>
      <IconButton
        className='iconButton'
        onClick={() => authenticateWithLinkedIn(redirectURL)}
      >
        <LinkedInIcon size={24} />
      </IconButton>
      <IconButton
        className='iconButton'
        onClick={() => authenticateWithGoogle(redirectURL)}
      >
        <GoogleIcon size={24} />
      </IconButton>
    </div>
  )
}

const useStyles = makeStyles(() => ({
  iconGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2px 168px 56px 168px',
    '& .iconButton': {
      border: 'solid 1px #E0E7EB',
      padding: 16
    },
    '& .icon': {
      width: 24,
      height: 24
    }
  }
}))

export default SocialLoginBox
