import React from 'react'
import Box, { BoxProps } from '@mui/material/Box'
import KCModal from './KCModal'
import { ResponsiveImageType } from 'react-datocms'
import CoverImage from './CoverImage'
import VideoPlayer from './VideoPlayer'
import { styled } from '@mui/material/styles'

interface VideoModalProps {
  videoUrl: string
  image: ResponsiveImageType
}

const VideoModalContainer = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '100%',
  '& .modal-btn-wrapper': {
    position: 'absolute',
    width: '100%',
    bottom: '30%',
    left: '4.2%'
  }
}))

const VideoModal = ({ videoUrl, image }: VideoModalProps) => {
  return (
    <VideoModalContainer>
      <CoverImage title={image.alt} responsiveImage={image} />
      <KCModal>
        <VideoPlayer
          videoUrl={videoUrl}
          image={image.src}
          playing={true}
          height={'81%'}
          marginTop={'-10%'}
        />
      </KCModal>
    </VideoModalContainer>
  )
}
export default VideoModal
