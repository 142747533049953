import React, { useEffect } from 'react'
import { Autocomplete, Typography, TextField } from '@mui/material'
import { MenuItem } from '../../style'
import { TransferCandidateInput } from 'model'
import useDebounceInput from 'hooks/debounce'

interface Props {
  transferrableJobs: any
  isTransferrableJobsLoading: boolean
  dispatchTransferInput: (transferInput: TransferCandidateInput) => void
  setJobSearchTerm: (searchTerm: string | undefined) => void
}

const JobSelector = ({
  transferrableJobs,
  isTransferrableJobsLoading,
  dispatchTransferInput,
  setJobSearchTerm
}: Props) => {
  const { debouncedSearchTerm, setSearchTerm, searchTerm } = useDebounceInput()

  useEffect(
    () => setJobSearchTerm(searchTerm),
    // eslint-disable-next-line
    [debouncedSearchTerm]
  )

  return (
    <Autocomplete
      id='transferableJobs'
      fullWidth
      autoHighlight
      options={transferrableJobs || []}
      loading={isTransferrableJobsLoading}
      filterOptions={(options: any) => options}
      onChange={(e: any, value: any) =>
        value &&
        dispatchTransferInput({
          jobId: value.id,
          jobName: value.displayTitle
        } as TransferCandidateInput)
      }
      noOptionsText={'No jobs found'}
      isOptionEqualToValue={(option: any, value: any) =>
        option?.id === value?.id
      }
      getOptionLabel={(option: any) => option?.displayTitle || ''}
      renderOption={(props: any, option: any) => {
        return (
          <MenuItem {...props}>
            <Typography>{option?.displayTitle}</Typography>
          </MenuItem>
        )
      }}
      renderInput={params => {
        const newParams = { ...params }
        newParams.inputProps.style = { padding: 0 }
        return (
          <TextField
            {...newParams}
            variant='outlined'
            placeholder='Start typing to search'
            value={''}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
        )
      }}
    ></Autocomplete>
  )
}

export default JobSelector
