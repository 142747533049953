import { Typography, TextField, Button } from '@mui/material'
import { useMutation } from 'gql/hooks'
import { DialogWithoutAction, InfoBanner, Tooltip, Color } from 'components'
import React, { memo, useContext } from 'react'
import { CreateJobInput, Filter, JobStatus, DispatchContent } from 'model'
import { CREATE_JOB } from 'gql/command'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { CREATE_JOB_SUBSCRIPTION } from 'gql/subscription'
import useStyles from './style'
import { MicroServiceContext } from 'context/MicroService'
import { AtsContext } from 'context/AtsContext'
import { openInNewTab } from 'utils/openInNewTab'

interface Props {
  openCreateJobDialog: boolean
  setOpenCreateJobDialog: (_bool: boolean) => void
  jobState: CreateJobInput
  dispatch: (data: DispatchContent | string) => void
  subscribeToMore: (_payload: any) => void
  jobFilterState: Filter
}
const CreateJob = (props: Props): React.ReactElement => {
  const {
    openCreateJobDialog,
    setOpenCreateJobDialog,
    dispatch,
    jobState,
    subscribeToMore,
    jobFilterState
  } = props
  const [createJobMutation] = useMutation(CREATE_JOB)
  const { displayNotification } = useContext(MicroServiceContext)
  const { isAtsSetup } = useContext(AtsContext)
  const classes = useStyles()
  const startSubscription = () => {
    subscribeToMore({
      document: CREATE_JOB_SUBSCRIPTION,
      variables: {
        id: jobState.jobId
      },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        displayNotification('This job has been created')
        if (
          !subscriptionData.data ||
          jobFilterState.status === JobStatus.CLOSED
        )
          return prev

        const newPrev = prev ? { ...prev } : {}
        if (!prev.myJobs) {
          newPrev.myJobs = {}
          newPrev.myJobs.edges = []
        }
        return Object.assign({}, prev, {
          myJobs: {
            ...newPrev.myJobs,
            edges: [
              {
                node: subscriptionData.data.jobCreated
              },
              ...newPrev.myJobs.edges
            ],
            count: newPrev.myJobs.count + 1
          }
        })
      }
    })
  }
  const closeDialog = () => {
    dispatch('clear')
    setOpenCreateJobDialog(false)
  }
  return (
    <>
      <DialogWithoutAction
        open={openCreateJobDialog}
        setOpen={setOpenCreateJobDialog}
        onClose={() => closeDialog()}
        title='Create job'
        Action={() => (
          <>
            <Button
              color='secondary'
              className={classes.buttons}
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                startSubscription()
                const submitState: any = { ...jobState }
                delete submitState.openCreateJobDialog
                createJobMutation({
                  variables: {
                    createJobInput: submitState
                  }
                })
                closeDialog()
              }}
              className={classes.buttons}
              color='primary'
              variant='contained'
              disabled={!jobState.displayTitle}
            >
              Create
            </Button>
          </>
        )}
      >
        <>
          <InfoBanner
            type='info'
            multiline
            content={
              <>
              Once you’ve created this job, you can request leads.&nbsp;
              <Typography
                variant='body2Medium'
                color={Color.black87}
                className='bannerText'
                sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }}
                onClick={() => openInNewTab('https://mathison.notion.site/What-types-of-jobs-does-Mathison-support-d777397973d143fab90bb82aea6f7c73')}
              >
                Learn more about roles we specialize in sourcing
              </Typography>
              .
            </>
            }
          />
          <Typography
            variant='formLabel'
            paragraph
            marginTop='20px'
            marginBottom='4px'
          >
            Title{' '}
          </Typography>
          <TextField
            variant='outlined'
            fullWidth
            placeholder='Job title'
            value={jobState.displayTitle}
            onChange={(e: any) =>
              dispatch({
                index: 'displayTitle',
                value: e.target.value
              })
            }
          />

          {isAtsSetup && (
            <>
              <Typography
                variant='formLabel'
                paragraph
                marginTop='20px'
                marginBottom='4px'
              >
                {isAtsSetup ? 'ATS Job ID' : 'Internal job ID'} (Optional){' '}
                <Tooltip
                  title={
                    isAtsSetup ?
                      'If you enter your ATS Job ID, leads moved to your ‘To contact’ list will automatically send to your ATS platform.' :
                      'If you have two jobs with similar titles, you can \n use this internal ID to help identify them.'
                  }
                  placement='right-end'
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </Tooltip>
              </Typography>
              <TextField
                variant='outlined'
                fullWidth
                placeholder={isAtsSetup ? 'ATS Job ID' : 'Internal job ID'}
                value={jobState.internalTitle}
                onChange={(e: any) =>
                  dispatch({
                    index: 'internalTitle',
                    value: e.target.value
                  })
                }
              />
            </>
          )}
        </>
      </DialogWithoutAction>
    </>
  )
}

export default memo(CreateJob)
