import { makeStyles } from '@mui/styles'

import { Color } from 'components'

const drawerWidth = 446

export const useStyles = makeStyles(() => ({
  banner: {
    height: 60,
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 2,
    padding: '0px 32px',
    backgroundColor: `rgba(255,255,255,0.6) !important`,
    backdropFilter: 'blur(100px)',
    WebkitBackdropFilter: 'blur(100px)',
    borderBottom: '1px solid #F2F3F3',
    '& .logo': {
      display: 'flex',
      '& img': { width: 24, height: 24, marginRight: 16 }
    }
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #F5F6F7',
    height: 100,
    zIndex: 1,
    padding: '16px 21px 0 30px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(200px)',
    WebkitBackdropFilter: 'blur(200px)',
    boxShadow: '0px -2px 4px rgba(176, 186, 191, 0.12)',
    position: 'sticky',
    top: 'calc(100vh - 100px)',
    left: 0,
    '& .prevButton': {
      color: Color.black,
      width: 98,
      height: 44
    },
    '& .nextButton': {
      width: 98,
      height: 44
    }
  },
  reviewPane: {
    width: drawerWidth,
    backgroundColor: Color.white,
    opacity: 0.75,
    backdropFilter: 'blur(55px)',
    WebkitBackdropFilter: 'blur(55px)',
    border: '1px solid #F2F3F3',
    borderTop: 'none'
  },
  reviewPaneContent: {
    padding: '0 32px 132px',
    marginTop: -16,
    '& .feedBack': {
      backgroundColor: '#EAF5FB',
      borderRadius: 5,
      width: 382,
      margin: '24px auto',
      padding: 12
    },
    '& .feedBackText': {
      width: 310,
      marginLeft: 13
    },
    '& .inputBox': {
      margin: '12px auto 32px auto',
      '& .MuiOutlinedInput-root': { padding: 12 },
      '& .MuiOutlinedInput-input': { padding: 0 }
    },
    '& .infoIcon': {
      marginLeft: 8
    },
    '& .selection': {
      marginTop: 16,
      padding: '16px 24px',
      width: 382,
      border: '1px solid #D7DCDF',
      borderRadius: 10,
      display: 'flex'
    },
    '& .selected': {
      border: '1px solid #050B0E',
      backgroundColor: '#FAFAFA'
    },
    '& .icon': {
      marginRight: 8
    },
    '& .tag': {
      height: 24,
      borderRadius: 20,
      padding: '0px 8px',
      marginLeft: 8
    }
  },
  profileSection: {
    marginTop: 56,
    width: `calc(100% - ${drawerWidth}px)`
  },
  title: {
    marginBottom: 28
  },
  emptyView: {
    height: '100vh',
    marginTop: 200,
    '& .blackButton': {
      width: 198,
      height: 44,
      marginTop: 24
    },
    '& .textButton': {
      color: Color.black,
      marginTop: 18,
      '&:hover': {
        backgroundColor: 'transparent'
      }
    }
  }
}))
