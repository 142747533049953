import {
  EmployerStaffAccountEhiAccessLevel,
  EmployerStaffAccountJobsAccessLevel,
  EmployerStaffAccountRole,
  EmployerStaffAccountStatus
} from 'model/organization'

export const ROLE_OPTIONS = [
  EmployerStaffAccountRole.BASIC_USER,
  EmployerStaffAccountRole.MANAGER,
  EmployerStaffAccountRole.SUPER_MANAGER
]

export const JOBS_ACCESS_OPTIONS = [
  EmployerStaffAccountJobsAccessLevel.BASIC_JOBS_ACCESS,
  EmployerStaffAccountJobsAccessLevel.JOBS_LEAD,
  EmployerStaffAccountJobsAccessLevel.NO_JOBS_ACCESS
]

export const EHI_ACCESS_OPTIONS = [
  EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS,
  EmployerStaffAccountEhiAccessLevel.BASIC_EHI_ACCESS,
  EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS
]

export const STATUS_OPTIONS = [
  EmployerStaffAccountStatus.PENDING,
  EmployerStaffAccountStatus.ACTIVE,
  EmployerStaffAccountStatus.INACTIVE
]

export {
  EmployerStaffAccountEhiAccessLevel,
  EmployerStaffAccountJobsAccessLevel,
  EmployerStaffAccountRole
}
