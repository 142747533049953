import React, { memo, useContext, useEffect, useReducer, useState } from 'react'
import CreateJob from './Create'
import { CreateJobInput, Filter, PaginationState } from 'model'
import { useQuery } from 'gql/hooks'
import { GET_MY_JOBS } from 'gql/query'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import TableContextProvider from 'context/TableContext'
import { Table } from 'components'
import TableRowBuilder from './TableRowBuilder'
import { v4 as uuidv4 } from 'uuid'
import {
  TABLE,
  InitiaPaginationState,
  InitialNewJobInfo,
  InitialJobFilter,
  Status
} from './constants'
import { MicroServiceContext } from 'context/MicroService'
import { AtsContext } from 'context/AtsContext'

const useStyles = makeStyles({
  container: {
    padding: '32px 48px',
    minHeight: 'calc(100vh - 250px - 42px)'
  },
  addButton: {
    height: 40,
    width: 186
  }
})

const Jobs = (): React.ReactElement => {
  const [filteredTable, setFilteredTable] = useState<any[] | undefined>()
  const classes = useStyles()
  const { currentAccount } = useContext(MicroServiceContext)
  const employerId = currentAccount.employer.id
  const [newJobState, dispatchNewJob] = useReducer(
    (formState: CreateJobInput, action: any) => {
      if (action === 'clear') {
        return InitialNewJobInfo
      } else {
        return { ...formState, [action.index]: action.value }
      }
    },
    InitialNewJobInfo
  )

  const [jobFilterState, dispatchFilterState] = useReducer(
    (formState: Filter, action: any) => {
      if (action === 'switch') {
        if (formState.status.state === Status.OPEN.state)
          return { ...formState, status: Status.CLOSED }
        else {
          return { ...formState, status: Status.OPEN }
        }
      } else {
        return { ...formState, [action.index]: action.value }
      }
    },
    InitialJobFilter
  )
  const [paginationState, dispatchPaginationState] = useReducer(
    (state: PaginationState, action: any) => {
      switch (action.type) {
        case 'rowsPerPageChange':
          return {
            currentPage: 0,
            rowsPerPage: action.value,
            cursor: undefined
          }
        case 'reset':
          return {
            currentPage: 0,
            rowsPerPage: state.rowsPerPage,
            cursor: undefined
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    InitiaPaginationState
  )

  const { loading, data, subscribeToMore, updateQuery } = useQuery(
    GET_MY_JOBS,
    {
      variables: {
        first: paginationState.rowsPerPage,
        after: paginationState.cursor,
        orderBy: null,
        orderDirection: null,
        status: jobFilterState.status.state,
        searchTerm: jobFilterState.name
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  )

  useEffect(() => {
    dispatchPaginationState({ type: 'reset' })
  }, [
    jobFilterState.name,
    dispatchPaginationState,
    jobFilterState.status.state
  ])

  const startCreatingNewJob = () => {
    const jobId = uuidv4()
    dispatchNewJob({ index: 'employerId', value: employerId })
    dispatchNewJob({ index: 'jobId', value: jobId })
    dispatchNewJob({ index: 'openCreateJobDialog', value: true })
  }
  const nextPage = () => {
    const { endCursor } = data.myJobs.pageInfo
    dispatchPaginationState({ index: 'cursor', value: endCursor })
  }

  const { isAtsSetup, loading: isAtsLoading } = useContext(AtsContext)

  useEffect(() => {
    // do nothing until the variable is resolved
    if (isAtsLoading) return

    const heads = isAtsSetup? TABLE.TABLE_HEADS :
      TABLE.TABLE_HEADS.filter(t => t.id !== 'internal_title')

    setFilteredTable(heads)
  }, [isAtsLoading, isAtsSetup])

  // If this page is loaded directly (i.e. is the first page the app loads)
  // `isAtsSetup` might be still loading (and hence `undefined`). This can
  // cause the `filteredTable` state to trigger a render in which a new column
  // is added, causing some unpleasant visual experience.
  if (filteredTable === undefined)
    return (<></>)

  return (
    <div className={classes.container}>
      <TableContextProvider
        searchBoxPlaceholder='Search for a job'
        emptyTableTitle='Create your first job to get started'
        emptyTableText={
          'You can add, remove and manage your jobs and pipeline settings here'
        }
        emptyTableButton={
          <Button
            variant='contained'
            className={classes.addButton}
            onClick={() => {
              startCreatingNewJob()
            }}
          >
            Create a job
          </Button>
        }
        tableTitleAction={() => {
          startCreatingNewJob()
        }}
        tableHeads={filteredTable}
        tableTitle='Jobs'
        createButtonText='Create a job'
        TableRowBuilder={(props: any) => (
          <TableRowBuilder
            {...props}
            updateQuery={updateQuery}
            status={jobFilterState.status}
          />
        )}
      >
        <Table
          loading={loading}
          filterState={jobFilterState}
          data={
            loading === false
              ? data?.myJobs?.edges.slice(
                  paginationState.currentPage * paginationState.rowsPerPage,
                  paginationState.currentPage * paginationState.rowsPerPage +
                    paginationState.rowsPerPage
                )
              : []
          }
          dispatch={dispatchFilterState}
          count={data?.myJobs?.count || 0}
          paginationState={paginationState}
          setPaginationState={dispatchPaginationState}
          fetchMore={nextPage}
        />
      </TableContextProvider>

      <CreateJob
        openCreateJobDialog={newJobState.openCreateJobDialog}
        setOpenCreateJobDialog={(value: boolean) => {
          dispatchNewJob({ index: 'openCreateJobDialog', value: value })
        }}
        jobState={newJobState}
        dispatch={dispatchNewJob}
        subscribeToMore={subscribeToMore}
        jobFilterState={jobFilterState}
      />
    </div>
  )
}

export default memo(Jobs)
