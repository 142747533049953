import { gql } from '@apollo/client'
import {
  PageInfo,
  PaginationInput,
  PaginationType,
  AccountWithName
} from './fragments'

export const GET_EMPLOYER_STAFF_ACCOUNTS = gql`
  query GetEmployerStaffAccounts(
    ${PaginationType}
    $searchTerm: String
    $jobsAccessLevels:[EmployerStaffAccountJobsAccessLevel]
  ) {
    employerStaffAccounts(
      ${PaginationInput}
      searchTerm: $searchTerm
      jobsAccessLevels: $jobsAccessLevels
    ) {
      pageInfo {
        ...pageInfo,
      }
      count
      edges {
        node {
          ...accountWithName
        }
      }
    }
  }
  ${AccountWithName}
  ${PageInfo}
`
