import { gql } from '@apollo/client'

export const IS_ATS_AVAILABLE = gql`
  query IsAtsAvailable { isAtsAvailable }
`

export const LINKED_ACCOUNT  = gql`
  query LinkedAccount { 
    linkedAccount {
      id
      integrationName
      contactEmail
    }
  }
`

export const LINK_TOKEN  = gql`
  query LinkedToken($organizationName: String!) { 
    linkToken(organizationName: $organizationName)
  }
`

export const CREATE_LINKED_ACCOUNT = gql`
  mutation CreateLinkedAccount($publicToken: String!) {
    createLinkedAccount(publicToken: $publicToken) {
      id
      integrationName
      contactEmail
    }
  }
`

export const DELETE_LINKED_ACCOUNT = gql`
  mutation DeleteLinkedAccount {
    deleteLinkedAccount {
      id
      integrationName
      contactEmail
    }
  }
`
