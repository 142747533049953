import React, { useContext, useState, useReducer } from 'react'
import { useStyles } from './style'
import { HashLink } from 'react-router-hash-link'
import { Grid, Typography, Button } from '@mui/material'
import { useQuery } from 'gql/hooks'
import { Color } from 'components'
import { IMG_LOGO_PARAMS } from './constants'
import { GET_CANDIDATES } from 'gql/query'
import { initialReviewInput } from './constants'
import { CandidateReviewInput, PipelineStage } from 'model/jobPipeline'
import ReviewPane from './ReviewPane'
import SelectedProfile from 'pages/Talent/SelectedProfile'
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material'
import { MyJobContext } from 'context/JobContext'

interface Props {
  nanoid: string
}

const ReviewQueue = ({ nanoid }: Props): React.ReactElement => {
  const classes = useStyles()
  const { job } = useContext(MyJobContext)
  const [currentPage, setCurrentPage] = useState(0)

  const [reviewInput, setReviewInput] = useReducer(
    (state: CandidateReviewInput, action: any) => {
      switch (action.type) {
        case 'clearInput':
          return {
            generalFeedback: '',
            structuredFeedback: undefined
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    initialReviewInput
  )

  const { data, refetch, loading } = useQuery(GET_CANDIDATES, {
    variables: {
      first: 1,
      jobId: job!.id,
      stage: PipelineStage.TO_REVIEW,
      imgixParams: IMG_LOGO_PARAMS
    }
  })

  const candidate = data?.candidates.edges[0]?.node
  const totalCount = data?.candidates ? data.candidates.count : 0

  const prevPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1)
      const { startCursor, hasPreviousPage } = data.candidates.pageInfo

      if (hasPreviousPage && currentPage < totalCount) {
        refetch({
          first: null,
          last: 1,
          before: startCursor,
          after: null
        })
      }

      setReviewInput({ type: 'clearInput' })
    }
  }
  const nextPage = () => {
    if (currentPage < totalCount) {
      setCurrentPage(currentPage + 1)
      const { endCursor, hasNextPage } = data.candidates.pageInfo

      if (hasNextPage) {
        refetch({
          first: 1,
          last: null,
          before: null,
          after: endCursor
        })
      }

      setReviewInput({ type: 'clearInput' })
    }
  }

  const handlePostFeedback = () => {
    if (currentPage + 1 === totalCount && currentPage !== 0) {
      prevPage()
    } else if (currentPage < totalCount) {
      setReviewInput({ type: 'clearInput' })
      refetch()
    }
  }

  return (
    <>
      {currentPage === totalCount && !loading ? (
        <Grid
          container
          alignItems='center'
          flexDirection='column'
          className={classes.emptyView}
        >
          <Typography
            variant='h1'
            color={Color.black80}
            lineHeight='46px'
            gutterBottom
          >
            You’re all done!
          </Typography>
          <Typography variant='body2' color={Color.black60} lineHeight='20px'>
            There are no more leads to review here.
          </Typography>
          <Typography variant='body2' color={Color.black60} lineHeight='20px'>
            You can find updates on the pipeline page for this job.
          </Typography>
          <Button
            variant='contained'
            color='secondary'
            className='blackButton'
            component={HashLink}
            to={`/jobs/${nanoid}`}
          >
            Back to pipeline
          </Button>
          <Button
            className='textButton'
            startIcon={<KeyboardBackspaceIcon />}
            onClick={prevPage}
            disabled={totalCount === 0}
          >
            Previous lead
          </Button>
        </Grid>
      ) : (
        <Grid container>
          {candidate && (
            <>
              <ReviewPane
                candidateId={candidate?.id}
                totalCount={totalCount}
                currentPage={currentPage}
                reviewInput={reviewInput}
                setReviewInput={setReviewInput}
                prevPage={prevPage}
                nextPage={nextPage}
                disabled={candidate?.stage !== PipelineStage.TO_REVIEW}
                handlePostFeedback={handlePostFeedback}
              />

              <Grid className={classes.profileSection}>
                <SelectedProfile
                  profileData={candidate.profile}
                  currentState={candidate.stage}
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  )
}

export default ReviewQueue
