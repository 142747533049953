import React, { memo } from 'react'
import { Color } from 'components'
import { MenuItem, useStyles } from '../../style'
import { FormControl, Select, Typography } from '@mui/material'
import { MOVE_CANDIDATE_OPTIONS } from '../../constants'

interface Props {
  fullwidth?: boolean
  selected: any
  setSelected: (selected: any) => void
  currentStage?: string
}

const MoveSelector = ({
  selected,
  setSelected,
  currentStage
}: Props): React.ReactElement => {
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelected(event.target.value)
  }
  const index = MOVE_CANDIDATE_OPTIONS.findIndex(
    (item: any) => item.value === currentStage
  )
  const options =
    index === 2
      ? MOVE_CANDIDATE_OPTIONS.slice(0, 2)
      : MOVE_CANDIDATE_OPTIONS.slice(index + 1)
  return (
    <FormControl fullWidth>
      <Select
        value={selected}
        displayEmpty
        onChange={() => handleChange}
        renderValue={selected => {
          if (selected) {
            const option = options.find(
              (option: any) => option.value === selected
            )
            return <Typography color={Color.black}>{option?.tag}</Typography>
          }
          return <Typography color='#505C63'>Select</Typography>
        }}
        MenuProps={{
          classes: {
            paper: classes.selectMenuItem,
            list: classes.selectMenuList
          }
        }}
      >
        {options.map((option: any, index: number) => {
          return (
            <MenuItem
              value={option.value}
              key={`item_${index}`}
              onClick={() => setSelected(option.value)}
            >
              {option.tag}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default memo(MoveSelector)
