import React from 'react'
import { Color } from 'components'
import { Typography, Grid } from '@mui/material'
import { Affiliation } from 'model/profile'
import LinkedGroups from './LinkedGroups'

interface Props {
  affiliations: Affiliation[]
  main?: boolean
}

const AffiliationsDetail = ({ affiliations }: Props): React.ReactElement => {
  return (
    <Grid
      container
      justifyContent='space-between'
      spacing={3}
      paddingBottom={3}
    >
      {affiliations.map((affiliation: Affiliation, index: number) => {
        const linkedinGroups = affiliation.linkedinGroups
        return (
          <Grid item xs={6} key={`affiliation_${index}`}>
            <Typography
              variant='button'
              color={Color.black87}
              gutterBottom
              fontWeight={500}
            >
              {affiliation.name}
            </Typography>

            {linkedinGroups.length < 2 ? (
              <Typography
                key={`linkedinGroup_${index}`}
                variant='body2'
                color={Color.grey700}
              >
                {linkedinGroups[0]?.name}
              </Typography>
            ) : (
              <>
                <Typography
                  key={`linkedinGroup_${index}`}
                  variant='body2'
                  color={Color.grey700}
                >
                  {[linkedinGroups[0].name, linkedinGroups[1].name].join(' • ')}
                </Typography>
                <LinkedGroups linkedinGroups={linkedinGroups.slice(2)} />
              </>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default AffiliationsDetail
