import React, { useState } from 'react'
import {
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
  Backdrop,
  IconButton,
  Grid
} from '@mui/material'
import { Color, UserAvatar } from 'components'
import { toCapitalizeWithSpace } from 'utils/stringHelper'
import Selector from './Selector'
import { CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS } from 'gql/command'
import { EMPLOYER_STAFF_ACCOUNT_PERMISSIONS_CHANGED } from 'gql/subscription'
import {
  ROLE_OPTIONS,
  JOBS_ACCESS_OPTIONS,
  EHI_ACCESS_OPTIONS,
  EmployerStaffAccountRole,
  EmployerStaffAccountJobsAccessLevel,
  EmployerStaffAccountEhiAccessLevel
} from './constants'
import { useMutation } from 'gql/hooks'
import {
  EmployerStaffAccount,
  EmployerStaffAccountStatus
} from 'model/organization'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import StaffMenu from './StaffMenu'

interface Props {
  data: EmployerStaffAccount
  isInEditMode: boolean
  subscribeToMore: (opt: any) => void
}

const TableRowBuilder = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState()
  const { data, isInEditMode, subscribeToMore } = props
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [changePermission] = useMutation(
    CHANGE_EMPLOYER_STAFF_ACCOUNT_PERMISSIONS
  )

  const { user, ehiAccess, isUsingSeat, jobsAccess, role, email } = data
  const startSubscription = () => {
    setIsBackdropOpen(true)
    subscribeToMore({
      document: EMPLOYER_STAFF_ACCOUNT_PERMISSIONS_CHANGED,
      variables: {
        employerStaffAccountId: data.id
      },
      onComplete: () => {
        setIsBackdropOpen(false)
      },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        const { employerStaffAccount } =
          subscriptionData.data.employerStaffAccountPermissionsChanged
        return Object.assign({}, prev, {
          ...prev.employerStaffAccounts,
          edges: [
            {
              ...prev.employerStaffAccounts.edges.find(
                (node: any) => node.node.id === data.id
              ),
              isUsingSeat: employerStaffAccount.isUsingSeat,
              jobsAccess: employerStaffAccount.jobsAccess,
              ehiAccess: employerStaffAccount.ehiAccess,
              role: employerStaffAccount.role
            },
            ...prev.employerStaffAccounts.edges
          ]
        })
      }
    })
  }
  const selectRole = async (value: string) => {
    startSubscription()
    if (value === EmployerStaffAccountRole.BASIC_USER) {
      const result = await changePermission({
        variables: {
          input: {
            employerStaffAccountId: data.id,
            role: value,
            jobsAccess: EmployerStaffAccountJobsAccessLevel.NO_JOBS_ACCESS,
            ehiAccess: EmployerStaffAccountEhiAccessLevel.NO_EHI_ACCESS
          }
        }
      })
      if (!result.data) {
        setIsBackdropOpen(false)
      }
    } else {
      const result = await changePermission({
        variables: {
          input: {
            employerStaffAccountId: data.id,
            role: value,
            jobsAccess: EmployerStaffAccountJobsAccessLevel.JOBS_LEAD,
            ehiAccess: EmployerStaffAccountEhiAccessLevel.FULL_EHI_ACCESS
          }
        }
      })
      if (!result.data) {
        setIsBackdropOpen(false)
      }
    }
  }
  const selectJobsAccess = async (value: string) => {
    startSubscription()
    const result = await changePermission({
      variables: {
        input: {
          employerStaffAccountId: data.id,
          role: role,
          jobsAccess: value,
          ehiAccess: ehiAccess
        }
      }
    })
    if (!result.data) {
      setIsBackdropOpen(false)
    }
  }
  const selectEHIAccess = async (value: string) => {
    startSubscription()
    const result = await changePermission({
      variables: {
        input: {
          employerStaffAccountId: data.id,
          role: role,
          jobsAccess: jobsAccess,
          ehiAccess: value
        }
      }
    })
    if (!result.data) {
      setIsBackdropOpen(false)
    }
  }

  return (
    <>
      <StaffMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        selectedStaffAccountId={data.id}
      />
      <TableRow>
        <TableCell>
          <Grid container wrap='nowrap'>
            <UserAvatar
              username={
                data.status === EmployerStaffAccountStatus.PENDING
                  ? email
                  : user?.name
                  ? user.name
                  : email
              }
            />
            <Grid marginLeft={2} item>
              <Typography
                variant='body2Medium'
                color={Color.main}
                textOverflow={'ellipsis'}
              >
                {user?.name || email || 'Unknown'}
              </Typography>
              <Typography
                variant='body2'
                lineHeight='20px'
                color={Color.black}
                fontWeight={300}
              >
                {toCapitalizeWithSpace(data.status) || 'Unknown'}
              </Typography>
            </Grid>
          </Grid>

          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={isBackdropOpen}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        </TableCell>
        <TableCell>
          {isInEditMode ? (
            <Selector
              options={ROLE_OPTIONS}
              select={selectRole}
              selected={role}
            />
          ) : (
            <Typography variant='body2' color={Color.black80}>
              {toCapitalizeWithSpace(role)}
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {isInEditMode ? (
            <Selector
              options={JOBS_ACCESS_OPTIONS}
              select={selectJobsAccess}
              selected={jobsAccess}
            />
          ) : (
            <Typography variant='body2' color={Color.black80}>
              {toCapitalizeWithSpace(jobsAccess)}
            </Typography>
          )}
        </TableCell>
        <TableCell align='left'>
          {isInEditMode ? (
            <Selector
              options={EHI_ACCESS_OPTIONS}
              select={selectEHIAccess}
              selected={ehiAccess}
            />
          ) : (
            <Typography variant='body2' color={Color.black80}>
              {toCapitalizeWithSpace(ehiAccess)}
            </Typography>
          )}
        </TableCell>
        <TableCell align='left'>
          <Typography variant='body2'>{isUsingSeat ? 'Yes' : 'No'}</Typography>
        </TableCell>
        <TableCell
          align='right'
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          {data.status === EmployerStaffAccountStatus.PENDING && (
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation()
                setAnchorEl(e.currentTarget)
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

export default TableRowBuilder
