import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  overviewContainer: { padding: 24 },
  profile: {
    marginTop: 24
  },
  name: {
    marginTop: 4,
    marginBottom: 4
  },
  infoBar: {
    marginTop: 48,
    marginBottom: 32
  },
  atsBanner: {
    marginTop: 16,
    height: '64px'
  },
  dialogButtons: {
    height: 44,
    width: 106,
    '&:not(:first-of-type)': { marginLeft: 32 }
  },
  disconnectButton: {
    color:  '#050B0E',
    fontWeight: 400,
    fontSize: 16
  },
  avatarPlaceholder: { width: 88, height: 88 },
  fileUploader: { width: 176, position: 'relative' },
  profileTitle: { marginTop: 12 },
  cameraIconButton: { height: 32, width: 32 },

  cameraIcon: {
    position: 'absolute',
    left: 56,
    top: 56,
    width: 32,
    height: 32,
    background: '#FFFFFF',
    border: '1px solid #E0E7EB',
    boxShadow:
      '0px 1px 2px rgba(97, 116, 128, 0.2), 0px 2px 4px rgba(176, 186, 191, 0.12)',
    borderRadius: 200
  },
  loginSetting: {
    marginTop: 16,
    maxWidth: 570
  },
  loginSettingTitle: {
    marginBottom: 4
  },
  clickableText: {
    cursor: 'pointer',
    width: 28
  },
  pointer: {
    cursor: 'pointer'
  }
}))
export default useStyles
