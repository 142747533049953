import Components from '../importHandler'

export const {
  InfoIcon,
  SuccessIcon,
  DashboardIcon,
  EditIcon,
  EmptyTable,
  FormField,
  theme,
  EmployerPartnersIcon,
  StatusDot,
  CameraIcon,
  LeadsIcon,
  SettingsIcon,
  TalentServicesIcon,
  Color,
  TextDivider,
  GoogleIcon,
  LinkedInIcon,
  Office365Icon,
  Paper,
  Snackbar,
  SocialMedia,
  FileUploader,
  UserAvatar,
  MailIcon,
  EducationIcon,
  ExperienceIcon,
  Dialog,
  DialogWithoutAction,
  Table,
  TableContext,
  useDebounceInput,
  TableContextProvider,
  ErrorMessage,
  ExportIcon,
  ViewIcon,
  ErrorIcon,
  PendingIcon,
  NotificationContext,
  NotificationProvider,
  CheckIcon,
  MathisonIcon,
  LinkedInVector,
  ChevronRightIcon,
  Chip,
  SingleCheckIcon,
  DoubleCheckIcon,
  CrossIcon,
  LinkIcon,
  DeleteIcon,
  InfoBanner,
  Tooltip,
  TabPanel,
  AddIcon
} = Components
