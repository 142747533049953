import React from 'react'
import { Image as CmsImage, ResponsiveImageType } from 'react-datocms'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

interface CoverImageProps {
  responsiveImage: ResponsiveImageType
  title?: string
  url?: string
  fullSizeImage?: boolean
  slug?: string
  isSlide?: boolean
  width?: number
  height?: number
}

interface ImageWrapperProps extends BoxProps {
  isSlide?: boolean
  fullSizeImage?: boolean
  width?: number
  height?: number
}

const ImageWrapper = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'isSlide' &&
    prop !== 'width' &&
    prop !== 'height' &&
    prop !== 'fullSizeImage'
})<ImageWrapperProps>(({ isSlide, fullSizeImage, width, height }) => ({
  ...(isSlide && {
    margin: '0 10% 40px 10%'
  }),
  ...(fullSizeImage && {
    margin: '40px 0'
  }),
  ...(width &&
    width < 1460 &&
    fullSizeImage &&
    height && {
      width: width,
      height: height,
      margin: 'auto'
    })
}))

type CmsImagePropTypes = {
  data: ResponsiveImageType
  className?: string
  pictureClassName?: string
  fadeInDuration?: number
  intersectionThreshold?: number
  intersectionMargin?: string
  lazyLoad?: boolean
  style?: React.CSSProperties
  pictureStyle?: React.CSSProperties
  explicitWidth?: boolean
}

interface StyledCmsImageProps extends CmsImagePropTypes {
  fullSizeImage?: boolean
}

const StyledCmsImage = styled(CmsImage, {
  shouldForwardProp: prop => prop !== 'fullSizeImage'
})<StyledCmsImageProps>(({ fullSizeImage }) => ({
  borderRadius: '8px',
  ...(fullSizeImage && {
    borderRadius: 'none'
  })
}))

export default function CoverImage({
  title,
  responsiveImage,
  isSlide,
  fullSizeImage,
  width,
  height
}: CoverImageProps) {
  return (
    <div className='-mx-5 sm:mx-0 cover-image'>
      <Box>
        <ImageWrapper
          className='image-wrapper'
          isSlide={isSlide}
          width={responsiveImage?.width ?? width}
          height={responsiveImage?.height ?? height}
          fullSizeImage={fullSizeImage}
        >
          <StyledCmsImage
            fullSizeImage={fullSizeImage}
            data={{
              ...responsiveImage,
              alt: `Cover Image for ${title}`
            }}
            className='customCard'
          />
        </ImageWrapper>
      </Box>
    </div>
  )
}
