import React, { useState, useContext } from 'react'
import { Typography, Tabs, Tab, Grid } from '@mui/material'
import useStyles from './style'
import { Color, Paper, UserAvatar, TabPanel } from 'components'
import Overview from './Overview'
import StaffMember from './Staff'
import { MyTabs } from './constants'
import { INITIAL_EMPLOYER, IMG_LOGO_PARAMS } from './Overview/constants'
import { useQuery } from 'gql/hooks'
import { GET_MY_EMPLOYER } from 'gql/query'
import { MicroServiceContext } from 'context/MicroService'

const Organization = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const { currentAccount } = useContext(MicroServiceContext)
  const {
    data: employerData = INITIAL_EMPLOYER,
    refetch,
    subscribeToMore
  } = useQuery(GET_MY_EMPLOYER, {
    variables: { imgixParams: IMG_LOGO_PARAMS },
    subscriptionTimeout: 30000
  })
  const { openContactDialog } = useContext(MicroServiceContext)

  const { myEmployer, numEmployerStaffAccounts } = employerData
  const { impactManager } = currentAccount.employer
  const { numOpenSeats, numUsedSeats } = myEmployer
  return (
    <div className={classes.container}>
      <Typography variant='h1' color={Color.black}>
        Organization settings
      </Typography>
      <Tabs
        value={selectedTab}
        className={classes.tabs}
        textColor='secondary'
        onChange={(_e, value) => {
          setSelectedTab(value)
        }}
        indicatorColor='secondary'
        aria-label='basic tabs example'
      >
        <Tab
          label={
            <Typography variant='body2Bold' fontWeight='500'>
              Overview
            </Typography>
          }
        />
        <Tab
          label={
            <Typography variant='body2Bold' fontWeight='500'>
              Staff members
            </Typography>
          }
        />
      </Tabs>
      <TabPanel value={selectedTab} index={MyTabs.Overview}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
              <Overview
                myEmployer={myEmployer}
                subscribeToMore={subscribeToMore}
                refetch={refetch}
              />
          </Grid>
          <Grid item xs={3}>
            <Paper className={classes.impactManagerContainer}>
              <Typography variant='h5' fontWeight='500'>
                Need help?
              </Typography>
              <Typography
                variant='body2'
                color={Color.black60}
                marginBottom='32px'
                marginTop='8px'
              >
                Get in touch with your Impact Manager
              </Typography>
              <Grid container>
                <Grid item xs={2}>
                  <UserAvatar username={impactManager.name}></UserAvatar>
                </Grid>
                <Grid item xs>
                  <Typography variant='body2' color={Color.black}>
                    {impactManager.name}
                  </Typography>
                  <Typography
                    variant='body1Medium'
                    color={Color.main}
                    className={classes.pointer}
                    onClick={() => openContactDialog()}
                  >
                    {impactManager.email}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={selectedTab} index={MyTabs.StaffMembers}>
        <StaffMember
          numEmployerStaffAccounts={numEmployerStaffAccounts}
          numOpenSeats={numOpenSeats}
          numUsedSeats={numUsedSeats}
        />
      </TabPanel>
    </div>
  )
}
export default Organization
