import { makeStyles } from '@mui/styles'
import { Color } from 'components'

export const useStyles = makeStyles(() => ({
  jobSettingContainer: {
    width: 600,
    margin: 'auto',
    height: '100%',
    paddingTop: 32
  },
  closeJobButton: {
    float: 'right'
  },
  statusContainer: {
    lineHeight: '35px',
    fontWeight: 400,
    fontFamily: 'Roboto'
  },
  dialogButtons: {
    height: 44,
    width: 180,
    '&:not(:first-of-type)': { marginLeft: 32 }
  },
  menuItem: {
    borderRadius: '0 !important',
    justifyContent: 'space-between !important',
    '& .removeButton': {
      color: Color.black,
      opacity: 0
    },
    '&:hover': {
      '& .removeButton': {
        opacity: 1
      }
    }
  },
  autoComplete: { padding: 0, marginBottom: 24 },
  input: { height: 44 }
}))
