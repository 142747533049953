import React from 'react'
import { useStyles, Chip } from '../style'
import { Color, Paper } from 'components'
import { CardContent, Typography, Grid } from '@mui/material'

interface Props {
  skills: string[]
}

const Skills = ({ skills }: Props): React.ReactElement => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <CardContent>
        <Typography
          variant='h4'
          color={Color.black87}
          paddingBottom={3}
          fontWeight='500'
        >
          Skills
        </Typography>
        <Grid container spacing={1}>
          {skills &&
            skills.map((skill: string, index: number) => (
              <Grid item key={`skill_${index}`}>
                <Chip
                  label={
                    <Typography
                      variant='body2'
                      lineHeight='20px'
                      color='primary'
                    >
                      {skill}
                    </Typography>
                  }
                  variant='outlined'
                  color='primary'
                />
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Paper>
  )
}

export default Skills
