import React, { useState } from 'react'
import { Typography, Grid, Button, ButtonProps } from '@mui/material'
import { Color } from 'components'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { FilterIndex } from '../constants'
import useStyles from './style'
import {
  ROLE_OPTIONS,
  JOBS_ACCESS_OPTIONS,
  EHI_ACCESS_OPTIONS,
  STATUS_OPTIONS
} from '../TableRowBuilder/constants'
import Dialogs from './dialogs'
import { styled } from '@mui/material/styles'

interface Props {
  filterState: any
  dispatch: (data: any) => void
}

interface FilterButtonProps extends ButtonProps {
  defaultState?: boolean
}

const FilterButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'defaultState'
})<FilterButtonProps>(({ defaultState }) => ({
  height: 32,
  paddingLeft: 0,
  paddingRight: 0,
  borderRadius: 20,
  borderColor: Color.main,
  border: defaultState ? `1px solid ${Color.black}` : '',
  color: defaultState ? Color.black : ''
}))
const SecondaryFilters = (props: Props) => {
  const { filterState, dispatch } = props
  const [isUsingSeatAnchorEl, setIsUsingSeatAnchorEl] = React.useState<
    undefined | HTMLElement
  >()
  const [roleAnchorEl, setRoleAnchorEl] = React.useState<
    undefined | HTMLElement
  >()
  const [editingType, setEditingType] = useState('')

  const [items, setItems] = useState<any[]>([])
  const classes = useStyles()
  return (
    <>
      <Grid container item className={classes.container} spacing={2}>
        <Grid item className={classes.noPaddingTop}>
          <FilterButton
            variant='outlined'
            defaultState={filterState.statuses.length === 0}
            onClick={(e: any) => {
              setRoleAnchorEl(e.currentTarget)
              setEditingType(FilterIndex.statuses)
              setItems(STATUS_OPTIONS)
            }}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowDropDownIcon className={classes.defaultStateIcon} />}
          >
            <Typography
              className={classes.filterLabel}
              color={Color.black80}
              variant='body2'
            >
              Status
            </Typography>
            <Typography
              className={classes.filterValue}
              color={Color.black80}
              variant='body2'
            >
              {filterState.statuses.length > 0 && (
                <span className={classes.roundDot}>
                  {filterState.statuses.length}
                </span>
              )}
            </Typography>
          </FilterButton>
        </Grid>
        <Grid item className={classes.noPaddingTop}>
          <FilterButton
            variant='outlined'
            defaultState={filterState.role.length === 0}
            onClick={(e: any) => {
              setRoleAnchorEl(e.currentTarget)
              setEditingType(FilterIndex.role)
              setItems(ROLE_OPTIONS)
            }}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowDropDownIcon className={classes.defaultStateIcon} />}
          >
            <Typography
              className={classes.filterLabel}
              color={Color.black80}
              variant='body2'
            >
              Role
            </Typography>
            <Typography
              className={classes.filterValue}
              color={Color.black80}
              variant='body2'
            >
              {filterState.role.length > 0 && (
                <span className={classes.roundDot}>
                  {filterState.role.length}
                </span>
              )}
            </Typography>
          </FilterButton>
        </Grid>
        <Grid item className={classes.noPaddingTop}>
          <FilterButton
            variant='outlined'
            defaultState={filterState.jobAccess.length === 0}
            onClick={(e: any) => {
              setRoleAnchorEl(e.currentTarget)
              setEditingType(FilterIndex.jobAccess)
              setItems(JOBS_ACCESS_OPTIONS)
            }}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowDropDownIcon className={classes.defaultStateIcon} />}
          >
            <Typography
              className={classes.filterLabel}
              color={Color.black80}
              variant='body2'
            >
              Job access
            </Typography>
            <Typography
              className={classes.filterValue}
              color={Color.black80}
              variant='body2'
            >
              {filterState.jobAccess.length > 0 && (
                <span className={classes.roundDot}>
                  {filterState.jobAccess.length}
                </span>
              )}
            </Typography>
          </FilterButton>
        </Grid>
        <Grid item className={classes.noPaddingTop}>
          <FilterButton
            variant='outlined'
            defaultState={filterState.ehiAccess.length === 0}
            onClick={(e: any) => {
              setRoleAnchorEl(e.currentTarget)
              setEditingType(FilterIndex.ehiAccess)
              setItems(EHI_ACCESS_OPTIONS)
            }}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowDropDownIcon className={classes.defaultStateIcon} />}
          >
            <Typography
              className={classes.filterLabel}
              color={Color.black80}
              variant='body2'
            >
              EI access
            </Typography>
            <Typography
              className={classes.filterValue}
              color={Color.black80}
              variant='body2'
            >
              {filterState.ehiAccess.length > 0 && (
                <span className={classes.roundDot}>
                  {filterState.ehiAccess.length}
                </span>
              )}
            </Typography>
          </FilterButton>
        </Grid>
        <Grid item className={classes.noPaddingTop}>
          <FilterButton
            variant='outlined'
            defaultState={true}
            onClick={(e: any) => {
              setIsUsingSeatAnchorEl(e.currentTarget)
            }}
            classes={{ endIcon: classes.endIcon }}
            endIcon={<ArrowDropDownIcon className={classes.defaultStateIcon} />}
          >
            <Typography
              className={classes.filterLabel}
              color={Color.black80}
              variant='body2'
            >
              Using seat:
            </Typography>
            <Typography
              className={classes.filterValue}
              color={Color.black80}
              variant='body2'
            >
              {filterState.isUsingSeat !== undefined
                ? filterState.isUsingSeat === true
                  ? 'Yes'
                  : 'No'
                : 'All'}
            </Typography>
          </FilterButton>
        </Grid>
      </Grid>
      <Dialogs
        filterState={filterState}
        dispatch={dispatch}
        items={items}
        editingType={editingType}
        setRoleAnchorEl={setRoleAnchorEl}
        setIsUsingSeatAnchorEl={setIsUsingSeatAnchorEl}
        isUsingSeatAnchorEl={isUsingSeatAnchorEl}
        roleAnchorEl={roleAnchorEl}
      />
    </>
  )
}

export default SecondaryFilters
