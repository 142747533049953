import { useQuery as useApolloQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { OperationVariables } from '@apollo/client/core'
import { Subscription } from 'model/apollo'
export const useQuery: any = (
  document: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: QueryHookOptions<any, OperationVariables> & Subscription
) => {
  const result = useApolloQuery(document, { ...options })

  return result
}
