import { createContext } from 'react'

type FullScreenDialogContextData = {
  openJobSetting?: boolean
  setOpenJobSetting?: (openJobSetting: boolean) => void
  openBatchRequest?: boolean
  setOpenBatchRequest?: (openBatchRequest: boolean) => void
}
export const FullScreenDialogContext =
  createContext<FullScreenDialogContextData>({
    openJobSetting: false,
    setOpenJobSetting: undefined,
    openBatchRequest: false,
    setOpenBatchRequest: undefined
  })
export default FullScreenDialogContext
