import React, { useState } from 'react'
import clsx from 'clsx'
import { formatDateInterval } from 'utils/timeFormat'
import { useStyles } from '../../style'
import { Color, ExperienceIcon } from 'components'
import { Typography, Grid } from '@mui/material'
import { WorkRecord } from 'model/profile'

interface Props {
  workRecords: WorkRecord[]
  main?: boolean
}

const ExperienceDetail = ({ workRecords, main }: Props): React.ReactElement => {
  const classes = useStyles()
  const [showMore, setShowMore] = useState<number[]>([])

  const isHidden = (index: number) => {
    return showMore.indexOf(index) === -1
  }
  const handleShowMoreOrLess = (index: number) => {
    const newShowMore = [...showMore]
    if (isHidden(index)) {
      newShowMore.push(index)
      setShowMore(newShowMore)
    } else {
      newShowMore.splice(newShowMore.indexOf(index), 1)
      setShowMore(newShowMore)
    }
  }

  return (
    <>
      {workRecords.map((workRecord: WorkRecord, index: number) => {
        const id = main ? `main${index.toString()}` : `sub${index.toString()}`
        return (
          <Grid container key={`workRecord_${index}`} paddingBottom={4}>
            <Grid item paddingRight={2}>
              <ExperienceIcon />
            </Grid>
            <Grid item xs>
              <Typography variant='body1' color={Color.black} gutterBottom>
                {workRecord.job}
              </Typography>
              <Typography
                variant='body2'
                color={Color.grey700}
                lineHeight='20px'
              >
                {workRecord.organization}
                {workRecord.location && ` • ${workRecord.location}`}
              </Typography>
              <Typography variant='body2' color={Color.grey700} gutterBottom>
                {formatDateInterval(workRecord.startDate, workRecord.endDate)}
              </Typography>
              <Typography
                id={id}
                gutterBottom
                variant='body2'
                lineHeight='20px'
                color={Color.black}
                className={clsx({
                  [classes.seeLess]: isHidden(index)
                })}
              >
                {workRecord.description}
              </Typography>

              {document.getElementById(id)?.scrollHeight! > 60 && (
                <Typography
                  color='primary'
                  variant='body2Medium'
                  onClick={() => handleShowMoreOrLess(index)}
                  className={classes.seeMoreButton}
                >
                  {isHidden(index) ? 'See more ' : 'See less'}
                </Typography>
              )}
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

export default ExperienceDetail
