import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  cancelButton: { marginRight: 24 },
  buttons: {
    width: 130,
    height: 44,
    '&:not(:first-of-type)': { marginLeft: 32 }
  },
  candidatesNum: {
    marginBottom: 0,
    lineHeight: '28px',
    textAlign: 'center',
    width: '32px',
    borderRadius: '8px'
  },
  lastWeekCandidatesNum: {
    background: '#E9F2ED',
    color: '#217D47'
  },

  lastWeekCandidatesZero: {
    background: '#DCEDF6',
    color: '#0079B6'
  },
  infoIcon: {
    width: 18,
    height: 18,
    position: 'relative',
    top: 3
  }
}))
export default useStyles
