import {
  Typography,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton
} from '@mui/material'
import React, { memo, useContext } from 'react'
import { useStyles } from './style'
import { Color } from 'components'
import { RequestBatchContext } from 'context/RequestBatch'
import { FullScreenDialogContext } from 'context/FullScreenDialog'
import { JobTypeValue, BatchDeliveryPreference } from 'model/jobPipeline'
import { Close as CloseIcon } from '@mui/icons-material'

interface Props {
  step: number
  setStep: (step: number) => void
}
const BatchSize = ({ step, setStep }: Props): React.ReactElement => {
  const classes = useStyles()
  const { setOpenBatchRequest } = useContext(FullScreenDialogContext)
  const { requestBatchInput, setRequestBatchInput, batchDeliveryPreferences } =
    useContext(RequestBatchContext)
  let options = [] as any[]
  if (requestBatchInput.jobType === JobTypeValue.SENIOR) {
    options = batchDeliveryPreferences.filter(
      (preferences: BatchDeliveryPreference) =>
        preferences.availableOnTypes.includes(JobTypeValue.SENIOR)
    )
  } else {
    options = batchDeliveryPreferences
  }

  return (
    <Grid className={classes.batchSize}>
      <Grid container position='relative' marginBottom={3}>
        <Typography variant='h1' lineHeight='46px' color={Color.black}>
          How would you like this batch delivered?
        </Typography>
        <IconButton
          onClick={() => setOpenBatchRequest!(false)}
          className='closeButton'
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      {options.map((item: BatchDeliveryPreference, index: number) => {
        return (
          <ListItem
            button
            key={`job_type-${index}`}
            className={classes.selection}
            classes={{ selected: classes.selected }}
            selected={requestBatchInput.batchSizeName === item.name}
            onClick={() =>
              setRequestBatchInput({
                type: 'setBatchDeliveryPreferences',
                batchSizeName: item.name,
                batchDeliveryPreferenceId: item.id
              })
            }
          >
            <ListItemText
              primary={
                <Grid container>
                  <Typography
                    variant='body1High'
                    color={Color.black80}
                    className='textMargin'
                  >
                    {item.name}
                  </Typography>
                </Grid>
              }
            />
          </ListItem>
        )
      })}
      <Grid container justifyContent={'space-between'} paddingTop={'52px'}>
        <Button
          variant='outlined'
          color='secondary'
          className={classes.backButton}
          onClick={() => setStep(step - 1)}
        >
          Back
        </Button>
        <Button
          variant='contained'
          className={classes.nextButton}
          disabled={requestBatchInput.batchSizeName === ''}
          onClick={() => setStep(step + 1)}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  )
}

export default memo(BatchSize)
