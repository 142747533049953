import { format } from 'date-fns-tz'
import { parseISO } from 'date-fns'

export const formatDateInterval = (start?: string, end?: string) => {
  if (end && start) {
    return `${format(parseISO(start), 'MMM yyyy', {
      timeZone: 'America/Los_Angeles'
    })} - ${format(parseISO(end), 'MMM yyyy', {
      timeZone: 'America/Los_Angeles'
    })}`
  } else {
    return start
      ? `${format(parseISO(start), 'MMM yyyy', {
          timeZone: 'America/Los_Angeles'
        })} - Present`
      : 'Present'
  }
}

export const formatDate = (date?: string, outputFormat?: string) => {
  return date
    ? `${format(parseISO(date), outputFormat || 'MMM d, yyyy', {
        timeZone: 'America/Los_Angeles'
      })}`
    : ''
}
