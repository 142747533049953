import { makeStyles, withStyles } from '@mui/styles'
import { Color } from 'components'
import {
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Chip as MuiChip
} from '@mui/material'
export const useStyles = makeStyles(() => ({
  sectionContainer: {
    margin: 'auto',
    padding: 32
  },
  fixedWidth: {
    width: '63%',
    minWidth: 912
  },
  paper: {
    marginBottom: 24
  },
  hidden: {
    visibility: 'hidden'
  },
  seeLess: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    lineClamp: 3,
    webkitLineClamp: 3,
    maxHeight: '60px'
  },
  seeMoreButton: {
    cursor: 'pointer',
    marginTop: 8
  },
  generalInfo: {
    '& .avatarContainer': {
      minHeight: 164,
      minWidth: 164
    },
    '& .nameText': {
      fontSize: 30,
      lineHeight: '48px',
      fontWeight: 700,
      color: Color.black,
      marginRight: 12
    },
    '& .nameTextHover': {
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& .maskedText': {
      textShadow: '0 0 24px rgba(0,0,0,0.5)',
      color: 'transparent'
    },
    '& .maskedAvatar': {
      backgroundColor: '#D7DCDF',
      width: 140,
      height: 140,
      borderRadius: 86
    },
    '& .linkText': {
      cursor: 'pointer',
      lineHeight: '48px',
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& .icon': {
      marginLeft: 10
    },
    '& .tag': {
      height: 24,
      borderRadius: 20,
      padding: '0 8px'
    }
  },
  affiliations: {
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      width: 120,
      paddingBottom: 24
    },
    '& .infoIcon': {
      color: Color.grey700,
      marginLeft: 6
    }
  },
  about: {
    '& .aboutText': {
      lineHeight: '20px'
    },
    '& .MuiDivider-root': {
      marginTop: 24,
      marginBottom: 24
    },
    '& .socialMedia': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  education: {
    paddingBottom: 32,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  circularProgress: {
    position: 'relative',
    left: 40,
    padding: 15
  },
  errorIcon: {
    color: Color.grey700,
    fontSize: 18,
    marginRight: 10,
    alignSelf: 'center'
  },
  language: { display: 'flex' }
}))

export const AccordionSummary = withStyles({
  root: {
    minHeight: 32,
    height: 32,
    padding: 0,
    marginTop: 6,
    display: 'flex',
    alignItems: 'flex-end',
    '&$expanded': {
      minHeight: 32,
      height: 32
    }
  },
  content: {
    display: 'flex',
    alignItems: 'flex-end',
    '& .contactInfo': {
      margin: '0 8px'
    },
    '& .mailIcon': {
      marginBottom: 3
    },
    '& .toggle': {
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '&$expanded': {
      margin: 0
    },
    margin: 0
  },
  expanded: { minHeight: 32, height: 32 }
})(MuiAccordionSummary)

export const AccordionDetails = withStyles({
  root: {
    marginTop: 12
  }
})(MuiAccordionDetails)

export const Chip = withStyles({
  root: {
    backgroundColor: '#F9FCFD'
  }
})(MuiChip)
