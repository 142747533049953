import React, { useState } from 'react'
import { useStyles } from '../../style'
import { Color, Paper, Tooltip } from 'components'
import { CardContent, Typography, Grid, TypographyProps } from '@mui/material'
import {
  InfoOutlined as InfoOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material'
import { Affiliation } from 'model/profile'
import AffiliationsDetail from './AffiliationsDetail'
import { styled } from '@mui/material/styles'

interface Props {
  affiliations: Affiliation[]
}

const ShowMoreButton = styled(Typography)<TypographyProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontWeight: '500',
  '& .MuiSvgIcon-root': {
    fontSize: 16,
    marginLeft: '8px'
  }
}))
const Affiliations = ({ affiliations }: Props): React.ReactElement => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  return (
    <Paper className={classes.paper}>
      <CardContent className={classes.affiliations}>
        <div className='title'>
          <Typography
            variant='h4'
            color={Color.black87}
            alignItems='center'
            fontWeight='500'
          >
            Affiliations
          </Typography>
          <Tooltip
            title={
              <>
                The affiliations shown here indicate this person may be a member
                or an ally of these communities.
                <br />
                <br />
                Affiliations are based on a mixture of self-reported data from
                the lead, and our proprietary community mappings. These leads
                have indicated publicly that they are a member of or interested
                in these groups, and we map those indications onto our community
                framework.
              </>
            }
            placement='top'
          >
            <InfoOutlinedIcon className='infoIcon' fontSize='small' />
          </Tooltip>
        </div>
        {affiliations && (
          <AffiliationsDetail affiliations={affiliations.slice(0, 4)} />
        )}
        {affiliations.length > 4 && (
          <Grid>
            {expanded && (
              <AffiliationsDetail affiliations={affiliations.slice(4)} />
            )}
            <Grid container alignItems='center'>
              <ShowMoreButton
                variant='button'
                color='primary'
                onClick={() => setExpanded(!expanded)}
              >
                {`Show ${affiliations.length - 4} ${
                  expanded ? 'less' : 'more'
                }`}
                {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ShowMoreButton>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Paper>
  )
}

export default Affiliations
