import React from 'react'
import { makeStyles } from '@mui/styles'
import { Color } from 'components'
import { Typography, Grid } from '@mui/material'
import { formatDate } from 'utils/timeFormat'

const useStyles = makeStyles({
  paper: {
    minWidth: 114,
    height: 68,
    borderRight: '1px solid #E0E7EB',
    '&:last-child': {
      borderRight: 'none'
    },
    '& .content': {
      padding: '0 40px'
    },
    '&:first-child': {
      '& .content': {
        paddingLeft: 0
      }
    }
  },

  dueDate: {
    fontFamily: 'Roboto',
    fontSize: 56,
    lineHeight: '66px',
    letterSpacing: 0.04,
    fontWeight: 700
  }
})

interface Props {
  title: string
  count?: number
  dueDate?: string
  showDueDate?: boolean
}
const Board = ({
  title,
  count,
  dueDate,
  showDueDate
}: Props): React.ReactElement => {
  const classes = useStyles()
  return (
    <div className={classes.paper}>
      <div className='content'>
        <Typography variant='body1High' color={Color.black60}>
          {title}
        </Typography>
        <Grid container alignItems='flex-end'>
          {dueDate ? (
            <Typography
              variant='h2'
              color={Color.black}
              lineHeight={'48px'}
              noWrap
            >
              {showDueDate ? formatDate(dueDate) : '-'}
            </Typography>
          ) : (
            <Typography variant='h2' color={Color.black} lineHeight={'48px'}>
              {count === undefined ? '-' : Math.max(0, count)}
            </Typography>
          )}
        </Grid>
      </div>
    </div>
  )
}

export default Board
