import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  main: {
    minHeight: 'calc(100vh - 250px)'
  },
  dashboardContainer: {
    width: '80%',
    margin: 'auto',
    marginTop: 56
  }
}))
export default useStyles
