import React from 'react'
import { Typography, Menu } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { Color } from 'components'
import { useStyles, MenuItem } from './style'
interface ClickableItem {
  label: string
  onClick: () => void
  icon?: any
}

interface Props {
  anchorEl?: HTMLElement
  setAnchorEl?: (_e?: HTMLElement) => void
  items: ClickableItem[]
  selectedItem?: any
}

const MyMenu = (props: Props) => {
  const { anchorEl, setAnchorEl, items, selectedItem } = props
  const classes = useStyles()

  const handleClose = () => {
    setAnchorEl!(undefined)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      autoFocus
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {items.map((item: ClickableItem) => (
        <MenuItem
          onClick={() => {
            handleClose()
            item.onClick()
          }}
          key={item.label}
        >
          {selectedItem ? (
            <>
              <div className={classes.placeholder}>
                {selectedItem === item.label && (
                  <CheckIcon htmlColor={Color.grey700} fontSize={'small'} />
                )}
              </div>
              <Typography variant='body2' color={Color.black}>
                {item.label}
              </Typography>
            </>
          ) : (
            <>
              {item.icon && <div className={classes.menuIcon}>{item.icon}</div>}
              <Typography variant='body1' color={Color.black}>
                {item.label}
              </Typography>
            </>
          )}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default MyMenu
