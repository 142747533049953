import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext
} from 'react'
import { useLazyQuery } from 'gql/hooks'
import { GET_BATCH_DELIVERY_PREFERENCES } from 'gql/query'
import { MyJobContext } from 'context/JobContext'
import { RequestBatchInput, BatchDeliveryPreference } from 'model/jobPipeline'

type RequestBatchContextData = {
  requestBatchInput: any
  setRequestBatchInput: React.Dispatch<any>
  batchDeliveryPreferences: BatchDeliveryPreference[]
  errors: any
  setIsValidProfileUrl: (value: boolean) => void
  setIsValidDescriptionUrl: (value: boolean) => void
  reachLimit?: boolean
  setReachLimit: (value: boolean) => void
}

export const RequestBatchContext = createContext<RequestBatchContextData>({
  requestBatchInput: {},
  setRequestBatchInput: () => {},
  batchDeliveryPreferences: [],
  errors: {},
  setIsValidProfileUrl: () => {},
  setIsValidDescriptionUrl: () => {},
  reachLimit: undefined,
  setReachLimit: () => {}
})

export const RequestBatchContextProvider = ({ children }: any): JSX.Element => {
  const { job } = useContext(MyJobContext)
  const filteredLocations = [] as any[]
  if (job?.locations) {
    job.locations.map((location: any) => {
      //eslint-disable-next-line
      const { __typename, ...rest } = location
      filteredLocations.push(rest)
    })
  }
  const initialRequestBatchInput = {
    jobType: null,
    batchSizeName: '',
    batchDeliveryPreferenceId: '',
    displayTitle: job?.displayTitle || '',
    description: job?.description || '',
    exampleProfiles: job?.exampleProfiles || [''],
    requiredSkills: job?.requiredSkills || [],
    preferredSkills: job?.preferredSkills || [],
    locations: filteredLocations,
    companiesToInclude: job?.companiesToInclude || [],
    companiesToExclude: job?.companiesToExclude || [],
    commentsAndFeedback: job?.commentsAndFeedback || ''
  } as RequestBatchInput
  const [isValidDescriptionUrl, setIsValidDescriptionUrl] = useState<boolean>()
  const [isValidProfileUrl, setIsValidProfileUrl] = useState(false)
  const [reachLimit, setReachLimit] = useState<boolean>()
  const [requestBatchInput, setRequestBatchInput] = useReducer(
    (state: RequestBatchInput, action: any) => {
      switch (action.type) {
        case 'reset':
          return {
            ...initialRequestBatchInput
          }
        case 'addExampleProfile':
          return {
            ...state,
            exampleProfiles: [...state.exampleProfiles, '']
          }
        case 'changeJobtype':
          return {
            ...state,
            jobType: action.value,
            batchSizeName: '',
            batchDeliveryPreferenceId: ''
          }
        case 'setBatchDeliveryPreferences':
          return {
            ...state,
            batchSizeName: action.batchSizeName,
            batchDeliveryPreferenceId: action.batchDeliveryPreferenceId
          }
        default:
          return { ...state, [action.index]: action.value }
      }
    },
    initialRequestBatchInput
  )

  const errors = {
    displayTitle:
      !requestBatchInput.displayTitle ||
      requestBatchInput.displayTitle.length < 3,
    description: !isValidDescriptionUrl && !reachLimit,
    exampleProfiles:
      !requestBatchInput.exampleProfiles[0] || !isValidProfileUrl,
    requiredSkills: !requestBatchInput.requiredSkills[0],
    preferredSkills: !requestBatchInput.preferredSkills[0],
    locations: !requestBatchInput.locations[0],
    companiesToInclude: !requestBatchInput.companiesToInclude[0],
    companiesToExclude: !requestBatchInput.companiesToExclude[0],
    commentsAndFeedback: !requestBatchInput.commentsAndFeedback
  }

  const [getBatchDeliveryPreferences, { data, called }] = useLazyQuery(
    GET_BATCH_DELIVERY_PREFERENCES
  )
  useEffect(() => {
    if (!called) {
      getBatchDeliveryPreferences()
    }
    // eslint-disable-next-line
  }, [])
  if (!data) return <></>
  // temporarily filter out the unneeded value
  const preferences = data.batchDeliveryPreferences.filter(
    (preferences: BatchDeliveryPreference) =>
      preferences.name !== '10 leads in 7 days'
  )
  return (
    <RequestBatchContext.Provider
      value={{
        requestBatchInput: requestBatchInput,
        setRequestBatchInput: setRequestBatchInput,
        batchDeliveryPreferences: preferences,
        errors: errors,
        setIsValidProfileUrl: setIsValidProfileUrl,
        setIsValidDescriptionUrl: setIsValidDescriptionUrl,
        reachLimit,
        setReachLimit
      }}
    >
      {children}
    </RequestBatchContext.Provider>
  )
}
export default RequestBatchContext
