import React, { memo, useContext } from 'react'
import { Typography } from '@mui/material'
import { Menu, MenuItem, useStyles } from './style'
import { FullScreenDialogContext } from 'context/FullScreenDialog'

interface Props {
  anchorEl: any
  setAnchorEl: (_e: any) => void
}

const JobMenu = (props: Props): React.ReactElement => {
  const classes = useStyles()
  const { anchorEl, setAnchorEl } = props
  const { setOpenJobSetting } = useContext(
    FullScreenDialogContext
  )

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={() => {
        setAnchorEl(undefined)
      }}
    >
      <MenuItem
        className={classes.shortMenuItem}
        onClick={() => {
          setAnchorEl(undefined)
          setOpenJobSetting!(true)
        }}
      >
        <Typography variant='body1'>Job settings</Typography>
      </MenuItem>
    </Menu>
  )
}

export default memo(JobMenu)
