import React, { ReactElement, useState, useEffect } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import SelectedJobOverview from './Overview'
import LeadReview from './LeadReview'
import MyJobContext from 'context/JobContext'
import { useQuery, useMutation } from 'gql/hooks'
import { GET_JOB } from 'gql/query'
import { SET_TALENT_MASKING_FLAG } from 'gql/command'
import NotFound from 'pages/NotFound'
import { Job, JobStatus } from 'model'
import { DocumentNode } from '@apollo/client'
import { BatchStatus } from 'model/jobPipeline'

interface Props {
  disableOriginHeader: (disabled: boolean) => void
}

const SelectedJob = ({ disableOriginHeader }: Props): ReactElement => {
  const params: any = useParams()
  const { nanoid } = params
  const [openNoBatchLeft, setOpenNoBatchLeft] = useState<boolean>(false)
  const { data, loading, subscribeToMore } = useQuery(GET_JOB, {
    variables: {
      nanoid: nanoid
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  })
  const [SetMaskingMutation] = useMutation(SET_TALENT_MASKING_FLAG)

  const [maskingFlag, setMaskingFlag] = useState<boolean>(
    data?.job.talentMaskingFlag
  )

  useEffect(() => {
    if (!loading) {
      setMaskingFlag(data?.job.talentMaskingFlag)
    }
    // eslint-disable-next-line
  }, [data?.job.talentMaskingFlag])

  if (loading) return <></>
  if (!loading && data === undefined) return <NotFound />
  const jobId = data!.job.id

  const handleToggleMasking = () => {
    const newState = !maskingFlag
    setMaskingFlag(newState)
    SetMaskingMutation({
      variables: {
        setTalentMaskingFlagInput: {
          jobId: jobId,
          isMasked: newState
        }
      }
    })
  }

  const startSubscription = (doc: DocumentNode, onComplete?: () => void) => {
    subscribeToMore({
      document: doc,
      variables: {
        id: jobId
      },
      updateQuery: (prev: any, { subscriptionData }: any) => {
        if (subscriptionData.data) {
          if (onComplete) {
            onComplete()
          }
          let job
          if (subscriptionData.data.jobRecruitersChanged) {
            job = {
              ...prev.job,
              recruiters: subscriptionData.data.jobRecruitersChanged
            }
          }
          if (subscriptionData.data.jobFollowersChanged) {
            job = {
              ...prev.job,
              followers: subscriptionData.data.jobFollowersChanged
            }
          }
          if (subscriptionData.data.jobBatchRequested) {
            job = {
              ...prev.job,
              currentBatch: subscriptionData.data.jobBatchRequested
            }
          }
          if (subscriptionData.data.jobClosed) {
            job = { ...subscriptionData.data.jobClosed }
          }
          if (subscriptionData.data.jobReopened) {
            job = { ...subscriptionData.data.jobReopened }
          }
          if (subscriptionData.data.jobRequirementsChanged) {
            job = { ...subscriptionData.data.jobRequirementsChanged }
          }
          return { job }
        }
      }
    })
  }

  const isBatchAlreadyRequested =
    data?.job?.status === JobStatus.OPEN &&
    [BatchStatus.IN_PROGRESS, BatchStatus.REQUESTED].includes(
      data?.job?.currentBatch?.status
    )

  return (
    <MyJobContext.Provider
      value={{
        job: data!.job as Job,
        maskingFlag: maskingFlag,
        setMaskingFlag: setMaskingFlag,
        handleToggleMasking: handleToggleMasking,
        startSubscription: startSubscription,
        numRemainingCreditsForMonth:
          data!.job.employer.numRemainingCreditsForMonth,
        openNoBatchLeft: openNoBatchLeft,
        setOpenNoBatchLeft: setOpenNoBatchLeft,
        isBatchAlreadyRequested: isBatchAlreadyRequested
      }}
    >
      <Routes>
        <Route path='' element={<SelectedJobOverview />} />
        <Route
          path='review-queue'
          element={
            <LeadReview
              nanoid={nanoid}
              disableOriginHeader={disableOriginHeader}
            />
          }
        />
      </Routes>
    </MyJobContext.Provider>
  )
}

export default SelectedJob
