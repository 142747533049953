import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import React, { memo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import AffiliationBuilder from './AffiliationBuilder'
import { ExpandStatus } from 'components'
interface Props {
  affiliations: any
}
const useStyles = makeStyles({
  showMoreItemButton: {
    backgroundColor: 'white',
    width: '100%',
    padding: 0,
    minHeight: 0,
    '&.Mui-expanded': {
      minHeight: 0,
      margin: 0
    },
    '& .showMoreItemText': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  accordionContent: {
    display: 'block',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0
    }
  }
})
const Affiliations = (props: Props): React.ReactElement => {
  const { affiliations } = props
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  return (
    <>
      <Accordion expanded={expanded}>
        <AccordionSummary
          className={classes.showMoreItemButton}
          classes={{
            content: classes.accordionContent,
            expanded: classes.accordionContent,
            root: classes.showMoreItemButton
          }}
        >
          <Grid container>
            {affiliations &&
              affiliations
                .slice(0, 5)
                .map((affiliation: any, index: number) => (
                  <AffiliationBuilder
                    affiliation={affiliation}
                    key={index}
                    last={
                      index === 4 && (affiliation.length === 5 || !expanded)
                    }
                  />
                ))}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {affiliations &&
              affiliations
                .slice(5)
                .map((affiliation: any, index: number) => (
                  <AffiliationBuilder
                    affiliation={affiliation}
                    key={index}
                    last={index === affiliations.slice(5).length - 1}
                  />
                ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {affiliations.length > 2 && !expanded && (
        <Grid container>
          <ExpandStatus
            setExpanded={setExpanded}
            length={affiliations.length - 2}
            expanded={expanded}
          />
        </Grid>
      )}
    </>
  )
}

export default memo(Affiliations)
