import { Location } from 'model/profile'

export const getLocationToDisplay = (location: Location) => {
  if (location) {
    if (location.city) {
      return `${location.city}, ${location.state}`
    } else if (location.state) {
      return `${location.state}, ${location.country}`
    } else {
      return location.country === null ? '' : location.country
    }
  }
}
