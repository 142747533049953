import React from 'react'
import { CmsArticle, CmsImage, TabContent } from 'model/cms'
import { TabPanel } from 'components'
import { Grid, Tabs, Tab, TabsProps, Box, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { getLinkUrl } from 'hooks/cmsComponentHooks'
import ArticleCard from '../ArticleCard'

interface CategoryTabsProps {
  tabsContent: TabContent[]
  fallbackImage?: CmsImage
  value: number
  setValue: (num: number) => void
}

const TabsContainer = styled(Box)<BoxProps>(() => ({
  width: '100%',
  margin: '40px 0'
}))

const StyledTabs = styled(Tabs)<TabsProps>(({ theme }) => ({
  fontFamily: '"D-DIN", Roboto, sans-serif',
  color: 'black',
  '.MuiButtonBase-root': {
    textTransform: 'none',
    fontWeight: 500,
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.90rem'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.25rem'
    }
  },
  '.Mui-selected': {
    fontWeight: 700
  },
  '.MuiTabs-scroller': {
    '& .MuiTabs-indicator': {
      backgroundColor: 'black !important'
    }
  }
}))

const CategoryTabs = ({
  tabsContent,
  fallbackImage,
  value,
  setValue
}: CategoryTabsProps) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(Number(newValue))
  }
  return (
    <TabsContainer>
      <Box sx={{ borderBottom: 1, borderColor: '#C4C4C4' }}>
        <StyledTabs
          textColor='inherit'
          value={value}
          variant='fullWidth'
          onChange={handleChange}
          aria-label='Category Tabs'
        >
          {tabsContent.map((tab: TabContent, index: number) => (
            <Tab
              key={`tab-${index + 1}`}
              label={tab.name}
              value={index}
              wrapped
            />
          ))}
        </StyledTabs>
      </Box>
      <>
        {tabsContent.map((tab: TabContent, index: number) => (
          <TabPanel
            sx={{ padding: 0 }}
            key={`panel-${index + 1}`}
            value={value}
            index={index}
          >
            <Grid
              sx={{ paddingTop: '40px' }}
              container
              rowSpacing={3}
              columnSpacing={3}
            >
              {tab.featuredArticles && tab.featuredArticles.length > 0 ? (
                <>
                  {tab.featuredArticles.map(
                    (content: CmsArticle, index: number) => (
                      <React.Fragment key={`category-card-${index}`}>
                        {index === 0 ? (
                          <Grid item sm={12}>
                            <ArticleCard
                              articleVariant='featured'
                              url={
                                content.externalUrl ||
                                getLinkUrl(
                                  content.slug,
                                  content.contentBucket,
                                  content.category
                                )
                              }
                              title={content.title}
                              blurb={content.blurb}
                              minutes={content.minutes}
                              contentBucket={content.contentBucket}
                              category={content.category}
                              image={content.image ?? fallbackImage}
                              tags={content.tags}
                            />
                          </Grid>
                        ) : (
                          <>
                            {index < 3 ? (
                              <Grid item xs={12} lg={6}>
                                <ArticleCard
                                  articleVariant='content'
                                  url={
                                    content.externalUrl ||
                                    getLinkUrl(
                                      content.slug,
                                      content.contentBucket,
                                      content.category
                                    )
                                  }
                                  title={content.title}
                                  blurb={content.blurb}
                                  minutes={content.minutes}
                                  contentBucket={content.contentBucket}
                                  category={content.category}
                                  tags={content.tags}
                                />
                              </Grid>
                            ) : null}
                          </>
                        )}
                      </React.Fragment>
                    )
                  )}
                </>
              ) : null}
            </Grid>
          </TabPanel>
        ))}
      </>
    </TabsContainer>
  )
}
export default CategoryTabs
