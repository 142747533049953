import { makeStyles } from '@mui/styles'
import { Color } from 'components'
const useStyles = makeStyles(() => ({
  selectMenuItem: {
    width: 'auto'
  },
  selectMenuList: {
    padding: 8
  },
  selectContainer: {
    height: 40,
    backgroundColor: Color.blue5,
    borderRadius: 4
  },
  select: { fontSize: 14, fontFamily: 'Roboto' },
  menuItemfont: { fontWeight: 500, fontSize: 14, fontFamily: 'Roboto' },
  staffMenu: { width: 280 }
}))
export default useStyles
