import React from 'react'
import useStyles from './style'
import { Typography, Button } from '@mui/material'
import { Color } from 'components'

const NotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.main}>
      <div className='container'>
        <Typography variant='largeTitle' color={Color.black}>
          Sorry, we can’t find that page
        </Typography>
        <Typography
          variant='body1High'
          color='#64737D'
          className='introduction'
        >
          Check that the address you typed is correct and try again, or try
          navigating to the page from the Dashboard.
        </Typography>
        <Button
          variant='contained'
          href='/'
          className='backBtn'
          color='secondary'
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  )
}
export default NotFound
