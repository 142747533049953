import { useState, useRef, useEffect, useContext } from 'react'
import { useQuery as useApolloQuery } from '@apollo/client'
import { DocumentNode } from 'graphql'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { OperationVariables } from '@apollo/client/core'
import { Subscription } from 'model/apollo'
import { MicroServiceContext } from 'context/MicroService'
const useQuery: any = (
  document: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  options?: QueryHookOptions<any, OperationVariables> & Subscription
) => {
  const [returned, setReturned] = useState(false)
  const { displayNotification } = useContext(MicroServiceContext)
  const result = useApolloQuery(document, options)
  const countRef = useRef(returned)
  countRef.current = returned
  const timeouts = useRef<any>()
  useEffect(
    () => () => {
      clearTimeout(timeouts.current)
    },
    [timeouts]
  )
  const subscribeToMore = (variable: any) => {
    const extraOption = options

    if (extraOption && extraOption.isSubscriptionEphemeral !== false) {
      timeouts.current = setTimeout(() => {
        if (countRef.current === false) {
          displayNotification('Sorry, we seem to be having network issues')
        }
      }, extraOption.subscriptionTimeout || 5000)
    }
    const updateQuery = variable.updateQuery
    variable.updateQuery = (_prev: any, { subscriptionData }: any) => {
      setReturned(true)
      return updateQuery(_prev, { subscriptionData })
    }
    result.subscribeToMore(variable)
  }

  return { ...result, subscribeToMore }
}

export default useQuery
