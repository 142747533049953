import React from 'react'
import useStyles from './style'
import Dashboard from './Dashboard'

const StatisticsBlock = (): React.ReactElement => {
  const classes = useStyles()

  return (
    <div className={classes.homeContainer}>
      <Dashboard />
    </div>
  )
}

export default StatisticsBlock
