import React, { useContext } from 'react'
import { makeStyles } from '@mui/styles'
import { DialogWithoutAction } from 'components'
import { Typography, Button } from '@mui/material'
import { MyJobContext } from 'context/JobContext'

const useStyles = makeStyles({
  button: {
    padding: '12px 40px'
  }
})

const NoBatchLeft = (): React.ReactElement => {
  const classes = useStyles()
  const { openNoBatchLeft, setOpenNoBatchLeft } = useContext(MyJobContext)

  return (
    <DialogWithoutAction
      open={openNoBatchLeft}
      setOpen={setOpenNoBatchLeft}
      title={'No Batches Left'}
      Action={() => (
        <Button
          className={classes.button}
          variant='contained'
          onClick={() => {
            setOpenNoBatchLeft(false)
          }}
        >
          Back to pipeline
        </Button>
      )}
      onClose={() => setOpenNoBatchLeft(false)}
    >
      <Typography variant='body1High' gutterBottom display='block'>
        Looks like your team doesn’t have any batches left to use this month.
        <br />
        <br />
        Your batches automatically reset at the beginning of every month, or you
        can contact your impact manager about increasing your monthly batches.
      </Typography>
    </DialogWithoutAction>
  )
}

export default NoBatchLeft
