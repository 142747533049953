import React, { memo, useState, useContext } from 'react'
import { ApolloError } from '@apollo/client'
import { Color } from 'components'
import { useStyles } from './style'
import { useMutation } from 'gql/hooks'
import RequiredInput from './RequiredInput'
import OptionalInput from './OptionalInput'
import { Typography, Button, Grid } from '@mui/material'
import { RequestBatchContext } from 'context/RequestBatch'
import AccordionControll from 'context/AccordionControll'
import { MyJobContext } from 'context/JobContext'
import { FullScreenDialogContext } from 'context/FullScreenDialog'
import { CHANGE_JOB_REQUIREMENTS } from 'gql/command'
import { JOB_REQUIREMENTS_CHANGED_SUBSCRIPTION } from 'gql/subscription'
import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material'

interface Props {
  step: number
  setStep: (step: number) => void
}

const JobRequirement = ({ step, setStep }: Props): React.ReactElement => {
  const classes = useStyles()
  const [changeJobRequirementsMutation] = useMutation(CHANGE_JOB_REQUIREMENTS, {
    onErrorAction: (error: ApolloError) => {
      if (
        error?.message === 'Job requirements can not be updated without changes'
      ) {
        setStep(step + 1)
        return true
      }
    }
  })
  const { setOpenBatchRequest } = useContext(FullScreenDialogContext)
  const { requestBatchInput, errors } = useContext(RequestBatchContext)
  const { job, startSubscription } = useContext(MyJobContext)
  const jobId = job!.id
  const [expanded, setExpanded] = useState<string>('Job title')
  const handleChangeExpanded =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : '')
    }

  const disableSubmit =
    errors.displayTitle || errors.description || errors.exampleProfiles|| errors.locations
  const handleSubmit = () => {
    startSubscription(JOB_REQUIREMENTS_CHANGED_SUBSCRIPTION, () =>
      setStep(step + 1)
    )
    changeJobRequirementsMutation({
      variables: {
        input: {
          jobId: jobId,
          displayTitle: requestBatchInput.displayTitle,
          description: requestBatchInput.description,
          exampleProfiles: requestBatchInput.exampleProfiles,
          requiredSkills: requestBatchInput.requiredSkills,
          preferredSkills: requestBatchInput.preferredSkills,
          locations: requestBatchInput.locations,
          companiesToInclude: requestBatchInput.companiesToInclude,
          companiesToExclude: requestBatchInput.companiesToExclude,
          commentsAndFeedback: requestBatchInput.commentsAndFeedback
        }
      }
    })
  }

  return (
    <AccordionControll.Provider
      value={{
        expanded: expanded,
        setExpanded: setExpanded,
        handleChangeExpanded: handleChangeExpanded
      }}
    >
      <Grid className={classes.jobRequirement}>
        <Button
          color='secondary'
          startIcon={<KeyboardBackspaceIcon />}
          onClick={() => setOpenBatchRequest!(false)}
          className='exitButton'
        >
          Exit
        </Button>
        <Typography
          variant='h1'
          lineHeight='46px'
          color={Color.black}
          marginBottom={7}
        >
          Job requirements
        </Typography>
        <RequiredInput />
        <OptionalInput />
      </Grid>
      <Grid
        sx={{
          width: '100vw',
          backgroundColor: Color.white,
          position: 'fixed',
          bottom: 0,
          left: 0,
          height: 100
        }}
      >
        <Grid container className={classes.dialogActions}>
          <Button className='cancelButton' onClick={() => setStep(step - 1)}>
            Back
          </Button>
          <Button
            variant='contained'
            className='submitButton'
            disabled={disableSubmit}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </AccordionControll.Provider>
  )
}

export default memo(JobRequirement)
