import React from 'react'

const EqualHiringIndex = (): React.ReactElement => {
  return (
    <>
      <iframe
        src='https://app.dsk.mathison.io/form'
        style={{ width: '100%', height: 'calc(100vh - 56px - 147px)' }}
        frameBorder='0'
      ></iframe>
    </>
  )
}

export default EqualHiringIndex
